import {optionAction} from "../../../../redux/store";
import {useEffect, useRef} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";
import callPeerRemoteModule from "../../../../../core/utils/callPeerRemoteModule";


const useMainNavigation = () => {

  const dispatch = useDispatch();
  const nav = useNavigate();
  const params = useParams();
  const scrollbarsRef = useRef();
  const navRef = useRef([]);
  const langData = useSelector(langDataSelector);
  const {currentNavigation, connectMode} = useSelector(state => ({
    currentNavigation: state.optionWindow.currentNavigation,
    connectMode: state.theme.connectMode
  }),shallowEqual);



  const summaryButtonClickHandler = async () => {
    dispatch(optionAction.setCheckClickHeader("summary"));

    const peer = await callPeerRemoteModule(connectMode);
    if(peer) {
      peer.conn.sendData("dispatch", { action: "optionAction", func: "setCheckClickHeader", payload: "summary"});
    } else if(!peer) {
      nav(`/${langData.lang}/${params.id}/summary`)
    }
  }


  useEffect(() => {
    const navDom = navRef.current.find(item => item.current && item.current.id === `option_${currentNavigation}`);
    if (navDom && navDom.current) {
      scrollbarsRef.current.view.style.scrollBehavior = "smooth";
      scrollbarsRef.current.scrollTop(navDom.current.offsetTop);
    }
  }, [currentNavigation, navRef]);

  return { summaryButtonClickHandler, scrollbarsRef, navRef }

}

export default useMainNavigation;