import React from "react";


const SelectButton = ({callBack}) => {



  return (
    <a href="src/app/components/preselection/molecules/PreSelectionCard#" className="btn" data-block data-intent="confirm"
       onClick={async (e) => {
         e.preventDefault();
         await callBack();
       }}
    >
      <span>Select</span>
    </a>
  )
}

export default SelectButton;