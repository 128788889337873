/**
 * color picker pointer body
 * */

import React from 'react';


const Magnifier = ({magniState, isShow, magnifier, colorData}) => {
  const pointerStyles = {
    position: "absolute",
    top: "-15%",
    left: "-15%",
    width: "130%",
    height: "130%",
    zIndex: 10,
    borderRadius: "50%"
  }

  return (
    <>
      <div style={{...magniState, display: isShow ? 'block' : 'none', overflow: "hidden"}} ref={magnifier}>
        <table style={{width: "100%", height: "100%", borderCollapse: "collapse"}}>
          <tbody>
          {colorData.map((color, idx) => {
            let midPoint = parseInt(colorData.length / 2);
            return <tr key={idx}>
              {color.map((c, index) => {
                return (
                  <td key={index}
                      style={{border: "0.5px solid", backgroundColor: c.hex, padding: 0, position: "relative"}}>
                    {((idx === midPoint) && (index === midPoint))
                      && (<div
                        style={{
                          ...pointerStyles,
                          border: (c.rgb.r > 200 && c.rgb.g < 100 && c.rgb.b < 100) ? "0.5px solid white" : "0.5px solid red",
                        }}>
                      </div>)
                    }
                  </td>)
                })
              }
            </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Magnifier;
