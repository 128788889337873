import React from "react";


const IntOnlyControllers = ({light, lightEvent}) => {


  return (
    <button type={"button"} className={`control-button ${light && "active"}`} id={"viewHeadlight"} onClick={() => lightEvent()}></button>
  )
}
export default IntOnlyControllers;