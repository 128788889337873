import unescapeHtmlTag from "../../core/utils/tools";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../redux/theme-slice/themeSlice";


const withNavTab = (InnerComponent) => {
  return (props) => {
    const {navId, navRef, navigationDataObject, isParentNav, slideToSubHandler} = props;

    const langData = useSelector(langDataSelector);


    const [closeTabList, setCloseTabList] = useState([]);

    const closeNavTabHandler = (navId, closeTabList, setCloseTabList) => {
      const copyList = [...closeTabList];
      if (!closeTabList.includes(navId)) {
        copyList.push(navId);
        setCloseTabList(copyList);
      } else if (closeTabList.includes(navId)) {
        const filteredList = copyList.filter(item => item !== navId);
        setCloseTabList(filteredList);
      }
    }


    return (
      <>
        {isParentNav ? (
          <div className={"option-h1"} ref={navRef} id={navId} data-expand={!closeTabList.includes(navId)}>
            <h1 onClick={() => {
              closeNavTabHandler(navId, closeTabList, setCloseTabList);
            }}>
              {unescapeHtmlTag(navigationDataObject[langData.langName])}
            </h1>

            <button type={"button"} className={"collapse"} onClick={() => {
              closeNavTabHandler(navId, closeTabList, setCloseTabList);
            }}/>

            {
              navigationDataObject.navigationSteps.map((stepArray, stepArrayIdx) => {
                return (
                  <InnerComponent
                    key={`step${stepArrayIdx}`}
                    {...{navigationDataObject, stepArray, stepArrayIdx, slideToSubHandler}}
                  />)
              })
            }
          </div>) : (
          <>
            {
              navigationDataObject && navigationDataObject.navigationSteps && navigationDataObject.navigationSteps.map((stepArray, stepArrayIdx) => {
                return (
                  <InnerComponent
                    key={`step${stepArrayIdx}`}
                    {...{navigationDataObject, stepArray, stepArrayIdx, slideToSubHandler}}
                  />)
              })
            }
          </>)
        }
      </>
    )

  }
}

export default withNavTab;