import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toggleAction} from "../../redux/store";
import {cameraSelector} from "../../redux/toggle-slice/toggleSlice";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";

const useToggleController = () => {
  const dispatch = useDispatch();
  const camera = useSelector(cameraSelector);

  const {viewController, extLight, intLight, hotspot, attract, connectMode} = useSelector(state => ({
    viewController: state.toggle.viewController,
    extLight: state.toggle.extLight,
    intLight: state.toggle.intLight,
    hotspot: state.toggle.hotspot,
    attract: state.toggle.attract,
    connectMode: state.theme.connectMode
  }),shallowEqual);



  const controllerClickEvent = () => {
    dispatch(toggleAction.setToggle({               // controller toggle ui
      key: "viewController",
      value: !viewController
    }));
  }

  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.className.includes("control-button")) {           // close background menu when click not background button ui
        dispatch(toggleAction.setToggle({
          key: "backgroundView",
          value: false
        }));

      }
      if (!e.target.className.includes("scene scene-current")) {        // close attract menu when click not background button ui ( mobile )
        dispatch(toggleAction.setToggle({
          key: "attract",
          value: false
        }));
      }
    }

    if (window) {
      window.addEventListener('click', closeMenu);
      return () => {
        window.removeEventListener('click', closeMenu);
      }

    } else {
      document.addEventListener('click', closeMenu);
      return () => {
        document.removeEventListener('click', closeMenu);
      }
    }
  }, []);


  const attractView = () => {
    console.log("Attract : ", !attract);
    dispatch(toggleAction.setToggle({           // attract menu toggle
      key: "attract",
      value: !attract
    }));
  }


  const lightEvent = async () => {
    const peer = await callPeerRemoteModule(connectMode);

    if (camera.currentCameraType === "EXT") {
      dispatch(toggleAction.setToggle({key: "extLight", value: !extLight}));

      if(peer) {
        peer.conn.sendData("ext:light:on", !extLight);
      } else if(!peer) {
        window && window.postMessage({msg: "ext:light:on", data: !extLight}, '*');
      }

    } else if (camera.currentCameraType === "INT") {

      dispatch(toggleAction.setToggle({key: "intLight", value: !intLight}));
      if(peer) {
        peer.conn.sendData("int:light:on", !intLight);
      } else if(!peer) {
        window && window.postMessage({msg: "int:light:on", data: !intLight}, '*');
      }

    }
  }


  const hotspotEvent = () => {
    dispatch(toggleAction.setToggle({
      key: "hotspot",
      value: !hotspot
    }));

    // window && window.postMessage({msg: "hotspot", data: !hotspot}, '*');
  }


  return {
    extLight,
    intLight,
    lightEvent,
    controllerClickEvent,
    viewController,
    hotspotEvent,
    camera,
    attractView,
    attract
  }
}

export default useToggleController;
