import React from "react";
import HeaderItem from "../atoms/HeaderItem";
import {useSelector} from "react-redux";

const HeaderList = ({setCurrentTitle, reg, navForHeader, headerClickHandler}) => {
  const connectMode =  useSelector(state => state.theme.connectMode);

  return (
    <div className={"step"}>
      {navForHeader.map((item, idx) => (
        <HeaderItem
          key={idx}
          headerItemData={item}
          setCurrentTitle={setCurrentTitle}
          reg={reg}
          callback={() => {
            if(connectMode !== "remote") {
              return ;
            }

            headerClickHandler(item);
          }}
        />))
      }
    </div>)
}
export default HeaderList;