import React from "react";
import withShootingPointHandler from "../../../../hoc/withShootingPointHandler";
import ControlArea from "./ControlArea";
import PreviewColor from "../molecules/PreviewColor";
import {useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";


const ColorOptionController = ({code, colorItemObject,stepObject, shootingPointChangeHandler }) => {
  const sendData = useSelector(configDataSelector);
  const currentStep = useSelector(state => state.optionWindow.currentStep);

  const colorTarget = colorItemObject.colorTarget;
  const codeKeyData = sendData[code];


  return (
    <div className={"option-h3"} data-expand={currentStep == code} data-value={codeKeyData ? true : false}>
      <PreviewColor {...{code, colorItemObject}} shootingPointChangeHandler={(e) => shootingPointChangeHandler(e, stepObject, colorTarget)}/>
      <ControlArea {...{code, codeKeyData, stepObject, colorTarget}}/>
    </div>
  )

}

export default withShootingPointHandler(ColorOptionController);
