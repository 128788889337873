/*
* Summary 아이템 리스트 출력
* */

import React, {useEffect, useRef} from "react"
import SummaryList from "./SummaryList";
import {unescapeHtmlTag} from "../../../../core/utils/tools";
import {summaryAction} from "../../../redux/store";
import {useDispatch} from "react-redux";

const SummaryListGroup = ({items}) => {
  const dispatch = useDispatch();
  const summaryListRef = useRef([]);

  useEffect(() => {
    const offsetTopList = summaryListRef.current.map(item => item.current.offsetTop);
    dispatch(summaryAction.setOffsetTopList(offsetTopList));
  },[summaryListRef, items]);


  return (<>
      {Object.keys(items).map((navTitle, navIdx) => {

        summaryListRef.current[navIdx] = React.createRef();
        return (
          <div key={navIdx} className={"spec-group"} ref={summaryListRef.current[navIdx]}>
            {<h2>{unescapeHtmlTag(navTitle)}</h2>}
            <ul>
              {
                Object.keys(items[navTitle]).map((key, idx) => {

                  return <SummaryList key={idx}
                                      title={key}
                                      item={items[navTitle][key]}
                  />
                })
              }
            </ul>
          </div>)
        })
      }
    </>

  )

}

export default SummaryListGroup;
