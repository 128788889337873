import LOGO_IMAGE from "../../../assets/images/logo_white.svg";
import {langSwitch} from "../../../../core/utils/lang";
import React from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";

const StandbyHeader = () => {
  const langData = useSelector(langDataSelector);

  return (
    <div className={"stb-header"}>
      <img src={LOGO_IMAGE} alt={"Genesis One of one logo"}/>
      <h1>{ langSwitch("connectPage", "headerTitle", langData.lang)}</h1>
      <p>{ langSwitch("connectPage", "headerText", langData.lang)}</p>
    </div>
  )

}

export default StandbyHeader;