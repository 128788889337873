import React from "react";
import unescapeHtmlTag from "../../../../core/utils/tools";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";


const PartItem = ({item, title, code}) => {
  const langData = useSelector(langDataSelector);
  return (
    <li>
      <div className={"spec-name"}>{unescapeHtmlTag(title)}</div>
      <div className={"spec-value"}>{unescapeHtmlTag(item[langData.langName])}
        { item.notes ? (
          <p className={"spec-value-request"}>
            NOTE : { unescapeHtmlTag(item.notes)}
          </p>
        ) : null
        }

      </div>
      <div className={"spec-code"}>
        {code}
        { item.color && ( <p style={{fontSize:"16px", marginTop:"14px"}}>{item.color}</p>) }
      </div>
    </li>

  )
}
export default PartItem;

