import React, {useEffect, useRef, useState} from "react";
import SummaryHeader from "../atoms/SummaryHeader";
import SummaryContainer from "../organisms/SummaryContainer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {offsetTopListSelector} from "../../../redux/summary-slice/summarySlice";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import {summaryAction} from "../../../redux/store";

const Summary = ({checkClickHeader}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const innerRef = useRef();

  const offsetTopList = useSelector(offsetTopListSelector);
  const {connectMode, runningSummaryAnimation} = useSelector(state => ({
    connectMode: state.theme.connectMode,
    runningSummaryAnimation: state.summary.runningSummaryAnimation
  }), shallowEqual);


  const [active, setActive] = useState(false);
  const [headerOpacity, setHeaderOpacity] = useState(false);


  let timer;
  const scrollHandle = async () => {
    const peer = await callPeerRemoteModule(connectMode);

    if (timer) {
      clearTimeout(timer);
    }

    if (containerRef.current.scrollTop >= innerRef.current.offsetTop) {
      setHeaderOpacity(true);
    } else {
      setHeaderOpacity(false);
    }

    if (!peer) {
      return;
    }

    timer = setTimeout(() => {
      const navContainerIndex = offsetTopList.findIndex(item => item >= containerRef.current.scrollTop);
      peer.conn.sendData("dispatch", {
        action: "summaryAction",
        func: "setRemoteScrollData",
        payload: {
          offsetTopList: offsetTopList,
          currentScrollTop: containerRef.current.scrollTop,
          currentScrollIndex: navContainerIndex
        }
      });
    }, 300);
  }


  useEffect(() => {
    if (connectMode === "web") {
      setActive(true);
    }

    if (connectMode === "remote") {
      if (checkClickHeader.includes("summary")) {
        setActive(true);
      }
    }
    containerRef && containerRef.current && containerRef.current.addEventListener("scroll", scrollHandle);
    return () => {
      containerRef && containerRef.current && containerRef.current.removeEventListener("scroll", scrollHandle);
    }
  }, [checkClickHeader, offsetTopList]);


  const runSummaryAnimation = () => {
    if (connectMode !== "web") {
      return;
    }

    dispatch(summaryAction.setRunningSummaryAnimation(true));
    window && window.postMessage({msg: "summaryAnimation:run", data: Number(0.2)});

    const stopSummaryEventLister = (event) => {
      if (event.data === "summaryAnimation:stop") {
        dispatch(summaryAction.setRunningSummaryAnimation(false));
        window.removeEventListener("message", stopSummaryEventLister);
      }
    }
    window.addEventListener("message", stopSummaryEventLister);
  }


  const summaryRef = useRef();
  useEffect(() => {
    summaryRef.current &&
    summaryRef.current.addEventListener("wheel", (e) => {
      e.stopPropagation();
    }, { passive: false })
  }, []);



  return (
    <div ref={summaryRef} className={`summary ${active ? "active" : ""}`} id={"summary"} style={runningSummaryAnimation ? {visibility: "hidden"} : {}}>

        <SummaryHeader setActive={setActive} headerOpacity={headerOpacity}/>
        <SummaryContainer containerRef={containerRef} innerRef={innerRef} active={active} runSummaryAnimation={runSummaryAnimation} />

    </div>
  )
}
export default Summary
