/**
 * show screenshot component
 * */
import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {EffectFade, Navigation} from "swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {elementReady} from "../../../../core/utils/tools";
import {summaryAction, toggleAction} from "../../../redux/store";
import {screenShotSelector} from "../../../redux/summary-slice/summarySlice";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";


SwiperCore.use([Navigation, EffectFade]);

const ScreenShot = ({active}) => {
  const {
    configId,
    screenShotCode,
    screenShotReady,
    screenShotActiveIndex,
    connectMode,
    checkClickHeader,
    background,
    screenShotBackground,
    loadingState,
    hotspot
  } = useSelector(state => ({
    configId: state.qrcode.configId,
    screenShotCode: state.summary.screenShotCode,
    screenShotReady: state.summary.screenShotReady,
    screenShotActiveIndex: state.summary.screenShotActiveIndex,
    connectMode: state.theme.connectMode,
    checkClickHeader: state.optionWindow.checkClickHeader,
    background: state.toggle.background,
    screenShotBackground: state.toggle.screenShotBackground,
    loadingState: state.intro.loadingState,
    hotspot: state.toggle.hotspot
  }), shallowEqual);

  const screenShot = useSelector(screenShotSelector);


  const dispatch = useDispatch();
  const summaryImageElement = useRef();




  useEffect(() => {
    if (!active) {
      return;
    }

    if (!checkClickHeader.includes("summary")) {
      return;
    }

    if (connectMode === "mediaWall") {
      return;
    }

    if (configId === screenShotCode && background === screenShotBackground) {
      return;
    }

    if(loadingState !== "end") {
      return;
    }


    dispatch(summaryAction.setScreenShotReady(false));
    dispatch(summaryAction.clearScreenShotImage());

    (async () => {
      const peer = await callPeerRemoteModule(connectMode);
      if(peer) {
        peer.conn.sendData("dispatch", {action: "summaryAction", func: "setScreenShotReady", payload: false});
        peer.conn.sendData("dispatch", {action: "summaryAction", func: "clearScreenShotImage", payload: ""});
      }
    })();





    const captureWidth = summaryImageElement.current.clientWidth;
    const captureHeight = summaryImageElement.current.clientHeight;

    if (connectMode === "web") {
      window && window.postMessage({msg: "hotspot", data: false}, '*');
    }


    setTimeout(() => {
      if (connectMode === "web") {
        elementReady("#application-canvas").then(() => {
          window && window.postMessage({
            msg: 'ui:takeScreenshot', data: {width: captureWidth, height: captureHeight}
          }, '*');
        });

      }

      (async () => {
        const peer = await callPeerRemoteModule(connectMode);
        if(peer) {
          peer.conn.sendData('ui:takeScreenshot', {width: captureWidth, height: captureHeight});
          dispatch(toggleAction.resetMovingPartsState());
        }
      })();
    }, 400);
  }, [checkClickHeader, loadingState, active]);


  useEffect(() => {
    if (!active) {
      return;
    }

    if (connectMode === "mediaWall") {
      return;
    }

    if ((configId !== screenShotCode) || (background !== screenShotBackground)) {
      return;
    }


    if (screenShot.length > 0) {
      dispatch(summaryAction.setScreenShotReady(true));

      (async () => {
        const peer = await callPeerRemoteModule(connectMode);
        if(peer) {
          peer.conn.sendData("dispatch", {action: "summaryAction", func: "setScreenShotReady", payload: true});
        }
      })();

    }
  }, [screenShot, screenShotCode, screenShotBackground, active]);


  const slideChangeHandler = async(e) => {
    if (connectMode !== "remote") {
      return;
    }
    const peer = await callPeerRemoteModule(connectMode);
    peer && peer.conn.sendData("dispatch", {
        action: "summaryAction",
        func: "setScreenShotActiveIndex",
        payload: e.realIndex
      });
  }


  const [swiper, setSwiper] = useState(null);
  const slideParams = {
    navigation: connectMode !== "mediaWall",
    speed: 800,
    loop: connectMode === "web",
    loopedSlides: screenShot && screenShot.length,
    initialSlide: 0,
    onSlideChangeTransitionStart: slideChangeHandler,
    onSwiper: setSwiper
  }


  useEffect(() => {
    if (swiper && connectMode === "mediaWall") {
      swiper.slideToLoop(screenShotActiveIndex);
    }
  }, [screenShotActiveIndex])


  return (
    <div className={"summary-image"} ref={summaryImageElement}>
      <Swiper {...slideParams}>
        {!screenShotReady
          ? (
            <div className={"loadingView"} style={{display: "block"}}>
              <div className={"spinner"}/>
            </div>)
          : screenShot && screenShot.map(item => {
          return (
            <SwiperSlide key={item.id} style={{backgroundImage: screenShotReady ? `url(${item.img})` : ""}}/>)
        })
        }
      </Swiper>
    </div>)
}
export default ScreenShot;
