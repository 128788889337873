import {useEffect, useRef, useState} from "react";
import {productListApi} from "../../../../core/api/introApi";
import ModelInfo from "./ModelInfo";
import ChangeModel from "./ChangeModel";
import {platform} from "../../../../core/platform/platform";


const ModelList = ({modelChooseImages}) => {
  const imageRef = useRef();
  const productsImagesIndexRef = useRef(60);
  const VEHICLE_SELECTION_POINTS = [0, 60, 120, 182];


  const [showModelInfo, setShowModelInfo] = useState(VEHICLE_SELECTION_POINTS.includes(productsImagesIndexRef.current));
  const [productList, setProductList] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);

  const [chosenVehicleImages, setChosenVehicleImages] = useState(new Map());


  useEffect(() => {
    productListApi().then(res => {
      if (res.data.result && res.data.message === "SUCCESS") {
        const mapData = new Map();
        // const chosenImageMap = new Map();

        for (let i = 0; i < res.data.data.content.length; i ++ ) {
          mapData.set(res.data.data.content[i].code, res.data.data.content[i]);
        }

        mapData.set("noProduct",  {id: null, code: null, displayTitle: 'No Product Data', displaySubtitle: ''});

        setProductList(mapData);
      } else if (!res.data.result) {
        console.log(`api request ${res.data.result} / ${res.data.message}`);
      }
    })
  }, []);


  useEffect(() => {
    if (showModelInfo) {
      if (modelChooseImages) {
        const modelCode = modelChooseImages[productsImagesIndexRef.current].name.split(".")[1];
        const getProductData = productList && productList.get(modelCode);

        if (getProductData) {
          setCurrentProduct(getProductData);
        } else if (!getProductData) {
          productList && setCurrentProduct(productList.get("noProduct"));
        }
      }
    }
  }, [productsImagesIndexRef.current, productList]);



  return (
    <>
      <div className={"model-sequence"}>
        <img ref={imageRef} src={modelChooseImages && modelChooseImages[59] && modelChooseImages[59].dataURI}/>
      </div>

      <ChangeModel
        imageRef={imageRef}
        productsImagesIndexRef={productsImagesIndexRef}
        VEHICLE_SELECTION_POINTS={VEHICLE_SELECTION_POINTS}
        showModelInfo={showModelInfo}
        modelChooseImages={modelChooseImages}
        setShowModelInfo={setShowModelInfo}
      />
      {
        productList !== null && (
          <ModelInfo
            imageRef={imageRef}
            showModelInfo={showModelInfo}
            setShowModelInfo={setShowModelInfo}
            currentProduct={currentProduct}
            chosenVehicleImages={chosenVehicleImages}
          />)
      }
    </>)


}
export default ModelList;