import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import {useEffect, useRef, useState} from "react";
import SwiperCore, {Navigation} from "swiper";
import PreselectionDesc from "../../preselection/atoms/PreselectionDesc";
import PreSelectionCard from "../../preselection/molecules/PreSelectionCard";
import {shallowEqual, useSelector} from "react-redux";
import {cardFlipToggleSelector} from "../../../redux/toggle-slice/toggleSlice";
import {getPreselectionList} from "../../../../core/api/preselectionApi";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";
import useSetDefaultOption from "../../../hooks/core/useSetDefaultOption";


const MediaWallPreselection = ({preselectionIndex}) => {
  SwiperCore.use([Navigation]);
  const listContainerRef = useRef();
  const cardRef = useRef();
  const cardFlipToggle = useSelector(cardFlipToggleSelector);
  const currentVehicle = useSelector(currentVehicleSelector);
  const {selectDefaultOption} = useSetDefaultOption();
  const {preselectionList} = useSelector(state => ({
    preselectionList: state.preselection.preselectionList
  }),shallowEqual);



  const [swiper, setSwiper] = useState(null);

  const swiperParams = {
    spaceBetween: 20,
    slidesPerView: "auto",
    onSwiper: setSwiper,
    observer: true,
    observeParents: true,
  }

  useEffect(() => {
    if (swiper && (preselectionIndex || preselectionIndex === 0)) {
      swiper.slideTo(preselectionIndex);
    }
  }, [preselectionIndex]);



  useEffect(() => {
    console.log("preselectionList :", preselectionList);
    const containerWidth = (cardRef.current) && (cardRef.current.clientWidth * 2.8);
    if(listContainerRef.current){
      listContainerRef.current.style.width = `${containerWidth}px`;
    }
  }, [preselectionList]);





  // useEffect(() => {
  //   if(currentVehicle){
  //     getPreselectionList(currentVehicle.id).then(res => {
  //       if(res.data.result && res.data.message === "SUCCESS") {
  //         const preselectionListData = res.data.data.content;
  //         setPreselectionList(preselectionListData);
  //       }
  //     });
  //   }
  // }, [currentVehicle]);


  return (
    <div className={"remote-tablet remote-wall"} data-theme="dark" data-lang={"ko"}>
      <div className={"wrap"}>
        <div className={"ps-container"}>
          <div className={"ps-top"}>
            <PreselectionDesc/>
          </div>
          <div className={"ps-list"} ref={listContainerRef} >
            <Swiper {...swiperParams} ref={setSwiper} className={"swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-backface-hidden"}>
              {preselectionList.filter(data => !data.isDefault).map((data, idx) => {
                return (
                  <SwiperSlide key={idx} ref={cardRef}>
                    <PreSelectionCard {...data} cardFlipHandler={null} cardFlipToggle={cardFlipToggle} selectDefaultOption={selectDefaultOption}/>
                  </SwiperSlide>)
                })
              }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaWallPreselection;