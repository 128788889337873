import React, {useEffect, useRef} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {menuAction} from "../redux/store";
import ModalIndex from "../components/modal/ModalIndex";
import "../assets/css/customCss.css"
import '../assets/fonts/GenesisSans/GenesisSans.css'
import {Outlet, useOutletContext} from "react-router-dom";
import RotateDevice from "../components/independent/RotateDevice";
import Layout from "../components/layout/template/Layout";
import {currentVehicleSelector} from "../redux/optionWindow-slice/optionWindowSlice";
import useIdleTime from "../hooks/core/useIdleTime";

const Web = () => {
  const dispatch = useDispatch();
  const {menuOpened, runningSummaryAnimation, isGnb} = useSelector(state => ({
    menuOpened: state.menuOpen.menuOpened,
    runningSummaryAnimation: state.summary.runningSummaryAnimation,
    isGnb: state.optionWindow.isGnb
  }), shallowEqual);

  const {wrapperRef} = useOutletContext();
  const mainTagRef = useRef();
  const currentVehicle = useSelector(currentVehicleSelector);

  useIdleTime(currentVehicle.idleTime);



  useEffect(() => {
    if (menuOpened) {
      wrapperRef.current.setAttribute("menu-opened", true);
    } else if (!menuOpened) {
      wrapperRef.current.removeAttribute("menu-opened");
    }

    if (runningSummaryAnimation) {
      wrapperRef.current.classList.add("is-standby");
    } else if (!runningSummaryAnimation) {
      wrapperRef.current.classList.remove("is-standby");
    }

    if (isGnb && !runningSummaryAnimation) {
      mainTagRef.current.classList.add("hide_view");
    } else {
      mainTagRef.current.classList.remove("hide_view");
    }
  }, [menuOpened, runningSummaryAnimation, isGnb]);





  return (
    <>
      <div className="overlay" onClick={() => dispatch(menuAction.menuToggle())}></div>
      <main id={"main-tag"} className={"mainView"} ref={mainTagRef}/>
      <Layout/>
      <Outlet/>
      <ModalIndex/>
      <RotateDevice/>{/* 모바일/태블릿 기기 회전안내 */}
    </>
  )
}

export default Web;
