import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {optionWindowSlice} from "./optionWindow-slice/optionWindowSlice";
import {themeSlice} from "./theme-slice/themeSlice";
import {menuWindowSlice} from "./menuWindow-slice/menuWindowSlice";
import introSlice from "./intro-slice/introSlice";
import IntroSlice from "./intro-slice/introSlice";
import {modalSlice} from "./modal-slice/modalSlice";
import { preselectionSlice } from "./preselection-slice/preselectionSlice";


import storageSession from "redux-persist/lib/storage/session"
import {persistReducer, PURGE, PERSIST} from "redux-persist";
import {summarySlice} from "./summary-slice/summarySlice";
import {toggleSlice} from "./toggle-slice/toggleSlice";
import qrcodeSlice from "./qrcode-sliace/qrcodeSlice";
import webglStateMiddleware from "./webgl.state.middleware";
import productListSlice from "./productList-slice/productListSlice";

const rootReducer = combineReducers({
  menuOpen : menuWindowSlice.reducer,
  optionWindow : optionWindowSlice.reducer,
  theme : themeSlice.reducer,
  intro :introSlice.reducer,
  modal : modalSlice.reducer,
  summary:summarySlice.reducer,
  toggle:toggleSlice.reducer,
  qrcode: qrcodeSlice.reducer,
  productList: productListSlice.reducer,
  preselection: preselectionSlice.reducer
})

const persistConfig = {
  key: "root",
  storage : storageSession,
  whitelist: ["optionWindow", "qrcode", "toggle", "menuOpen", "productList", "preselection"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer : persistedReducer,
  devTools : true,
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [PERSIST, PURGE]
        },
      }).concat(webglStateMiddleware),
})

export const modalAction = modalSlice.actions;
export const menuAction = menuWindowSlice.actions;
export const themeAction = themeSlice.actions;
export const optionAction = optionWindowSlice.actions;
export const introAction = IntroSlice.actions;
export const summaryAction = summarySlice.actions;
export const toggleAction = toggleSlice.actions;

export const productListAction = productListSlice.actions;

export const preselectionAction = preselectionSlice.actions;
export const qrcodeAction = qrcodeSlice.actions;
export default store;
