import React from "react";
import ColorChip from "../atoms/ColorChip";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";


const ColorTab = ({code, colorByCategory, codeKeyData, colorItemObject}) => {
  const langData = useSelector(langDataSelector);
  const colorCategory = colorItemObject && colorItemObject.colorCategories;

  const categoriesExcludingDefault = colorCategory && colorCategory.filter(item => !item.nameEn.includes("default")) || [];
  const defaultCategory = colorCategory && colorCategory.find(item => item.nameEn.includes("default"));



  return (
    <>
    {colorItemObject && (
      <div className={"tab-content active"}>

        {
          colorItemObject.paletteId
            ? categoriesExcludingDefault.length > 0
              ? categoriesExcludingDefault.sort((a,b) => a.categoryOrder - b.categoryOrder).map((category, idx) => {
              if(colorByCategory[category.id]) {
                return (
                  <ColorChip key={idx}
                             categoryName={category[langData.langName]}
                             colorArray={colorByCategory[category.id]}
                             codeKeyData={codeKeyData}
                             code={code}
                  />)
              }
          }) : categoriesExcludingDefault.length === 0 && (
              <ColorChip categoryName={""}
                         colorArray={colorByCategory[defaultCategory.id]}
                         codeKeyData={codeKeyData}
                         code={code}
              />
            )
            : !colorItemObject.paletteId && (
              <>
                {colorByCategory && (colorCategory.length > 0)
                  ? [...colorCategory].sort((a, b) => a.categoryOrder - b.categoryOrder).map((category, idx) => {
                    if (colorByCategory[category.id]) {
                      return (
                        <ColorChip key={idx}
                                   categoryName={category[langData.langName]}
                                   colorArray={colorByCategory[category.id]}
                                   codeKeyData={codeKeyData}
                                   code={code}
                        />)
                    }
                  })
                  : <ColorChip categoryName={""}
                               colorArray={colorByCategory["default"]}
                               codeKeyData={codeKeyData}
                               code={code}
                  />
                }
              </>)
        }
      </div>)
    }
  </>)
}

export default ColorTab;