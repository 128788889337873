

const Button = ({ onClick, name, type="button", confirm=false, block=false, className, size, disabled, onKeyDown }) => {
//size : "lg" or "sm" (필수값아님)

  return (
    <button type={type}
            className={`btn ${className}`}
            data-block={block}
            data-intent={confirm ? "confirm" : false}
            onClick={onClick}
            data-size={size}
            disabled={disabled}
    >
      <span>{name}</span>
    </button>
  )
}

export default Button;