/**
 * save configuration component
 * */

import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {menuAction, modalAction} from "../../../redux/store";
import SaveComponentQRCode from "./SaveComponentQRCode";
import {useParams} from "react-router-dom";
import {transferLanguage} from "../../../../core/utils/tools";
import {confSave} from "../../../../core/api/configuratorApi";
import {langSwitch} from "../../../../core/utils/lang";
import {configDataSelector, currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";

const SaveComponent = ({subModalMount}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const sendData = useSelector(configDataSelector);
  const configId = useSelector(state => state.qrcode.configId);
  const currentVehicle = useSelector(currentVehicleSelector);

  const [inputValue, setInputValue] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const SuccessMessage = () => {
    const alertTitle = params.lang === "kr" ? "이메일 발송이 완료되었습니다." : "Email Sent";
    const alertMessage = params.lang === "kr" ? (
      <>입력해주신 메일 주소로 <br/>당신만의 원오브원 차량 구성 결과가 발송되었습니다.</>
    ) : (
      <>Thank you for sharing your inspiration with us. <br/>We have sent your configuration to the email address you
        provided.</>
    )

    return (<><h2>{alertTitle}</h2><p>{alertMessage}</p></>
    )
  }


  const inputChangeHandler = (e) => {       // input email
    setInputValue(e.target.value);
  }

  const checkEmail = (e) => {       // check email format
    let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
    return regExp.test(e)
  }


  const sendEmailHandler = async () => {

    const payloadData = {
      productId: currentVehicle.id,
      bespokeCode: configId,
      email: inputValue,
      configuration: JSON.stringify(sendData)
    }

    if (checkEmail(inputValue)) {

      const saveResult = await confSave({                             // save configuration
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": params.lang === "kr" ? "ko" : "en"
        }
      }, payloadData);
      if (saveResult.data.result) {

        subModalMount(<SuccessMessage/>, "alert", true);
        dispatch(menuAction.menuToggle());
      } else {
        alert("error");
      }


    } else if (!validEmail) {
      const emailWarningMessage = langSwitch("saveConfig", "emailWarning", params.lang);
      subModalMount(<><p>{emailWarningMessage}</p></>, "alert");
    }
  }

  return (
    <>
      <div className={"modal-header"}>
        <h2>{langSwitch("saveConfig", "title", params.lang)}</h2>
        <button type="button" className="modal-close" onClick={() => dispatch(modalAction.modalClose())}>close
        </button>
      </div>

      <div className={"modal-body"}>
        <p>
          {langSwitch("saveConfig", "bodyDesc", params.lang).split("\n").map((title, index) => {
            return (
              <React.Fragment key={index}>{title}<span></span><br/></React.Fragment>
            )
          })}
        </p>
        <div className={"save-load"}>
          <div className={"form-group"} id={"SavedCode"}>
            <label>{transferLanguage(params.lang, "carConfCode")}</label>
            <div className={"form"}>
              <input type={"text"} data-full readOnly={true} value={configId}/>
            </div>
          </div>
          <SaveComponentQRCode configId={configId} sendData={sendData}/>
          <div className={"form-group"} id={"SendCode"}>
            <label>{transferLanguage(params.lang, "sendEmail")}</label>
            <div className={"form"}>
              <input type="text" data-full=""
                     placeholder={transferLanguage(params.lang, "emailAddressMsg")} value={inputValue}
                // onBlur={checkEmail}
                     onChange={inputChangeHandler}/>
            </div>

            <button type="button" className="btn notheme" data-block="" data-intent="confirm"
                    onClick={sendEmailHandler}>
              <span>{transferLanguage(params.lang, "sendEmailBtn")}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SaveComponent;
