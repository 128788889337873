import React from "react";
import PartDetailTab from "../molecules/PartDetailTab";
import NotesTab from "../molecules/NotesTab";
import Part3dTab from "../molecules/Part3dTab";
import Part2dTab from "../molecules/Part2dTab";
import ColorTab from "../molecules/ColorTab";
import ColorPickerTab from "../molecules/ColorPickerTab";
import withTabs from "../../../../hoc/withTabs";

const ControlArea = ({partArray, code, codeKeyData, stepObject, selectedIndex, personalItemObject, colorByCategory, colorItemObject, currentTab}) => {



  return (
    <>
        {currentTab === "OPTION" && (stepObject.stepType === "PART_3D" || stepObject.stepType === "MATERIAL") &&
          <Part3dTab {...{partArray, code, codeKeyData}}/>
        }
        {currentTab === "OPTION" && stepObject.stepType === "PART_2D" &&
          <Part2dTab {...{personalItemObject,code,codeKeyData}}/>
        }
        {currentTab === "COLOR" &&
          <ColorTab {...{code, colorByCategory, codeKeyData, colorItemObject}}/>
        }
        {currentTab === "DETAIL" &&
          <PartDetailTab {...{partArray, code, selectedIndex, personalItemObject}}/>
        }
        {currentTab === "NOTE" &&
          <NotesTab code={code}/>
        }
        {currentTab === "CUSTOM_COLOR" &&
          <ColorPickerTab code={code}/>
        }
    </>
  )
}

export default withTabs(ControlArea) ;
