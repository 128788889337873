

const Pagination = ({animate, productsImagesIndexRef, VEHICLE_SELECTION_POINTS, runningAnimation}) => {




  return (
    <div className={"pagination"}>
      {
        VEHICLE_SELECTION_POINTS.map((item, index) => (
          <button key={"pagination_"+item} type={"button"} className={`dot ${productsImagesIndexRef.current === item ? "active" : ""}`}
                  onClick={() => {
                    if (runningAnimation) {
                      return;
                    }

                    animate(item);
                  }}
          >{index}</button>
        ))
      }
    </div>
  )
}

export default Pagination;

