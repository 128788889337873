
import {useEffect, useState} from "react";

const LoadingProgress = () => {
    const [progress, setProgress] = useState(0);

    function updateProgress(progressValue) {
        setProgress(current => {
            return progressValue;
        });
    }

    useEffect(()=>{
        const receiveMsg = (e)=>{
            if(e.data.progress) {
                updateProgress(e.data.progress);
            }
        }
        window.addEventListener('message',receiveMsg);

        return ()=>{
            window.removeEventListener('message',receiveMsg);
        }
    },[])
    return (
        <div className={"progress"}>{progress}</div>
    )
}
export default LoadingProgress;
