import React, {useEffect} from "react";
// import INTRO_BG from "../../assets/images/intro_bg.jpg";
import INTRO_BG from "../../assets/images/genesis_one_of_one_main.jpg"
import INTRO_BG_M from "../../assets/images/genesis_one_of_one_main_m.jpg"
import LOGO_DARK from "../../assets/images/logo.png";
import LOGO_WHITE from "../../assets/images/logo_white.png";
import Button from "../independent/Button";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import useLoadMyOneOfOne from "../../hooks/core/useLoadMyOneOfOne";
import {persistor} from "../../../index";
import {platform} from "../../../core/platform/platform";
import axios from "axios";
import {introAction} from "../../redux/store";
import {supportsAvif} from "../../../core/utils/supportsAvif";


const IntroPage = () => {
  const params = useParams();
  const bespokeCode = params.bespokeCode;
  const theme = useSelector(state => state.theme.theme);
  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);
  const mobileSuffix = "m_";
  const dispatch = useDispatch();
  const { keyDownHandler, onInputHandler, getEntireCodeFromInput, codeInputRefs, initialValue, introCodeSubmitHandler} = useLoadMyOneOfOne();

  const purgeEvent = async () => {
    await persistor.purge();

    axios.get(`${process.env.PUBLIC_URL}/intro/${sessionStorage.getItem("supportAvif") ? "avif" : "webp"}/${isMobile ? mobileSuffix : ""}walkin-images.json`).then(res => {
      if (res.status === 200) {
        dispatch(introAction.setWalkInImages(res.data));
      }
    });
  }




  useEffect(() => {
    sessionStorage.removeItem("auth");

    supportsAvif().then((isValid) => {
      console.log("Does your browser support avif?", isValid);
      if (isValid) {
        sessionStorage.setItem("supportAvif", true);
      }
    });

    setTimeout(() => purgeEvent(), 200);
  },[]);


  const enterKeyEvent = () => {
    introCodeSubmitHandler(bespokeCode, getEntireCodeFromInput);
  };


  return (
    <div data-theme={theme} className={"temp-div"} data-lang={params.lang ? params.lang : "en"}>
      <div className={"wrap"}>
        <div className={"intro"}>
          <img className={"intro-bg"} src={ isMobile ? INTRO_BG_M : INTRO_BG}/>
          <div className={"code-wrap"}>
            <div className={"logo"}>
              <img className={"logo-white"} src={LOGO_WHITE}/>
              <img className={"logo-dark"} src={LOGO_DARK}/>
            </div>
            <div className={"code-input"}>
              <h1>Welcome</h1>
              <p>Please enter your invitation or One of One Code</p>
              <div className={"form"}>
                { initialValue.map((item, index) => {
                  return (
                    <input
                      key={index} type={"text"} data-box maxLength={1}
                      defaultValue={bespokeCode ? bespokeCode[index]: ""}
                      disabled={!!bespokeCode}
                      ref={codeInputRefs.current[index]}
                      onKeyDown={(e) => keyDownHandler(e, index, enterKeyEvent)}
                      onInput={(e) => onInputHandler(e, index)}
                    />)
                  })
                }
              </div>
              <Button block={true} confirm={true} size={"lg"} name={"ENTER"} onClick={() => introCodeSubmitHandler(bespokeCode, getEntireCodeFromInput)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntroPage;