import ChangeModelButtons from "./ChangeModelButtons";
import Pagination from "./Pagination";
import {useState} from "react";


const ChangeModel = ({imageRef, productsImagesIndexRef, VEHICLE_SELECTION_POINTS, showModelInfo, setShowModelInfo, modelChooseImages}) => {
  const [runningAnimation, setRunningAnimation] = useState(false);


  const decreaseIndex = () => {
    if(productsImagesIndexRef.current > 0) {
      productsImagesIndexRef.current = (productsImagesIndexRef.current-1) % modelChooseImages.length;
    }
  }

  const increaseIndex = () => {
    if(productsImagesIndexRef.current < modelChooseImages.length -1) {
      productsImagesIndexRef.current = (productsImagesIndexRef.current+1) % modelChooseImages.length;
    }
  }

  const animate = (eventOrStringOrNumber) => {
    setRunningAnimation(true);
    setShowModelInfo(false);

    let directionOrPaginationIndex;

    if (typeof eventOrStringOrNumber === "string") { // left or right
      directionOrPaginationIndex = eventOrStringOrNumber;

      if (directionOrPaginationIndex === "right") {
        increaseIndex();
      } else if (directionOrPaginationIndex === "left") {
        decreaseIndex();
      }

      if (VEHICLE_SELECTION_POINTS.includes(productsImagesIndexRef.current)) {
        setShowModelInfo(true);
        setRunningAnimation(false);
        return;
      }
    } else if(typeof eventOrStringOrNumber === "number") { // pagination index

      directionOrPaginationIndex = eventOrStringOrNumber;
      if(productsImagesIndexRef.current > directionOrPaginationIndex) {
        decreaseIndex();
      } else if (productsImagesIndexRef.current < directionOrPaginationIndex) {
        increaseIndex();
      }

      if(productsImagesIndexRef.current === eventOrStringOrNumber) {
        setShowModelInfo(true);
        setRunningAnimation(false);
        return;
      }
    } else { // event object
      directionOrPaginationIndex = eventOrStringOrNumber.target.id;
    }
    imageRef.current.src = modelChooseImages[productsImagesIndexRef.current].dataURI;

    requestAnimationFrame(() => animate(directionOrPaginationIndex));
  }

  return (
    <>
      <ChangeModelButtons
        imageRef={imageRef}
        productsImagesIndexRef={productsImagesIndexRef}
        modelChooseImages={modelChooseImages}
        showModelInfo={showModelInfo}
        setShowModelInfo={setShowModelInfo}
        VEHICLE_SELECTION_POINTS={VEHICLE_SELECTION_POINTS}
        runningAnimation={runningAnimation}
        animate={animate}
      />
      <Pagination
        productsImagesIndexRef={productsImagesIndexRef}
        VEHICLE_SELECTION_POINTS={VEHICLE_SELECTION_POINTS}
        runningAnimation={runningAnimation}
        animate={animate}
      />
    </>
  )
}

export default ChangeModel;