import React, {useEffect, useRef, useState} from "react";

import SummaryHeader from "../../summary/atoms/SummaryHeader";
import SummaryContainer from "../../summary/organisms/SummaryContainer";
import {useSelector} from "react-redux";
import {offsetTopListSelector, remoteScrollDataSelector} from "../../../redux/summary-slice/summarySlice";


const MediaWallSummary = ({summaryData, checkClickHeader}) => {
  const containerRef = useRef(null);
  const innerRef = useRef();

  const offsetTopList = useSelector(offsetTopListSelector);
  const remoteScrollData = useSelector(remoteScrollDataSelector);



  const [headerOpacity, setHeaderOpacity] = useState(false);



  const scrollHandle = () => {
    if (containerRef.current.scrollTop >= innerRef.current.offsetTop) {
      setHeaderOpacity(true);
    } else {
      setHeaderOpacity(false);
    }
  }


  const [indexState, setIndexState] = useState();

  useEffect(() => {
    if(!remoteScrollData) {
      return ;
    }
    containerRef.current.style.scrollBehavior = "smooth";
    if(remoteScrollData.currentScrollIndex !== -1){
      setIndexState(remoteScrollData.currentScrollIndex);
    } else {
      setIndexState(indexState);
    }
  },[remoteScrollData]);


  useEffect(() => {
    if(!remoteScrollData) {
      return ;
    }
    containerRef.current.scrollTop = offsetTopList[indexState]/ remoteScrollData.offsetTopList[indexState] * remoteScrollData.currentScrollTop;
    containerRef && containerRef.current && containerRef.current.addEventListener("scroll", scrollHandle);

    return () => {
      containerRef && containerRef.current && containerRef.current.removeEventListener("scroll", scrollHandle);
    }
  },[remoteScrollData, indexState]);


  
  
  return (
    <div className={`summary ${checkClickHeader.includes("summary") ? "active" : ""}`}>
      <SummaryHeader headerOpacity={headerOpacity}/>
      <SummaryContainer containerRef={containerRef} innerRef={innerRef} summaryData={summaryData} />
    </div>)
}
export default MediaWallSummary
