import React from "react";


const SummaryButton = ({onClick}) => {

  return (
    <div className="option-summary">
      <button type="button" className="btn" data-block="" data-intent="confirm" data-size="lg" onClick={onClick}>
        <span>Summary</span>
      </button>
    </div>
  )
}

export default SummaryButton;