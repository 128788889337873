import logoWhite from "../../../assets/images/logo_white.svg";
import logoDark from "../../../assets/images/logo.svg";
import React from "react";
import {useSelector} from "react-redux";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";


const RemoteLogo = () => {
  const connectMode = useSelector(state => state.theme.connectMode);



  return (
    <div className="logo" onClick={async () => {
      const peer = await callPeerRemoteModule(connectMode);
      peer && peer.conn.sendData("changeCar", null);
    }}
    >
      <img src={logoWhite} className="logo-white"/>
      <img src={logoDark} className="logo-dark"/>
    </div>
  )
}

export default RemoteLogo;