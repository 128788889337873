


export const colorTargetReplacement = (colorTarget) => {
  const map = {
    "EXTERIOR_COLOR_PRIMARY" : "EXT_MAIN",
    "EXTERIOR_COLOR_SECONDARY" : "EXT_SPECIAL",
    "EXTERIOR_PINSTRIPE" : "EXT_PINSTRIPE",
    "INTERIOR_KEY_COLOR" : "INT_KEY",
    "INTERIOR_SECONDARY_COLOR" : "INT_SECONDARY",
  }


  if(colorTarget !== undefined && colorTarget !== null && colorTarget.trim() !== "") {
    let keyCode = map[colorTarget];
    if(keyCode) {
      return keyCode;
    } else if(!keyCode) {
      return "INT_" + colorTarget;
    }
  }
}




export const stepMgmtDataProcessing = (productStepManagementData, langData, paramsId) => {
  let allColorItems = [];
  let navForHeader = [];


  const processedNavData = productStepManagementData.sort((a, b) => a.navOrder - b.navOrder).map((navData) => {
    const newNavStepsArray = []
    const stepGroupArray = [];


    navData.navigationSteps.sort((a, b) => a.stepOrder - b.stepOrder).map((navStepData) => {

      let childNavList = productStepManagementData.filter(item => item.childButtonPosition === navStepData.id);

      const newNavStepData = { //새로 정립된 각각의 스텝데이터
        childNavData: childNavList,
        nameEn: navStepData.nameEn,
        nameKr: navStepData.nameKr,
        stepItems: navStepData.stepItems.filter(i => i.active).sort((a, b) => a.itemOrder - b.itemOrder),
        stepType: navStepData.stepType,
        active: navStepData.active,
        cameraType: navStepData.cameraType,
        cameraCode: navStepData.cameraCode,
      }

      if (navStepData.stepType !== "COLOR") {
        newNavStepData.stepTabs = navStepData.tabs
      }

      // const stepOrder = navStep.stepOrder -1;
      const firstStepItemData = navStepData.stepItems[0] && navStepData.stepItems[0];

      if (firstStepItemData && navStepData.active) {
        if (navStepData.stepType === "COLOR") {
          newNavStepsArray.push([newNavStepData]);
          stepGroupArray.push("COLOR");
          allColorItems = [...allColorItems, ...navStepData.stepItems];

        }
        if (newNavStepData.stepType == "PART_3D") {
          const idx = stepGroupArray.findIndex((e) => {
            return e == firstStepItemData.partSwitch && firstStepItemData.partSwitch.partCategory.split("_")[0]
          });
          //현재 part switch의 이름 중 공통된 부분(SEAT_????)을 이용한 가공을 통해 그룹이 묶이고있음.
          //향후에도 이 규칙대로 part switch의 이름을 명명할 것이 아니라면 다른 데이터로 그룹을 지정할 수 있는 방법에 대해서도 검토 필요.

          if (idx == -1) {//공통된 이름의 index를 찾지 못한 경우 신규추가, 공통된 이름의 index를 찾은 경우는 해당 그룹에 추가.
            firstStepItemData.partSwitch &&
            stepGroupArray.push(firstStepItemData.partSwitch.partCategory.split("_")[0]);
            newNavStepsArray.push([newNavStepData])
          } else {
            newNavStepsArray[idx].push(newNavStepData)
          }
        }
        if (navStepData.stepType == "MATERIAL") {
          const idx = stepGroupArray.findIndex((e) => e == firstStepItemData.materialSwitch && firstStepItemData.materialSwitch.type.split("_")[0])
          if (idx == -1) {
            firstStepItemData.materialSwitch && stepGroupArray.push(firstStepItemData.materialSwitch.type.split("_")[0]);
            newNavStepsArray.push([newNavStepData])
          } else {
            newNavStepsArray[idx].push(newNavStepData)
          }
        }
        if (navStepData.stepType == "PART_2D") {
          firstStepItemData.partPersonalization &&
          stepGroupArray.push(firstStepItemData.partPersonalization.code.split("_")[0]);
          newNavStepsArray.push([newNavStepData]);
        }
      }
    });

    const newNavData = {
      id: navData.id,
      nameEn: navData.nameEn,
      nameKr: navData.nameKr,
      navOrder: navData.navOrder,
      navigationSteps: newNavStepsArray.filter(nav => nav),
      isGnb: navData.isGnb,
      linkPath: navData.linkPath.toLowerCase(),
      cameraType: navData.cameraType,
      cameraCode: navData.cameraCode,
      parentId: navData.parentId,
      childNavigationIdList: navData.childNavigationIdList ? [...navData.childNavigationIdList] : null,
      childButtonPosition: navData.childButtonPosition
    };

    return newNavData;
  });

  const setNavForHeader = () => processedNavData.filter(item => !item.parentId).map((menu) => {
    const item = {};
    let path = menu["linkPath"] && menu["linkPath"].toLowerCase();
    if (!menu.isGnb) {
      path = `/${langData.lang}/${paramsId.toLowerCase()}/configurator/${path}`;
    } else if (menu.isGnb) {
      if (path[0] && path[0] !== "/") {
        path = "/" + path;
      }
      path = `/${langData.lang}/${paramsId.toLowerCase()}${path}`;
    } else {
      path = path;
    }
    item.id = menu["linkPath"] && menu["linkPath"].toLowerCase();
    item.title = menu[langData.langName];
    item.gnb = menu.isGnb;
    item.cameraCode = menu.cameraCode;
    item.cameraType = menu.cameraType;
    item.path = path;

    return item;
  });

  navForHeader = setNavForHeader();


  return { processedNavData, allColorItems, navForHeader }
}