import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  preselectionList: []
}

export const preselectionSlice = createSlice({
  name: "preselection",
  initialState: initialState,
  reducers: {
    setPreselectionList(state, action) {
      state.preselectionList = action.payload;
    }
  }
})