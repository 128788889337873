import {Scrollbars} from "react-custom-scrollbars-2";
import React from "react";
import StepsList from "./StepsList";
import SummaryButton from "../atoms/SummaryButton";
import {useSelector} from "react-redux";
import {navDataSelector} from "../../../../redux/menuWindow-slice/menuWindowSlice";
import useMainNavigation from "./useMainNavigation";

const MainNavigation = ({slideToSubHandler}) => {
  const navData = useSelector(navDataSelector);
  const {summaryButtonClickHandler, navRef, scrollbarsRef} = useMainNavigation();


  return (
    <div className={"option-container"}>
      <Scrollbars autoHide ref={scrollbarsRef}>
        {
          navData.processedNavData && navData.processedNavData.map((navigationDataObject, navObjectIdx) => {
            navRef.current[navObjectIdx] = React.createRef();

            if (!navigationDataObject.isGnb && !navigationDataObject.parentId) {
              return (
                <StepsList
                  key={`nav` + navObjectIdx}
                  navigationDataObject={navigationDataObject}
                  navId={`option_${navigationDataObject.linkPath}`}
                  navRef={navRef.current[navObjectIdx]}
                  isParentNav={true}
                  slideToSubHandler={slideToSubHandler}
                />)
            }
          })
        }
        <SummaryButton onClick={summaryButtonClickHandler}/>
      </Scrollbars>
    </div>
  )
}

export default MainNavigation;