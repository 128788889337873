/**
 * menu redux
 * current menu state
 * */

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
  menuOpened : false,       // menu state ( click two line )
  saveLoadOpened : "",      // save/ load modal opened
  navData : "",             // navigation menu list
}

export const menuWindowSlice = createSlice({
  name : "menuOpen",
  initialState : initialState,
  reducers : {
    menuToggle(state) {
      state.menuOpened = !state.menuOpened;
    },
    saveLoadToggle(state,action){
      state.saveLoadOpened = action.payload
    },
    clearMenuSlice(state){
      const keys= Object.keys(initialState);
      for(let i = 0; i< keys.length; i++){
        const key = keys[i]
        state[key] = initialState[key]

      }
    },
    setMenu(state,action){
      state[action.payload.key]  = action.payload.value
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
})


export const navDataSelector = createSelector(
  (state) => state.menuOpen.navData || initialState.navData,
  (navData) => {
    return navData;
  }
);