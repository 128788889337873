import React from "react"
import SummaryBody from "../molecules/SummaryBody";
import ScreenShot from "../atoms/ScreenShot";
import MediaWallSummaryBody from "../../mediaWall/molecules/MediaWallSummaryBody";
import {useSelector} from "react-redux";
import SummaryAnimationButton from "../atoms/SummaryAnimationButton";


const SummaryContainer = ({
                            containerRef, innerRef, summaryData, active, runSummaryAnimation
                          }) => {
  const connectMode = useSelector(state => state.theme.connectMode);

  return (
    <div className={"summary-container"} ref={containerRef}>
      <ScreenShot summaryData={summaryData} active={active}/>

      <div className={"summary-inner"} ref={innerRef}>
        <div className={"summary-title"}>Your Genesis One of One</div>
        {connectMode !== "mediaWall" && <SummaryBody/>}
        {connectMode === "web" && <SummaryAnimationButton runSummaryAnimation={runSummaryAnimation}/> }
        {connectMode === "mediaWall" && <MediaWallSummaryBody summaryData={summaryData}/>}
      </div>
    </div>
  )
}

export default SummaryContainer;
