import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_backend_url+"/public";

export const introSignInApi = async (payload) => {
  return await axios.post(API_ENDPOINT+ "/intro/signin", payload);
}

export const productListApi = async () => {
  return await axios.get(API_ENDPOINT + "/product/list");
}