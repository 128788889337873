/**
 * Right Menu Model List
 * **/

import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {introAction} from "../../../redux/store";
import {useParams} from "react-router-dom";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";
import {persistor} from "../../../../index";

const ModelItem = ({car, idx, vehicleSelectHandler}) => {

  const params = useParams();
  const connectMode = useSelector(state => state.theme.connectMode);
  const currentVehicle = useSelector(currentVehicleSelector);
  const langData = useSelector(langDataSelector);




  const selectOtherModelHandler = useCallback(async (e, car) => {
    const selectCarCode = car?.code.toLowerCase() || "";
    const currentCarCode = connectMode === "remote" ? currentVehicle.code : params.id;

    if (selectCarCode.toLowerCase() === (currentCarCode && currentCarCode.toLowerCase())) {
      return;
    }

    persistor.purge();
    const peer = await callPeerRemoteModule(connectMode);

    if (!peer) {
      window.location.href =`${process.env.REACT_APP_DOMAIN}/${langData.lang}/${selectCarCode}`
    } else if (peer) {
      vehicleSelectHandler(selectCarCode, "", connectMode);
      peer.conn.sendData("dispatch", {action: "introAction", func: "setLoadingState", payload: "wait"});
    };
  }, [connectMode, car, idx, params.id, currentVehicle]);

  return (
    <li id={car.code} onClick={(event) => selectOtherModelHandler(event, car)}>
      <a>
        <div className="model-name">
          <span>{car.displayTitle}</span>
          {car.displaySubtitle && <em>{car.displaySubtitle}</em>}
        </div>
        {/*<img src={`${process.env.REACT_APP_DOMAIN}/resource/images/${car.code.toLowerCase()}.png`}/>*/}
        <img src={`${process.env.PUBLIC_URL}/${car.code.toLowerCase()}.png`}/>
      </a>
    </li>
  )
}

export default ModelItem;
