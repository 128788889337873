import Button from "../../../independent/Button";
import {useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";
import useNotesTab from "./useNotesTab";

const NotesTab = ({code}) => {
  const sendData = useSelector(configDataSelector);
  const { setNotesHandler, setNotesValue, notesValue, isNoSelected } = useNotesTab(code)
  


  return (
    <div className={"tab-content"}>
      <div className={"option"}>
        <h4>Input text</h4>
        <div className={"input-text"}>
          <p>You can change it to another text.<br/> Please enter the text you want.</p>
          <div className={"form"}>
            <input type={"text"} disabled={isNoSelected} data-full={true} placeholder={"ex ) One of one"}
                   onChange={({target: {value}}) => setNotesValue(value)} value={notesValue}/>
          </div>
          {(sendData[code] && sendData[code]["NOTES"]) ? (
            <div className={"btn-group"}>
              <Button name={"Modify"} block={true} confirm={true} onClick={() => setNotesHandler("", code)}/>
              <Button name={"Delete"} block={true} onClick={() => setNotesHandler("delete")}/>
            </div>) : (
            <div className={"btn-group"}>
              <Button name={"Apply"} block={true} confirm={true} onClick={() => setNotesHandler("", code)} disabled={isNoSelected}/>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

export default NotesTab;