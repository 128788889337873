import React from "react";
import withShootingPointHandler from "../../../../hoc/withShootingPointHandler";
import PreviewPart from "../molecules/PreviewPart";
import ControlArea from "./ControlArea";
import {useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";
import usePartOptionController from "./usePartOptionController";

const PartOptionController = ({partArray, code, title, stepObject, shootingPointChangeHandler, personalItemObject}) => {
  const sendData = useSelector(configDataSelector);
  const currentStep = useSelector(state => state.optionWindow.currentStep);

  const { findSelectedPartItem } = usePartOptionController();

  const codeKeyData = sendData[code];

  const findSelectedData = findSelectedPartItem(stepObject, partArray, codeKeyData, personalItemObject);
  const {partSwitch, selectedItem, selectedIndex} = findSelectedData;


  return (
    <div className={"option-h3"} data-expand={currentStep == code} data-value={codeKeyData ? true : false}>
      <PreviewPart shootingPointChangeHandler={(e) => shootingPointChangeHandler(e, stepObject, partSwitch)} title={title} selectedItem={selectedItem}/>
      <ControlArea {...{partArray, code, codeKeyData, stepObject, selectedIndex, personalItemObject}}/>
    </div>
  )
}

export default withShootingPointHandler(PartOptionController);
