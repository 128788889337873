import unescapeHtmlTag from "../../../../../core/utils/tools";
import React from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";


const PartDetail = ({item}) => {
  const langData = useSelector(langDataSelector);


  return (
    <div>
      <div className={"option-image"}>
        <img src={`${process.env.REACT_APP_image_path}/item/${item.detailImgDesktop}`}/>
      </div>
      <div className={"option-name"}>{unescapeHtmlTag(item[langData.langName])}</div>
      <div className={"option-desc"}>{unescapeHtmlTag(item[langData.langDesc])}</div>
    </div>
  )
}

export default PartDetail;