/**
 * intro redux
 * */

import { createSlice } from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
    loadingState:"wait",        // current loading state ( wait , loading, end )
    walkInImages: null
}

const introSlice = createSlice({
    name : "intro",
    initialState : initialState,
    reducers : {
        setLoadingState(state,action){
            state.loadingState = action.payload
        },
        setWalkInImages(state,action) {
            state.walkInImages = [...action.payload];
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
})

export default introSlice
