import React from 'react';
import useBackgroundController from "./useBackgroundController";
import BackgroundThumbnail from "../atoms/BackgroundThumbnail";

const BackgroundController = () => {

  const {backgroundChangeHandler, backgroundData, background, backgroundViewEvent} = useBackgroundController();




  return (
    <>
      <button type="button" className="control-button" id="viewBackground" onClick={backgroundViewEvent}>Open Background</button>
      <div className="list-bg">
        { backgroundData.map(bg => {
            return (
              <BackgroundThumbnail key={bg.envCode} bg={bg} background={background} backgroundChangeHandler={backgroundChangeHandler}/>)
          })
        }
      </div>
    </>)
}


export default React.memo(BackgroundController);
