import {Navigate, Outlet} from "react-router-dom";
import React, {useCallback, useEffect, useRef, useState} from "react";
import useLoadProductData from "../../hooks/core/useLoadProductData";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../redux/theme-slice/themeSlice";
import Loading from "../../components/independent/Loading";
import useMsgReceive from "../../hooks/core/useMsgReceive";
import {currentVehicleSelector} from "../../redux/optionWindow-slice/optionWindowSlice";

const PrivateRoute = () => {
  const initConnectMode = "web";
  const [winReady, setWinReady] = useState(false);

  const {vehicleSelectHandler, navAndConfigDataReady} = useLoadProductData();
  useMsgReceive(initConnectMode);

  const langData = useSelector(langDataSelector);
  const loadingState = useSelector(state => state.intro.loadingState);
  const wrapperRef = useRef();



  useEffect(() => {
    setWinReady(true);
    if (isLogin()) {
      sessionStorage.removeItem("loginAttempt");
    }
  }, [])

  const isLogin = useCallback(() => !!sessionStorage.getItem("auth"), []);

  return (
    winReady
      ? isLogin()
        ? (
          <div id={"wrap"} className={"wrap"} data-lang={langData.lang} data-theme={"dark"} ref={wrapperRef}>
            {
              (loadingState === "loading" || loadingState.includes("Load")) ? <Loading/> : null
            }
            <Outlet context={{
              vehicleSelectHandler,
              initConnectMode,
              navAndConfigDataReady,
              wrapperRef,
            }}/>
          </div>
        )
        : <Navigate to={"/en/intro"}/>
      : null
  )
}

export default PrivateRoute;