import withCategory from "../../../../hoc/withCategory";
import useColorChip from "./useColorChip";

const ColorChip = ({codeKeyData, colorItem, code}) => {

  const {colorChipSelectHandler} = useColorChip();



  return (
    <li className={`${codeKeyData && codeKeyData.COLOR === colorItem.color.code ? "active" : ""}`}>
      <div>
        <button type={"button"} style={{backgroundColor : `rgba(${colorItem.color.diffRgb})`}}
                onClick={()=>colorChipSelectHandler(code, colorItem)}>
          <span>color name</span>
          <div className={`${colorItem.color && colorItem.color.materialType && colorItem.color.materialType === "glossy" ? "material-glossy": ''}`}></div>
        </button>
      </div>
    </li>
  )
}

export default withCategory(ColorChip);