/**
 * qr 코드 찍기 전 intro 대기상태
 * */

import React from 'react';
import logoWhite from "../../assets/images/logo_white.svg";
import qrCodeArea from "../../assets/images/remote_qrcode_area.svg";
import {langSwitch} from "../../../core/utils/lang";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../redux/theme-slice/themeSlice";

const RemoteStandBy = ({setQrMode})=>{
  const langData = useSelector(langDataSelector);


  return (
    <div className="standby">
      <div className="stb-header">
        <img src={logoWhite} alt="Genesis One of one logo" />
        <h1>{ langSwitch("connectPage", "headerTitle", langData.lang) }</h1>
        <p>{ langSwitch("connectPage", "headerText", langData.lang) }</p>
      </div>
      <div className="stb-body">
        <div className="stb-howto">
          <div className="title">{ langSwitch("connectPage", "guideTitle", langData.lang) }</div>
          <ol>
            { langSwitch("connectPage", "guideText", langData.lang).split("/").map((text, index) => {
              return <li key={index}>{ text }</li>
            })}
          </ol>
        </div>
        <div className="stb-qrscan">
          <div className="title">{ langData.lang === "kr" ? "QR코드" : "QR code"}</div>
          <img src={qrCodeArea} alt="QRcode Scan" />
          <button type="button" className="btn" data-block="" data-intent="confirm"
                  onClick={()=>setQrMode(true)}>
            <span>{langData.lang === "kr" ? "QR code 스캔하기" : "Scan QR code"}</span>
          </button>
        </div>
      </div>

    </div>
  )
}

export default RemoteStandBy
