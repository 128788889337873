import {HuePicker, SketchPicker} from "react-color";
import React from "react";


const ColorPicker = ({ color, changeColor, colorRef}) => {


  return (
    <div className="colorpicker-wrap" id={"pickers"} style={{position: "relative"}}>
      <SketchPicker color={color.rgb} onChange={(c, e) => changeColor(c.rgb, e)} disableAlpha={true}
                    ref={colorRef}/>
      <HuePicker color={color.rgb} onChange={(c) => changeColor(c.rgb)} disableAlpha={true}
                 direction={"horizontal"}/>
    </div>

  )
}

export default ColorPicker;

