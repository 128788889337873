import React from "react"
import LOGO_IMAGE_WHITE from "../../../assets/images/logo_white.svg";
import LOGO_IMAGE from "../../../assets/images/logo.svg";
import {useNavigate} from "react-router-dom";
import {menuAction, optionAction, toggleAction} from "../../../redux/store";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";

const SummaryHeader = ({setActive, headerOpacity}) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const {connectMode} = useSelector(state => ({
    connectMode: state.theme.connectMode,
  }), shallowEqual);
  const webglElement = document.getElementById("webglFrame");

  const closeSummaryHandler = async() => {
    setActive(false);
    dispatch(optionAction.setCheckClickHeader(""));

    const peer = await callPeerRemoteModule(connectMode);

    if(!peer) {
      dispatch(toggleAction.setToggle({key: "hotspot",value: false}));
      window && window.postMessage({msg: "anim:allInstantClose"});
      setTimeout(()=>{ nav(-1) },500);
    } else if (peer) {
      peer.conn.sendData("dispatch", {action: "optionAction", func: "setCheckClickHeader", payload: ""});
    }
  }



  return (
    <div className="summary-header" data-scrolled={headerOpacity}>
      <div className={"summary-inner"}>
        <div className="logo">
          <img src={headerOpacity ? LOGO_IMAGE : LOGO_IMAGE_WHITE} />
        </div>
        <div className="page-name">Summary</div>
        {connectMode !== "mediaWall" && (
          <button type="button" className="close-summary" onClick={closeSummaryHandler}>Close Summary</button>)
        }
      </div>
    </div>
  )
}

export default SummaryHeader
