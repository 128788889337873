import {batch, shallowEqual, useDispatch, useSelector} from "react-redux";
import RemoteLogo from "../atoms/RemoteLogo";
import Logo from "../atoms/Logo";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {menuAction, optionAction, toggleAction} from "../../../redux/store";
import HeaderList from "../molecules/HeaderList";
import useTargetCamera from "../../../hooks/controller/useTargetCamera";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import {useParams} from "react-router-dom";
import {navDataSelector} from "../../../redux/menuWindow-slice/menuWindowSlice";


const Header = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {headerCameraControl} = useTargetCamera();
  const reg = useMemo(() => /[\{\}\[\]\/?.,;:|\)*~`!^\+<>@\#$%&\\\=\(\'\"]/gi, []);
  const [currentTitle, setCurrentTitle] = useState('Pre-Selection');
  const navData = useSelector(navDataSelector);
  const navForHeader = navData && navData.navForHeader ? navData.navForHeader : [];

  const {connectMode, currentNavigation} = useSelector(state => ({
    connectMode: state.theme.connectMode,
    currentNavigation: state.optionWindow.currentNavigation,
  }), shallowEqual);


  const headerClickHandler = useCallback(async (item) => {
    const peer = await callPeerRemoteModule(connectMode);
    if (item) {
      if (item.id === currentNavigation) {
        return;
      }

      dispatch(optionAction.setCheckClickHeader(item.id));
      peer && peer.conn.sendData("dispatch", {action: "optionAction", func: "setCheckClickHeader", payload: item.id});

      if (!item.id.includes("summary")) {
        const itemId = item.id.replaceAll(reg, "");
        (() => {
          batch(() => {
            dispatch(optionAction.setCurrentNavigation(itemId));
            dispatch(optionAction.setDataSub(false));
            dispatch(optionAction.setChildNavigationData(""));
            dispatch(optionAction.setOptions({selection: "isGnb", value: item.gnb}));

            if (peer) {
              peer.conn.sendData("dispatch", {action: "optionAction", func: "setCurrentNavigation", payload: itemId});

              if (item.gnb) {
                dispatch(toggleAction.resetMovingPartsState());
                peer.conn.sendData("anim:allInstantClose");
              }
            }
          });
        })();


        headerCameraControl(item);
      }
    }

  }, [connectMode, headerCameraControl, currentNavigation]);

  useEffect(() => {
    if(connectMode !== "web") {
      return;
    }

    const path = params["*"]?.split("/");
    const pathIndex = path.length - 1;

    if (path[pathIndex] !== "") {
      const clickedData = navForHeader.find((item) => item.id.replace(/[/?]/g, "") === path[pathIndex]);
      headerClickHandler(clickedData)
    }
  }, [params["*"], navForHeader]);


  return (
    <header>
      {
        connectMode === "remote" ? <RemoteLogo/> : <Logo currentTitle={currentTitle}/>
      }
      <HeaderList headerClickHandler={headerClickHandler} setCurrentTitle={setCurrentTitle} reg={reg}
                  navForHeader={navForHeader}/>
      <button type="button" className="open-menu" onClick={() => dispatch(menuAction.menuToggle())}>
        Open menu
      </button>
    </header>)
}
export default Header;