import React, {createRef, useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux"
import MainNavigation from "../organisms/MainNavigation";
import ChildNavigation from "../organisms/ChildNavigation";
import {childNavigationDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";
import useOptionController from "./useOptionController";


const OptionController = () => {
  const childNavigationData = useSelector(childNavigationDataSelector);
  const {dataSub, isGnb} = useSelector(state => ({
    dataSub: state.optionWindow.dataSub,
    isGnb: state.optionWindow.isGnb
  }),shallowEqual);
  const optionRef = createRef();

  const { slideToMainHandler, slideToSubHandler } = useOptionController();

  const [optionOpened, setOptionOpened] = useState(false);

  useEffect(() => {
    setOptionOpened(!isGnb);

    optionRef.current.addEventListener("wheel", (e) => {
      e.stopPropagation();
    },{ passive: false})
  },[isGnb]);


  return (
    <aside className={`option-wrap ${optionOpened ? "aside-open" : ""}`} data-sub={dataSub} ref={optionRef}>
      <div className={"option-viewport"}>
        <div className={"option-slide"}>
          <MainNavigation slideToSubHandler={slideToSubHandler} />
          <ChildNavigation childNavigationData={childNavigationData} slideToSubHandler={slideToSubHandler} slideToMainHandler={slideToMainHandler}/>
        </div>
      </div>
      <div className={`toggle-option ${!optionOpened && 'is-hiding'}`} onClick={() => setOptionOpened(current => !current)}>
        <span></span>
        <button type={"button"}></button>
      </div>
    </aside>
  )
}

export default OptionController;
