import ext1_g90 from "../../app/assets/images/attract/g90/camera_exterior_01.jpg";
import ext2_g90 from "../../app/assets/images/attract/g90/camera_exterior_02.jpg";
import ext3_g90 from "../../app/assets/images/attract/g90/camera_exterior_03.jpg";
import ext4_g90 from "../../app/assets/images/attract/g90/camera_exterior_04.jpg";

import int1_g90 from "../../app/assets/images/attract/g90/camera_interior_01.png";
import int2_g90 from "../../app/assets/images/attract/g90/camera_interior_02.png";
import int3_g90 from "../../app/assets/images/attract/g90/camera_interior_03.png";
import int4_g90 from "../../app/assets/images/attract/g90/camera_interior_04.png";


import ext1_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camera_exterior_01.jpg"
import ext2_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camera_exterior_02.jpg"
import ext3_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camera_exterior_03.jpg"
import ext4_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camera_exterior_04.jpg"

import int1_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camear_interior_01.jpg"
import int2_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camear_interior_02.jpg"
import int3_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camear_interior_03.jpg"
import int4_gv80_coupe from "../../app/assets/images/attract/gv80-coupe/camear_interior_04.jpg"

import ext1_gv80 from "../../app/assets/images/attract/gv80/camera_exterior_01.jpg";
import ext2_gv80 from "../../app/assets/images/attract/gv80/camera_exterior_02.jpg";
import ext3_gv80 from "../../app/assets/images/attract/gv80/camera_exterior_03.jpg";
import ext4_gv80 from "../../app/assets/images/attract/gv80/camera_exterior_04.jpg";

import int1_gv80 from "../../app/assets/images/attract/gv80/camera_interior_01.jpg";
import int2_gv80 from "../../app/assets/images/attract/gv80/camera_interior_02.jpg";
import int3_gv80 from "../../app/assets/images/attract/gv80/camera_interior_03.jpg";
import int4_gv80 from "../../app/assets/images/attract/gv80/camera_interior_04.jpg";




export const extAttract = {
  'g90-long-wheel-base' : [
    {
      cameraType: "EXT",
      attrCode : 3,
      title:"Cam1",
      img:ext1_g90
    },
    {
      cameraType: "EXT",
      attrCode : 0,
      title:"Cam2",
      img:ext2_g90
    },
    {
      cameraType: "EXT",
      attrCode : 1,
      title:"Cam3",
      img:ext3_g90
    },
    {
      cameraType: "EXT",
      attrCode : 2,
      title:"Cam4",
      img:ext4_g90
    }
  ],
  'g90' : [
    {
      cameraType: "EXT",
      attrCode : 3,
      title:"Cam1",
      img:ext1_g90
    },
    {
      cameraType: "EXT",
      attrCode : 0,
      title:"Cam2",
      img:ext2_g90
    },
    {
      cameraType: "EXT",
      attrCode : 1,
      title:"Cam3",
      img:ext3_g90
    },
    {
      cameraType: "EXT",
      attrCode : 2,
      title:"Cam4",
      img:ext4_g90
    }
  ],
  'gv80' : [
    {
      cameraType: "EXT",
      attrCode : 3,
      title:"Cam1",
      img:ext1_gv80
    },
    {
      cameraType: "EXT",
      attrCode : 0,
      title:"Cam2",
      img:ext2_gv80
    },
    {
      cameraType: "EXT",
      attrCode : 1,
      title:"Cam3",
      img:ext3_gv80
    },
    {
      cameraType: "EXT",
      attrCode : 2,
      title:"Cam4",
      img:ext4_gv80
    }
  ],

  'gv80-coupe': [
    {
      cameraType: "EXT",
      attrCode : 3,
      title:"Cam1",
      img:ext1_gv80_coupe
    },
    {
      cameraType: "EXT",
      attrCode : 0,
      title:"Cam2",
      img:ext2_gv80_coupe
    },
    {
      cameraType: "EXT",
      attrCode : 1,
      title:"Cam3",
      img:ext3_gv80_coupe
    },
    {
      cameraType: "EXT",
      attrCode : 2,
      title:"Cam4",
      img:ext4_gv80_coupe
    }
  ]
}

export const intAttract = {
  'g90-long-wheel-base' : [
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_DASH',
      title:"Cam1",
      img:int1_g90
    },
    {
      cameraType: "INT",
      attrCode : 'FR_LOOKAT_DASH',
      title:"Cam2",
      img:int2_g90
    },
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_REAR',
      title:"Cam3",
      img:int3_g90
    },
    {
      cameraType: "INT",
      attrCode : 'RR_LOOKAT_FL',
      title:"Cam4",
      img:int4_g90
    }
  ],
  'g90' : [
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_DASH',
      title:"Cam1",
      img:int1_g90
    },
    {
      cameraType: "INT",
      attrCode : 'FR_LOOKAT_DASH',
      title:"Cam2",
      img:int2_g90
    },
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_REAR',
      title:"Cam3",
      img:int3_g90
    },
    {
      cameraType: "INT",
      attrCode : 'RR_LOOKAT_FL',
      title:"Cam4",
      img:int4_g90
    }
  ],
  'gv80' : [
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_DASH',
      title:"Cam1",
      img:int1_gv80
    },
    {
      cameraType: "INT",
      attrCode : 'FR_LOOKAT_DASH',
      title:"Cam2",
      img:int2_gv80
    },
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_REAR',
      title:"Cam3",
      img:int3_gv80
    },
    {
      cameraType: "INT",
      attrCode : 'RR_LOOKAT_FL',
      title:"Cam4",
      img:int4_gv80
    }
  ],
  'gv80-coupe': [
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_DASH',
      title:"Cam1",
      img:int1_gv80_coupe
    },
    {
      cameraType: "INT",
      attrCode : 'FR_LOOKAT_DASH',
      title:"Cam2",
      img:int2_gv80_coupe
    },
    {
      cameraType: "INT",
      attrCode : 'CENTER_LOOKAT_REAR',
      title:"Cam3",
      img:int3_gv80_coupe
    },
    {
      cameraType: "INT",
      attrCode : 'RR_LOOKAT_FL',
      title:"Cam4",
      img:int4_gv80_coupe
    }
  ]
}


// export const extAttract_g90 = [
//   {
//     cameraType: "EXT",
//     attrCode : 3,
//     title:"Cam1",
//     img:ext1_g90
//   },
//   {
//     cameraType: "EXT",
//     attrCode : 0,
//     title:"Cam2",
//     img:ext2_g90
//   },
//   {
//     cameraType: "EXT",
//     attrCode : 1,
//     title:"Cam3",
//     img:ext3_g90
//   },
//   {
//     cameraType: "EXT",
//     attrCode : 2,
//     title:"Cam4",
//     img:ext4_g90
//   }
// ]
//
// export const intAttract_g90 = [
//   {
//     cameraType: "INT",
//     attrCode : 'CENTER_LOOKAT_DASH',
//     title:"Cam1",
//     img:int1_g90
//   },
//   {
//     cameraType: "INT",
//     attrCode : 'FR_LOOKAT_DASH',
//     title:"Cam2",
//     img:int2_g90
//   },
//   {
//     cameraType: "INT",
//     attrCode : 'CENTER_LOOKAT_REAR',
//     title:"Cam3",
//     img:int3_g90
//   },
//   {
//     cameraType: "INT",
//     attrCode : 'RR_LOOKAT_FL',
//     title:"Cam4",
//     img:int4_g90
//   }
// ]

// export const extAttract_gv80 = [
//   {
//     cameraType: "EXT",
//     attrCode : 3,
//     title:"Cam1",
//     img:ext1_gv80
//   },
//   {
//     cameraType: "EXT",
//     attrCode : 0,
//     title:"Cam2",
//     img:ext2_gv80
//   },
//   {
//     cameraType: "EXT",
//     attrCode : 1,
//     title:"Cam3",
//     img:ext3_gv80
//   },
//   {
//     cameraType: "EXT",
//     attrCode : 2,
//     title:"Cam4",
//     img:ext4_gv80
//   }
// ]
//
// export const intAttract_gv80 = [
//   {
//     cameraType: "INT",
//     attrCode : 'CENTER_LOOKAT_DASH',
//     title:"Cam1",
//     img:int1_gv80
//   },
//   {
//     cameraType: "INT",
//     attrCode : 'FR_LOOKAT_DASH',
//     title:"Cam2",
//     img:int2_gv80
//   },
//   {
//     cameraType: "INT",
//     attrCode : 'CENTER_LOOKAT_REAR',
//     title:"Cam3",
//     img:int3_gv80
//   },
//   {
//     cameraType: "INT",
//     attrCode : 'RR_LOOKAT_FL',
//     title:"Cam4",
//     img:int4_gv80
//   }
// ]



export const interiorCameraList = [
  { code: 'FL_LOOKAT_DASH', idx: 0 },
  { code: 'FL_LOOKAT_STREER', idx: 1 },
  { code: 'FR_LOOKAT_DASH', idx: 2 },
  { code: 'FL_LOOKAT_RL', idx: 3 },
  { code: 'CENTER_LOOKAT_DASH', idx: 4 },
  { code: 'CENTER_LOOKAT_REAR', idx: 5 },
  { code: 'RR_LOOKAT_FL', idx: 6 },
  { code: 'RR_LOOKAT_RL', idx: 7 },
  { code: 'TRUNK', idx: 8 }

]
