import React from "react";
import PreviewImg from "../atoms/PreviewImg";
import PreviewTitle from "../atoms/PreviewTitle";

const PreviewPart = ({shootingPointChangeHandler, title, selectedItem})=>{



  return (
    <div className={"option-value"} onClick={shootingPointChangeHandler}>
      <PreviewImg selectedItem={selectedItem}/>
      <PreviewTitle selectedItem={selectedItem} title={title} type={"PART"}/>
    </div>
  )
}

export default PreviewPart;
