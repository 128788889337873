import React, {Fragment} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";


const ConnectModal = ({modalInfo, setIsPeer, setIsModal, endPeerConnection}) => {
  const langData = useSelector(langDataSelector);



  return (
    <div className="modal-wrap" data-type="alert" id="ScanSuccess" style={{display: "block"}}>
      <div className="modal-container">
        <div className="modal">
          <div className="modal-body">
            <h2>{modalInfo.title}</h2>
            <p>
              {modalInfo.text && modalInfo.text.split("/").map((t, index) => {
                return (
                  <Fragment key={index}>{t}<br/></Fragment>
                )
              })}
            </p>

          </div>
          <div className="modal-footer">

            {
              endPeerConnection ?
                <button type="button" className="btn notheme" data-block="" data-size="lg"
                        onClick={() => {
                          setIsPeer(modalInfo.data);
                          setIsModal(false);
                        }}><span>{langData.lang === "kr" ? "확인" : "Confirm"}</span>
                </button> :
                <div className={!endPeerConnection ? "waiting" : ''}>
                  <div className={"spinner"}></div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectModal;