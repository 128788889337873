import React, {useEffect, useState} from 'react';
import ConnectionComplete from "../atoms/ConnectionComplete";
import MediaWallPreselection from "../organisms/MediaWallPreselection";
import MediaWallSummary from "../organisms/MediaWallSummary";
import {shallowEqual, useSelector} from "react-redux";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";
import MediaWallLoading from "../../independent/MediaWallLoading";
import useMsgReceiveMediaWall from "../../../hooks/core/useMsgReceiveMediaWall";


const MainComponent = () => {
  const currentVehicle = useSelector(currentVehicleSelector);
  const { iframeRef, preselectionIndex, summaryData } = useMsgReceiveMediaWall();


  const {currentNavigation, checkClickHeader, loadingState} = useSelector(state => ({
    currentNavigation: state.optionWindow.currentNavigation,
    checkClickHeader: state.optionWindow.checkClickHeader,
    loadingState: state.intro.loadingState
  }),shallowEqual);



  useEffect(() => {
    if(currentVehicle) {
      let modelAssetRoot = currentVehicle.code ? currentVehicle.code.toLowerCase() : "";
      if(modelAssetRoot) {
        const iframeSrc = `${process.env.REACT_APP_media_wall_configurator_url}/${modelAssetRoot.toLowerCase()}/index.html?mode=tablet`;
        iframeRef.current.src = iframeSrc;
      }
    }
  },[currentVehicle]);


  return (
    <>
      {
        loadingState !== "end" ?
          !currentNavigation ? <ConnectionComplete/>
            : <MediaWallLoading/> : null
      }
      { currentNavigation.includes("collection") && <MediaWallPreselection preselectionIndex={preselectionIndex}/> }

      <MediaWallSummary summaryData={summaryData} checkClickHeader={checkClickHeader} />

      <div>


        <iframe
          ref={iframeRef}
          id={"webglFrame"}
          // src={`${process.env.REACT_APP_media_wall_configurator_url}/${assetRoot}/index.html?mode=tablet`}
          style={{width: '100%', height: '100%', position: "absolute", border: '0px'}}/>


      </div>
    </>
  )
}

export default MainComponent;
