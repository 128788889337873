import SummaryListGroup from "../../summary/molecules/SummaryListGroup";
import SummaryQrCode from "../../summary/atoms/SummaryQrCode";



const MediaWallSummaryBody = ({summaryData}) => {



  return (
   <>
     {
       summaryData && (
         <div className={"summary-body"}>
           <div className={"summary-spec"}>
             <SummaryListGroup items={summaryData.data["data"]}/>
           </div>
           <div className={"summary-action"}>
             <SummaryQrCode configId={summaryData.configId} configData={summaryData.configData}/>

           </div>
         </div>
       )
     }
   </>
  )
}

export default MediaWallSummaryBody;