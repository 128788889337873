import {batch, shallowEqual, useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {introAction, menuAction, optionAction, themeAction} from "../../redux/store";
import {productListApi} from "../../../core/api/introApi";
import {getOptionList} from "../../../core/api/optionApis";
import {getPreselectionDetail, getPreselectionList} from "../../../core/api/preselectionApi";
import {stepMgmtDataProcessing} from "../../../core/utils/replace";
import {langDataSelector} from "../../redux/theme-slice/themeSlice";
import useDidMountEffect from "./useDidMountEffect";
import {setPreselectionVehicleOption} from "../../../core/utils/buildConfigSnippet";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";
import {elementReady} from "../../../core/utils/tools";
import {platform} from "../../../core/platform/platform";
import TempestWidget from "@tempestcgi/tempest-widget";

// const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\+<>@\#$%&\\\=\(\'\"]/gi;

const reg = /[{}[\]/?.,;:|)*~`!^+<>@#$%&\\=('"]/gi;

const useLoadProductData = (isLoadMyOneOfOne=false) => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramsId = params && params.id && params.id.toLowerCase();

  const [navAndConfigDataReady, setNavAndConfigDataReady] = useState(true);

  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);
  const mobileSuffix = "_m";

  const langData = useSelector(langDataSelector);
  const {loadingState, CONFIG_DATA, camera, background, hotspot, connectedMode} = useSelector(state => ({
    loadingState: state.intro.loadingState,
    CONFIG_DATA: state.optionWindow.CONFIG_DATA,
    camera: state.toggle.camera,
    background: state.toggle.background,
    hotspot: state.toggle.hotspot,
    connectedMode: state.theme.connectMode,
  }), shallowEqual);


  // const DUMMY_MODEL_LIST = {
  //   "g90-long-wheel-base": {
  //     canvasId: 'application-canvas',
  //     assetPrefix: `${process.env.REACT_APP_configurator_url}/g90-long-wheel-base${isMobile ? mobileSuffix : ""}/`,
  //     debug: false,
  //     tools: {"sceneMode": true, cameraPosition: true},
  //     canvasParentId: "main-tag"
  //   },
  //   "g90": {
  //     canvasId: 'application-canvas',
  //     assetPrefix: `${process.env.REACT_APP_configurator_url}/g90${isMobile ? mobileSuffix : ""}/`,
  //     debug: false,
  //     tools: {"sceneMode": true, cameraPosition: true},
  //     canvasParentId: "main-tag"
  //   },
  //   "gv80-coupe": {
  //     canvasId: 'application-canvas',
  //     assetPrefix: `${process.env.REACT_APP_configurator_url}/gv80-coupe${isMobile ? mobileSuffix : ""}/`,
  //     debug: false,
  //     tools: {"sceneMode": true, cameraPosition: true},
  //     canvasParentId: "main-tag"
  //   },
  //   "gv80": {
  //     canvasId: 'application-canvas',
  //     assetPrefix: `${process.env.REACT_APP_configurator_url}/gv80${isMobile ? mobileSuffix : ""}/`,
  //     debug: false,
  //     tools: {"sceneMode": true, cameraPosition: true},
  //     canvasParentId: "main-tag"
  //   }
  // }


  const vehicleSelectHandler = async (usersSelectVehicleCode=null, isLoadMyDesign = false, connectMode = "web", configData = null) => {
    const selectedVehicleCode = usersSelectVehicleCode ? usersSelectVehicleCode.toLowerCase() : paramsId && paramsId;
    setNavAndConfigDataReady(false);

    if(!selectedVehicleCode) {
      return;
    }
    dispatch(introAction.setLoadingState("loading"));

    if (connectMode === "remote") {
      await (async () => {
        const peer = await callPeerRemoteModule("remote");
        peer.conn.sendData("webglDestroy");
      })();
    }


    if (connectMode === "web") {
      elementReady("#main-tag").then(res => {
        const dataForInject = {
          canvasId: 'application-canvas',
          assetPrefix: `${process.env.REACT_APP_configurator_url}/${selectedVehicleCode}${isMobile ? mobileSuffix : ""}/`,
          debug: false,
          tools: {"sceneMode": true, cameraPosition: true},
          canvasParentId: "main-tag"
        }

        if (res) {
          TempestWidget.widget(dataForInject);
          // dispatch(introAction.setLoadingState("vehicleDataLoad:end"));

        }
      });
    }

    productListApi().then(res => {
      //product list 에서 사용자가 선택한 product code 와 일치하는 항목의 데이터를 다음 then으로 리턴.
      if (res.data.result && res.data.message === "SUCCESS") {
        const productList = res.data.data.content;
        const selectedProductDetailData = productList.find(item => {
          const productCode = item && item.code && item.code.toLowerCase();
          if (productCode === selectedVehicleCode) {
            return item;
          }
          return false;
        });
        return selectedProductDetailData;
      } else {
        return false;
      }
    }).then(async selectedProductDetailData => {
      //위에서 리턴받은 product detail data 의 id를 통해 해당 product 의 navigation list 와 preselection list 를 받아온다.
      if (selectedProductDetailData) {
        const navigationListReq = await getOptionList(selectedProductDetailData.id);
        const preselectionListReq = await getPreselectionList(selectedProductDetailData.id);


        //데이터를 받아오는 데 성공했다면, navigation list 는 가공처리, preselection list 에선 default preselection 을 찾아 리턴.
        if (navigationListReq.data.result && preselectionListReq.data.result) {
          const preselectionList = preselectionListReq.data.data.content || [];
          const defaultPreselection = preselectionList.find(item => item.isDefault);
          const processedNavData = stepMgmtDataProcessing(navigationListReq.data.data, langData, selectedVehicleCode);

          return {selectedProductDetailData, defaultPreselection, processedNavData}
        } else {
          return false;
        }
      } else {
        return false;
      }

    }).then(async (res) => {
      const {defaultPreselection, selectedProductDetailData, processedNavData} = res;

      let defaultPreselectionConfigData;

      

      // Redux의 CONFIG_DATA 상에 이미 데이터가 존재하는지 체크
      const noSavedConfig = (reduxConfigData) => {
        return Object.keys(reduxConfigData).length === 0 && reduxConfigData.constructor === Object;
      }

      //load my design 으로 불러온 차량이 아닌 경우
      if (!isLoadMyDesign) {

        if (noSavedConfig(CONFIG_DATA)) { // redux 에 저장된 CONFIG_DATA 가 없을 때

          if (defaultPreselection) {
            defaultPreselectionConfigData = await getPreselectionDetail(selectedProductDetailData.id, defaultPreselection.id).then(async res => {
              if (res.data.result && res.data.message === "SUCCESS") {
                const processedDetailData = await setPreselectionVehicleOption(res.data.data.config_data);
                return processedDetailData;
              }
            });
          } else {
            defaultPreselectionConfigData = {};
          }


        } else if (!noSavedConfig(CONFIG_DATA)) { // redux 에 저장된 CONFIG_DATA 가 존재할 때
          defaultPreselectionConfigData = CONFIG_DATA;
        }
      } else if (isLoadMyDesign) {

        defaultPreselectionConfigData = configData;

      }
      
      return {selectedProductDetailData, processedNavData, defaultPreselectionConfigData}

    }).then(async res => {
      const {selectedProductDetailData, processedNavData, defaultPreselectionConfigData} = res;


      //여러번의 dispatch 가 여러번의 렌더링을 유발할 수 있으므로 batch로 일괄처리 해주는 함수
      const dispatchHandler = async (selectedNav, gnb, connectMode) => {
        await batch(() => {
          dispatch(menuAction.setMenu({key: "navData", value: processedNavData}));
          dispatch(optionAction.setCurrentVehicle(selectedProductDetailData));
          dispatch(optionAction.setLoadData(defaultPreselectionConfigData));
          dispatch(optionAction.setCurrentNavigation(selectedNav.replaceAll(reg, "")));
          dispatch(optionAction.setCheckClickHeader(selectedNav));
          dispatch(optionAction.setOptions({selection: "isGnb", value: gnb}));
          dispatch(themeAction.setConnectMode(connectMode));
        });
      }

      //기본 사이트와 remote/mediawall 사이트의 경로이동 분기처리 함수
      const pathHandler = async (connectMode, isLoadMyDesign, firstNavigationData) => {


        if (connectMode === "web") {
          if(!params["*"]) {

            let path = isLoadMyDesign ? `/${langData.lang}/${selectedVehicleCode}/configurator/exterior` : firstNavigationData.path;
            navigate(path);
          }

        } else if (connectMode === "remote") {
          const peer = await callPeerRemoteModule(connectMode);
          peer.conn.sendData("dispatch", {
            action: "optionAction",
            func: "setCurrentVehicle",
            payload: selectedProductDetailData
          });
          peer.conn.sendData("dispatch", {
            action: "optionAction",
            func: "setCurrentNavigation",
            payload: isLoadMyDesign ? "exterior" : firstNavigationData.id
          });
          peer.conn.sendData("dispatch", {
            action: "optionAction",
            func: "setCheckClickHeader",
            payload: isLoadMyDesign ? "exterior" : firstNavigationData.id
          });

        }
      }

      //조건에 맞춰 위의 두 함수 실행
      if (isLoadMyDesign) {
        dispatchHandler("exterior", false, connectMode);
        pathHandler(connectMode, isLoadMyDesign);

      } else if (!isLoadMyDesign) {
        const firstNavigationData = processedNavData.navForHeader[0] ? processedNavData.navForHeader[0] : {
          id: "",
          path: ""
        };
        dispatchHandler(firstNavigationData.id, firstNavigationData.gnb, connectMode);
        pathHandler(connectMode, isLoadMyDesign, firstNavigationData);
      }
      setNavAndConfigDataReady(true);
    });
  }


  const resetConfigAndEnv = async () => {
    await dispatch(optionAction.setSendData(CONFIG_DATA));
    await window && window.postMessage({
      msg: "camera:change",
      data: [hotspot, camera.currentCameraType, camera.currentCameraCode]
    }, "*");
    await window && window.postMessage({
      msg: "env:change",
      data: background
    });
  }

  useDidMountEffect(() => {
    if (loadingState === "assetLoad:end") {
      resetConfigAndEnv().then(async () => {
        const remoteModePeerConnected = await callPeerRemoteModule(connectedMode);

        setTimeout(() => {
          dispatch(introAction.setLoadingState("end"));

          if (remoteModePeerConnected) {
            remoteModePeerConnected.conn.sendData("dispatch", {
              action: "introAction",
              func: "setLoadingState",
              payload: "end"
            });
          }
        }, 600);
      });
    }
  }, [loadingState, connectedMode, navAndConfigDataReady]);


  useEffect(() => {
    if(isLoadMyOneOfOne || !paramsId) {
      return;
    }
    vehicleSelectHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsId]);


  return {vehicleSelectHandler, navAndConfigDataReady}
}


export default useLoadProductData;
