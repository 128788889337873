import React, {useEffect, useState} from 'react';
// import {extAttract_g90, intAttract_g90, extAttract_gv80, intAttract_gv80} from "../../../../../core/dummy/cameraAttractList";
import { extAttract, intAttract } from "../../../../../core/dummy/cameraAttractList";

import SceneButton from "../atoms/SceneButton";
import SceneThumbnail from "../atoms/SceneThumbnail";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {cameraSelector} from "../../../../redux/toggle-slice/toggleSlice";
import {toggleAction} from "../../../../redux/store";
import callPeerRemoteModule from "../../../../../core/utils/callPeerRemoteModule";
import {platform} from "../../../../../core/platform/platform";


const SceneController = ({setAttract}) => {
  const dispatch = useDispatch();
  const connectMode = useSelector(state => state.theme.connectMode);
  const camera = useSelector(cameraSelector);

  const {attract, currentVehicle} = useSelector(state => ({
    attract: state.toggle.attract,
    currentVehicle: state.optionWindow.currentVehicle
  }),shallowEqual);

  const [attractItems, setAttractItems] = useState([]);
  const [selectedAttr, setSelectedAttr] = useState({EXT: undefined, INT: undefined});

  const sceneChangeHandler = async (attr, selectedAttr, setSelectedAttr) => {
    if((camera.currentCameraType === attr.cameraType) && (camera.currentCameraCode === attr.attrCode)) {
      return;
    }
    let selectedSceneData = {...selectedAttr, ...{[attr.cameraType] : attr}};
    setSelectedAttr(selectedSceneData);
    if(platform.touch) {
      if(attract) {
        setAttract();
      }
    }
    const cameraChangeMessage = attr.cameraType.toLowerCase() + ":camera:change";
    dispatch(toggleAction.setToggle({ key: "camera", value: {currentCameraType: attr.cameraType, currentCameraCode: attr.attrCode} }));

    const peer = await callPeerRemoteModule(connectMode);
    if(peer) {
      peer.conn.sendData(cameraChangeMessage, [attr.attrCode], true);
    } else if(!peer) {
      window && window.postMessage({ msg: cameraChangeMessage, data: [attr.attrCode, true] }, '*');
    }
  }



  useEffect(() => {
    const vehicleCode = currentVehicle && currentVehicle.code && currentVehicle.code.toLowerCase();

    if (!vehicleCode) return;

    let extCameraThumbnails = extAttract[vehicleCode];
    let intCameraThumbnails = intAttract[vehicleCode];

    if (!extCameraThumbnails || !intCameraThumbnails) {
      return;
    }

    const selectedData = !selectedAttr.EXT ? {EXT: extCameraThumbnails[0], INT: intCameraThumbnails[0]} : {EXT: selectedAttr.EXT, INT: intCameraThumbnails[0]};
    setSelectedAttr(selectedData);

    if (camera.currentCameraType === "INT") {
      setAttractItems(intCameraThumbnails);
    } else if (camera.currentCameraType === "EXT") {
      setAttractItems(extCameraThumbnails);
    }
  }, [camera.currentCameraType]);

  return (
    <div className="camera">
      {
        connectMode === "web" ? (
          <SceneThumbnail selectedAttr={selectedAttr} setAttract={setAttract} currentCameraType={camera.currentCameraType}/>
        ) : (
          <div className={"camera-title"}>
            <span>Change<br/>Camera</span>
          </div>)
      }
      <ul className="camera-list">
        { attractItems.map((attr, idx) =>
          <SceneButton key={idx} attr={attr} sceneChangeHandler={sceneChangeHandler} selectedAttr={selectedAttr} setSelectedAttr={setSelectedAttr}/>)
        }
      </ul>
    </div>
  )
}

export default SceneController;
