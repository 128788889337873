import WalkIn from "./WalkIn";
import {useEffect, useState} from "react";
import ModelList from "./ModelList";
import Logo from "../../layout/atoms/Logo";
import {useOutletContext} from "react-router-dom";
import {platform} from "../../../../core/platform/platform";
import axios from "axios";
import {introAction} from "../../../redux/store";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const NewModelSelect = () => {
  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);
  const mobileSuffix = "m_";
  const dispatch = useDispatch();

  const [walkInAnimationDone, setWalkInAnimationDone] = useState(false);
  const {initConnectMode} = useOutletContext();
  const [modelChooseImages, setModelChooseImages] = useState();
  const {walkInImages} = useSelector(state => ({
    walkInImages: state.intro.walkInImages,
  }), shallowEqual);


  useEffect(() => {
    if (!walkInImages) {
      axios.get(`${process.env.PUBLIC_URL}/intro/${sessionStorage.getItem("supportAvif") ? "avif" : "webp"}/${isMobile ? mobileSuffix : ""}walkin-images.json`).then(res => {
        if (res.status === 200) {
          dispatch(introAction.setWalkInImages(res.data));
        }
      });
    }

    axios.get(`${process.env.PUBLIC_URL}/intro/${sessionStorage.getItem("supportAvif") ? "avif" : "webp"}/${isMobile ? mobileSuffix : ""}model-choose-images.json`).then(res => {
      if (res.status === 200) {
        setModelChooseImages(res.data);
      }
    });
  }, []);


  return (
    <>
      <header className={initConnectMode === "remote" ? "model-select-header" : ""}>
        <Logo/>
      </header>
      <main className={"page-model"}>
        {
          !walkInAnimationDone
            ? <WalkIn setWalkInAnimationDone={setWalkInAnimationDone}/>
            : <ModelList modelChooseImages={modelChooseImages}/>
        }
      </main>
    </>
  )
}


export default NewModelSelect;
