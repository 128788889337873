import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_backend_url+"/public/bespoke";

export const requestPdfDownload = async(payload) => {
  const config = {
    method: "POST",
    url: `${API_ENDPOINT}/pdfDownload`,
    responseType: "blob",
    data: payload
  };
  const response = await axios(config);
  return response;
}
