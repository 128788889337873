import Logo from "../layout/atoms/Logo";
import {platform} from "../../../core/platform/platform";
import ModelSelectPc from "./ModelSelectPc";
import {useEffect, useState} from "react";
import {productListApi} from "../../../core/api/introApi";
import {useOutletContext} from "react-router-dom";

const ModelSelect = ({setProductSelected = null}) => {
  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);
  const {vehicleSelectHandler, initConnectMode, navAndConfigDataReady} = useOutletContext();

  const [activeVehicle, setActiveVehicle] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [productList, setProductList] = useState([]);


  useEffect(() => {
    if (isMounted)
      productListApi().then(res => {
        if (res.data.result && res.data.message === "SUCCESS") {
          const vehicleList = res.data.data.content;
          setProductList(vehicleList);

          if ((!isMobile || initConnectMode === "remote") && vehicleList[0]) {
            setActiveVehicle(vehicleList[0].code);
          }

        } else if (!res.data.result) {
          return false;
        }
      }).catch(error => console.log(error));
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const vehicleActiveHandler = (e) => {
    setActiveVehicle(e.currentTarget.id);
  }


  return (
    <>
      <header className={initConnectMode === "remote" ? "model-select-header" : ""}>
        <Logo/>
      </header>


      <div className={isMobile && initConnectMode === "web" ? "model-mobile" : "model-pc"}>
        <ModelSelectPc
          productList={productList}
          activeVehicle={activeVehicle}
          vehicleActiveHandler={vehicleActiveHandler}
          vehicleSelectHandler={vehicleSelectHandler}
          initConnectMode={initConnectMode}
          setProductSelected={setProductSelected}
          navAndConfigDataReady={navAndConfigDataReady}
        />
      </div>
    </>
  )
}

export default ModelSelect;