import {useCallback, useEffect, useRef} from "react";
import {shallowEqual, useSelector} from "react-redux";


const useIdleTime = (currentVehicleIdleTime) => {
  const {isGnb, loadingState, checkClickHeader} = useSelector(state => ({
    isGnb: state.optionWindow.isGnb,
    loadingState: state.intro.loadingState,
    checkClickHeader: state.optionWindow.checkClickHeader,
  }), shallowEqual)

  let activeIdle = false;

  //차량이 가진 idleTime 정보를 ms로 환산. 0이상, 40초 미만은 40초로 한다.
  let idleTime;
  if (!currentVehicleIdleTime) {
    idleTime = 0;
  } else if (currentVehicleIdleTime < 40) {
    idleTime = 40 * 1000;
  } else {
    idleTime = currentVehicleIdleTime * 1000;
  }


  const timerRef = useRef();

  //mousemove, scroll, touchstart, keypress, touchmove 동작을 감지하면 작동하는 함수
  function resetTimer() {
    //브라우저가 사용자의 동작을 감지할 때 마다 clearTimeout -> 새로운 setTimeout 설정이 반복되며 idleTime이 갱신됨
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!idleTime || isGnb || loadingState !== "end" || checkClickHeader.includes("summary")) {
      //위 조건의 경우 clear 이후 새로운 setTimeout 을 적용하지 않기 때문에 idleTime 만큼 동작을 멈춰도 idle animation 은 작동하지 않는다.
      return;
    }

    const wrapTag = document.getElementById("wrap");

    if(!wrapTag) {
      return;
    }

    if (activeIdle) { //이미 idle animation 이 실행중일 때 동작을 감지하면 idle animation 을 중지한다.
      window.postMessage({msg: "idleAnimation:stop"});
      wrapTag.classList = "wrap";
      activeIdle = false;
    }


    timerRef.current = setTimeout(() => { // idleTime 만큼의 시간이 지난 후 setTimeout 안의 콜백이 실행.
      window.postMessage({msg: "idleAnimation:run"});
      wrapTag.classList = "wrap is-standby";
      activeIdle = true;
    }, idleTime);
  }

  //브라우저에 전달할 이벤트 항목들
  let EVENTS = ['mousemove', 'scroll', 'touchstart', 'keypress', 'touchmove'];

  useEffect(() => {
    if (idleTime && loadingState === "end") { // idleTime 이 있는 차량의 경우 loadingState 가 end 가 된 시점에 최초에 함수 실행
      resetTimer();
    }

    EVENTS.forEach(function (eventName) {
      window.addEventListener(eventName, resetTimer);
    })

    return () => {
      EVENTS.forEach(function (eventName) {
        window.removeEventListener(eventName, resetTimer);
      })
    }
  }, [idleTime, loadingState, isGnb, checkClickHeader]);


  //브라우저 최소화로 페이지가 백그라운드 실행 상태가 되면 설정된 setTimeout 을 삭제해준다.
  document.addEventListener('visibilitychange', useCallback(function () {
    if (document.hidden) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    } else {
      resetTimer();
    }

  }, [timerRef.current, document]))


  return {}
}

export default useIdleTime;