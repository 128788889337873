import React from "react";


const ColorTypeSelectButtons = ({selectedColorType, setSelectedColorType}) => {


  return (
    <div className={"color-type-select"}>
      <label className={"checkbox"}>
        <input type={"radio"} name={"colorType"} checked={selectedColorType === "rgb"} onChange={() => setSelectedColorType("rgb")}/>
        <span>RGB</span>
      </label>
      <label className={"checkbox"}>
        <input type={"radio"} name={"colorType"} checked={selectedColorType === "lab"} onChange={() => setSelectedColorType("lab")}/>
        <span>LAB</span>
      </label>
    </div>
  )

}

export default ColorTypeSelectButtons;

