import React from "react";
import {transferLanguage} from "../../../../../core/utils/tools";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";


const ColorPickerRange = ({color, colorMode, selectedColorType, inputColorChange}) => {
  const langData = useSelector(langDataSelector);

  return (
    <div className={"color-range"}>
      {
        Object.keys(color[colorMode]).map((colorCode, index) => {

          if(colorMode === selectedColorType){
            return (
              <div key={`${colorMode}_${colorCode}`}>
                <span>{transferLanguage(langData.lang, `${colorMode}_${colorCode}`)}</span>
                <div className={"input-range"}>
                  <input type={"range"} value={color[colorMode][colorCode] || 0}
                         min={colorMode === "rgb" || (colorMode === "lab" && colorCode === "l") ? 0 : -120}
                         max={colorMode === "rgb" ? 255 : colorCode === "l" ? 100 : 120 }
                         onChange={(e) => {
                           inputColorChange(colorMode, colorCode, e.target.value)}
                         }
                  />
                </div>
                <div className={"input-num"}>
                  <input type={"text"}
                         value={color[colorMode][colorCode]}
                         onChange={(e) => {
                           inputColorChange(colorMode, colorCode, e.target.value)}
                         }
                  />
                </div>
              </div>)
          }

        })
      }
    </div>
  )
}

export default ColorPickerRange;