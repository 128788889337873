import React, {useEffect, useRef, useState} from "react";
import {rgb_to_lab} from "../../../../../core/utils/colorConverts";
import {optionAction} from "../../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";

const useColorPickerTab = (code) => {
  const dispatch = useDispatch();
  const sendData = useSelector(configDataSelector);

  const selectSubOption = code;
  const [colorMaterial,setColorMaterial] = useState("GLOSSY")


  useEffect(() => {
    if(!sendData[code]) {
      return;
    }

    if(sendData[code].CUSTOM){
      const customColorRGB = sendData[code].CUSTOM.DIFFUSE;
      const customColorMaterial = sendData[code].CUSTOM.MATERIAL;
      const customColorLab = rgb_to_lab(customColorRGB.r,customColorRGB.g,customColorRGB.b);

      setColor({
        rgb: customColorRGB,
        lab: customColorLab
      });
      setColorMaterial(customColorMaterial);
    } else if(sendData[code].DIFFUSE) {
      const colorRgbData = sendData[code].DIFFUSE.split(",");
      const colorRgb = { r: parseInt(colorRgbData[0]), g: parseInt(colorRgbData[1]), b: parseInt(colorRgbData[2]) };
      const colorMaterial = sendData[code].MATERIAL;
      const colorLab = rgb_to_lab(colorRgb.r, colorRgb.g, colorRgb.b);


      setColor({
        rgb: colorRgb,
        lab: colorLab
      });
      setColorMaterial(colorMaterial);
    }
  },[code]);


  const [color,setColor] = useState({
    rgb:{r:0, g:0, b:0},
    lab:{l:0, a:0, b:0}
  })
  const [pickerType,setPickerType] = useState("COLOR");
  const [selectImage,setSelectImage] = useState(false);
  const [imageData,setImageData] = useState([]);
  const colorRef =  useRef()
  const fileRef = useRef();
  const imageRef = useRef();
  const imagePickerPointer = useRef();
  const imagePickerBgColorRef = useRef();


  const changeColor = (c)=>{
    if(c.a){
      delete c.a
    }
    const lab = rgb_to_lab(c.r,c.g,c.b);
    setColor({
      rgb:c,
      lab:lab
    });
  }





  const clickApply = ()=>{
      const copySendData = {...sendData[selectSubOption]};
      const {DIFFUSE, MATERIAL, ...rest} = copySendData;

      const colorCode = Object.keys(color.rgb);
      const copyColor = {...color};
      for(let i of colorCode) {
        if(isNaN(color.rgb[i])){
          return;
          break;
        } else if(color.rgb[i] === ""){
          copyColor.rgb = {r: 0, g: 0, b: 0};
          changeColor(copyColor.rgb);
          break;
        }
      }







      dispatch(optionAction.setSendData({
        key:selectSubOption,
        value:{
          ...rest,
          COLOR:"",
          CUSTOM:{
            DIFFUSE:{...copyColor.rgb},
            MATERIAL: selectSubOption.includes("EXT") ? colorMaterial : "MATT"
          }
        }
      }))

  }


  const imageChange = (e)=>{
    if (fileRef.current.files && fileRef.current.files[0]) {
      const reader = new FileReader();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      reader.readAsDataURL(fileRef.current.files[0]);
      reader.onload = async function(e) {

        const newImage = new Image();
        newImage.src = e.target.result;

        newImage.onload = async function(){
          const imageWidth = this.width;
          const imageHeight = this.height;

          const resizeCanvasHeight = async() => {
            return parseInt(((imageRef.current.width * imageHeight)/imageWidth).toString());
          }

          canvas.width = imageRef.current.width;
          canvas.height = await resizeCanvasHeight();

          ctx.drawImage(newImage,0,0,canvas.width,canvas.height);
          const data = ctx.getImageData(0,0,imageRef.current.width,canvas.height).data;
          setImageData(data);


          imageRef.current.src =canvas.toDataURL();
          setSelectImage(true);
          imagePickerPointer.current.style.top = "0%";
          imagePickerPointer.current.style.left = "0%";
          imagePickerBgColorRef.current.style.backgroundColor = "";
          changeColor({r:0,g:0,b:0});
        }
      };
    } else {
      imageRef.current.src = "";
      setSelectImage(false);
    }
  }



  return {
    color, setColor,
    clickApply, changeColor, imageChange,
    colorRef, fileRef, imageRef, imagePickerPointer, imagePickerBgColorRef,
    pickerType, setPickerType,
    selectImage, imageData,
    colorMaterial, setColorMaterial,
  }
}

export default useColorPickerTab;
