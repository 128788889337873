import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";


const usePreviewColor = (code, colorItemObject) => {
  const sendData = useSelector(configDataSelector);
  const langData = useSelector(langDataSelector);


  const findCurrentColor = useCallback((selected, colorItemObject, langName) => {
    let curr = {};
    const colorList = colorItemObject.itemOptions;

    if (selected) {                     // config data에 해당 데이터가 있을경우
      if (selected.CUSTOM) {          // 해당 값에 custom 데이터가 있을 경우
        curr.COLOR = `${selected.CUSTOM.DIFFUSE.r},${selected.CUSTOM.DIFFUSE.g},${selected.CUSTOM.DIFFUSE.b}`              // 컬러칩 색상 custom 데이터에 맞게 변경
        curr.TITLE = "Custom color(R" + selected["CUSTOM"]['DIFFUSE'].r + ",G" + selected["CUSTOM"]['DIFFUSE'].g + ",B" + selected["CUSTOM"]['DIFFUSE'].b + ")"  // custom 데이터에 맞게 title 변경
        curr.MATERIAL = selected.CUSTOM.MATERIAL // 재질 변경
      } else {
        const selectedColor = colorList.find(e => e.color && e.color.code === selected.COLOR); // code에 맞는 컬러 데이터 검색
        if (selectedColor && selectedColor.color) {
          curr.COLOR = selectedColor.color.diffRgb //
          curr.TITLE = selectedColor[langName]
          curr.MATERIAL = String(selectedColor.color.materialType).toUpperCase()
        }
      }
      return curr;
    } else {
      return null;
    }
  },[]);



  const [currentColor, setCurrentColor] = useState();
  useEffect(() => {
    const findColor = findCurrentColor(sendData[code], colorItemObject, langData.langName);
    setCurrentColor(findColor);
  }, [sendData[code]])

  return {
    currentColor
  }

}

export default usePreviewColor;