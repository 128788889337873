import TouchController from "../components/independent/TouchController";
import {menuAction} from "../redux/store";
import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import ModalIndex from "../components/modal/ModalIndex";
import PreSelection from "../components/preselection/template/PreSelection";
import Summary from "../components/summary/templates/Summary";
import Controllers from "../components/controller/Controllers";
import withPeerConnect from "../hoc/withPeerConnect";
import Layout from "../components/layout/template/Layout";
import {useOutletContext} from "react-router-dom";
import MediaWallLoading from "../components/independent/MediaWallLoading";


const Remote = () => {
  const dispatch = useDispatch();
  const { vehicleSelectHandler } = useOutletContext();

  const {currentNavigation, checkClickHeader, loadingState} = useSelector(state => ({
    currentNavigation: state.optionWindow.currentNavigation,
    checkClickHeader: state.optionWindow.checkClickHeader,
    loadingState: state.intro.loadingState,
  }),shallowEqual);




  return (
    <>
      {
        (loadingState !== "end") ? (
          <MediaWallLoading/>
          ) :
        <>
          <TouchController/>
          <div className="overlay" onClick={() => dispatch(menuAction.menuToggle())}></div>
          <Layout vehicleSelectHandler={vehicleSelectHandler}/>
          { currentNavigation.includes("collection") && <PreSelection/>}
          <Controllers/>
          <Summary checkClickHeader={checkClickHeader}/>
          <ModalIndex/>
        </>
      }
    </>
  )
}

export default withPeerConnect(Remote);


