import React, {Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom"
import IntroPage from "../components/intro/IntroPage";
import PrivateRoute from "./route/PrivateRoute";
import MediaWall from "../pages/MediaWall";
import {
  isDesktop, isMobile, isTablet,
  deviceType, OsTypes, getUA, osName, isAndroid, isIOS, isIOS13, isBrowser, isIPad13,  isWindows, isMacOs
} from "react-device-detect";
import Web from "../pages/Web";
import PreSelection from "../components/preselection/template/PreSelection";
import Summary from "../components/summary/templates/Summary";
import Controllers from "../components/controller/Controllers";
import Remote from "../pages/Remote";
import NewModelSelect from "../components/intro/newModelSelect/NewModelSelect";

const MediaWallStyleRoute = React.lazy(() => import("./route/MediaWallStyleRoute"));
const RemoteStyleRoute = React.lazy(() => import("./route/RemoteStyleRoute"));
let DeviceStyleRoute;
if (isDesktop && !isTablet && !isMobile) {
  DeviceStyleRoute = React.lazy(() => import("./route/DesktopStyleRoute"));
} else if (isMobile && isTablet) {
  DeviceStyleRoute = React.lazy(() => import("./route/TabletStyleRoute"));
} else if (isMobile && !isTablet && !isDesktop) {
  DeviceStyleRoute = React.lazy(() => import("./route/MobileStyleRoute"));
}



const Router = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route path={"/"} element={<Navigate to={"/en/intro"}/>}/>
        <Route path={"/tablet"} element={<Navigate to={"/en/tablet"}/>}/>
        <Route path={"/mediawall"} element={<Navigate to={"/en/mediawall"}/>}/>


        <Route element={<RemoteStyleRoute/>}>
          <Route path={"/:lang/tablet"} element={<Remote/>}/>
        </Route>

        <Route element={<MediaWallStyleRoute/>}>
          <Route path={"/:lang/mediawall"} element={<MediaWall/>}/>
        </Route>


        <Route element={<DeviceStyleRoute/>}>

          <Route path={"/:lang/intro"} element={<IntroPage/>}/>
          <Route path={"/code/:bespokeCode"} element={<IntroPage/>}/>

          <Route element={<PrivateRoute/>}>
            <Route path={":lang/model-select"} element={<NewModelSelect/>}/>
            <Route path={"/:lang/:id/*"} element={<Web/>}>
              <Route path={"collection"} element={<PreSelection/>}/>
              <Route path={"configurator/:conf"} element={<Controllers/>}/>
              <Route path={"summary"} element={<Summary/>}/>
            </Route>
          </Route>

        </Route>

        <Route path="*" element={<Navigate to={"/en/intro"}/>}/>
      </Routes>
    </Suspense>
  )
}
export default Router;
