import React from "react";
import RgbInputs from "../atoms/RgbInputs";
import useColorPickerColorTab from "./useColorPickerColorTab";
import Magnifier from "../atoms/Magnifier";
import Button from "../../../independent/Button";
import ColorPicker from "../atoms/ColorPicker";

const ColorPickerColorTab = ({color, setColor, changeColor, clickApply, colorRef, pickerType}) => {
  const {colorData, magnifier, isShow, magniState} = useColorPickerColorTab(['sketch-picker', 'saturation-white'], colorRef, 3);



  return (
    <div style={{display: pickerType === "COLOR" ? "block" : "none"}}>
      <ColorPicker color={color} changeColor={changeColor} colorRef={colorRef}/>
      <RgbInputs color={color} setColor={setColor}/>
      <Button name={"Apply"} confirm={true} block={true} onClick={clickApply}/>
      {colorData.length > 0 && (
        <Magnifier magniState={magniState} isShow={isShow} magnifier={magnifier} colorData={colorData}/>)
      }
    </div>
  )
}

export default ColorPickerColorTab;
