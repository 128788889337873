import {tabNameMapping} from "../../../../../core/utils/tools";
import React from "react";
import useTabs from "./useTabs";


const Tabs = ({currentTab, setCurrentTab, setColorItemObject, stepObject, setColorByCategory, code, codeKeyData}) => {
  const {extraTabList} = useTabs(setColorByCategory, setColorItemObject, stepObject, code, codeKeyData, setCurrentTab);



  return (
    <div className="tabs">
      <button type="button" className={`tab ${currentTab === (stepObject.stepType === "COLOR" ? "COLOR" : "OPTION") && "active"}`}
              onClick={(e) => setCurrentTab(stepObject.stepType === "COLOR" ? "COLOR" : "OPTION")}>
        { stepObject.stepType === "COLOR" ? "Color" : "Option" }
      </button>
      {extraTabList.map(item => {
        return (
          <button key={item} type="button" className={`tab ${currentTab === item && "active"}`} disabled={item !== "CUSTOM_COLOR" && !codeKeyData}
                  style={{color: currentTab === item ? "rgba(255,255,255,1.0)" : codeKeyData || item === "CUSTOM_COLOR" ? "rgba(255,255,255,0.6)" : "rgba(255,255,255,0.3)"}}
                  onClick={(e) => setCurrentTab(item)}
          >
            {tabNameMapping(item)}
          </button>)
      })
      }
    </div>)
}

export default Tabs;