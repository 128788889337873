import React, {useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {confLoad} from "../../../core/api/configuratorApi";
import {useSelector} from "react-redux";
import {introSignInApi} from "../../../core/api/introApi";
import {langDataSelector} from "../../redux/theme-slice/themeSlice";
import useLoadProductData from "./useLoadProductData";

const useLoadMyOneOfOne = () => {
  const nav = useNavigate();
  const langData = useSelector(langDataSelector);
  const connectMode = useSelector(state => state.theme.connectMode);
  const {vehicleSelectHandler} = useLoadProductData(true);


  const requestApiHandler = (payload, checkData) => {
    introSignInApi(payload).then(res => {
      if (res.data.result && res.data.message === "COMMON_USER") {
        nav(`/${langData.lang}/model-select`);
        sessionStorage.setItem("auth", res.data.result);
      } else if(res.data.result && res.data.message === "CONFIGURATION_USER") {

        const {commonUserBespokeCode} = payload;
        loadMyOneOfOne(commonUserBespokeCode, res.data.result);

      } else if (!res.data.result) {

        const attemptsData = {
          value: parseInt(checkData.value) + 1,
          expire: Date.now() + 60000
        };
        alert(`You entered the wrong One of One Code. (${parseInt(checkData.value) + 1}/5)`);
        sessionStorage.setItem("loginAttempt", JSON.stringify(attemptsData));
      }
    });
  };




  const loadMyOneOfOne = async(bespokeCode, result) => {//bespoke Code loader
    sessionStorage.setItem("auth", result);

    await confLoad(bespokeCode).then(res => {
      if(res.data.result && res.data.message === "SUCCESS"){
        const configData = res.data.data.config_data;
        const productCode = res.data.data.productCode.toLowerCase();
        const loadMyDesign = true;
        vehicleSelectHandler(productCode, loadMyDesign, connectMode, configData);
      }
    });
  }



  let bespokeCodeTimer;
  const introCodeSubmitHandler = (bespokeCode, getEntireCodeFromInput) => {
    if(bespokeCodeTimer) {
      clearTimeout(bespokeCodeTimer);
    }

    let payload = {commonUserName: "commonUser", commonUserBespokeCode: ""};

    if (bespokeCode) { //parameter code 존재할 경우
      payload.commonUserBespokeCode = bespokeCode;
      introSignInApi(payload).then(res => {
        if (res.data.result && res.data.message === "CONFIGURATION_USER") {
          loadMyOneOfOne(bespokeCode, res.data.result);
        }
      })
      return;
    }

    let finalCode = getEntireCodeFromInput();
    payload.commonUserBespokeCode = finalCode;
    let checkData = {value: 0, expire: null};
    const attemptsData = sessionStorage.getItem("loginAttempt");

    if (attemptsData) {
      checkData = JSON.parse(attemptsData);
    }

    if (checkData.value < 5) {
      requestApiHandler(payload, checkData);
    } else if (checkData.value >= 5 && (Date.now() > checkData.expire)) {
      sessionStorage.removeItem("loginAttempt");
      checkData = {value: 0, expire: null};
      requestApiHandler(payload, checkData);
    } else {
      alert("Number of inputs exceeded. Try after 60 seconds.");
    }
  };




  // intro, load my design input handler
  let initialValue = new Array(7).fill("");
  const codeInputRefs = useRef([]);
  codeInputRefs.current = initialValue.map((ref, index) => codeInputRefs.current[index] = React.createRef());


  const onInputHandler = async (e, index) => {
    e.preventDefault();
    const characterCode = e.target.value.toUpperCase();
    const regexp = /^[A-Za-z0-9]$/;
    const isValid = regexp.test(characterCode);

    if (isValid) {
      codeInputRefs.current[index].current.value = characterCode;
      const nextIdx = index + 1;
      const exIdx = index - 1;

      if (nextIdx <= initialValue.length) {
        codeInputRefs.current[nextIdx] && codeInputRefs.current[nextIdx].current.focus();
        codeInputRefs.current[nextIdx] && codeInputRefs.current[nextIdx].current.select();

        if (exIdx >= 0) {
          codeInputRefs.current[exIdx].current.type = "password";
        }

        if (index === 6) {
          codeInputRefs.current[index-1].current.type = "password";
          bespokeCodeTimer = setTimeout(() => {
            codeInputRefs.current[index].current.type = "password";
          }, 1000);
        }
      }
    }
  }

  const keyDownHandler = async (e, index, enterKeyEvent) => {
    // e.preventDefault();

    const characterCode = String.fromCharCode(e.which);

    if (e.key === 'Backspace') {
      codeInputRefs.current[index].current.value = '';
      codeInputRefs.current[index].current.type = "text";
      if (index > 0) {
        const prevIndex = index - 1;
        codeInputRefs.current[prevIndex].current.type = "text";
        // codeInputRefs.current[prevIndex].current.value = '';
        codeInputRefs.current[prevIndex].current.focus();
        // codeInputRefs.current[prevIndex].current.select();
      }
      return;
    }
    if ((e.ctrlKey || e.metaKey) && characterCode.toLowerCase() === 'v') {
      await pasteFullCode();

      return;
    }

    if(e.key === "ArrowLeft" || e.key === "ArrowRight") {
      let idx;
      if(e.key === "ArrowLeft") {
        idx = index - 1;
      } else if(e.key === "ArrowRight") {
        idx = index + 1;
      }

      if(idx >= 0 && idx < initialValue.length) {
        codeInputRefs.current[idx].current.focus();
      }
    }

    if(index === 6 && e.key === "Enter") {
      if(!enterKeyEvent) {
        return false;
      }
      enterKeyEvent();
    }
  };


  const pasteFullCode = async () => {
    const clipboardText = await navigator.clipboard.readText();
    const regexp = /^[A-Za-z0-9]+$/;
    const isValid = regexp.test(clipboardText);
    if(!isValid) {
      return;
    }
    for (let i = 0; i < initialValue.length; i++) {

      codeInputRefs.current[i].current.focus();
      codeInputRefs.current[i].current.select();
      codeInputRefs.current[i].current.value = String.fromCharCode(clipboardText.charCodeAt(i)).toUpperCase();
    }
  }


  const getEntireCodeFromInput = () => {
    let finalCode = '';
    codeInputRefs.current.map((ref) => {
      finalCode += ref.current.value;
    });
    return finalCode;
  }






  return {
    introCodeSubmitHandler,
    getEntireCodeFromInput, keyDownHandler, initialValue, codeInputRefs, onInputHandler
  }
}

export default useLoadMyOneOfOne;
