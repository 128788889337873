import bg_1 from "../../app/assets/images/bg_cityview.png";
import bg_2 from "../../app/assets/images/bg_02.jpg";
import bg_3 from "../../app/assets/images/bg_03_brick.png";
import bg_desert from "../../app/assets/images/bg_desert.png"
// {
//     envCode : 'BLACK',
//     img:bg_2,
//     title:"night"
// },
export const backgroundData = [
    {
        envCode : 'EXT01',
        img:bg_3,
        title:"EXT01"
    },
    {
        envCode : 'CITY',
        img:bg_1,
        title:"City"
    },
    {
        envCode: 'DESERT',
        img: bg_desert,
        title:"Desert"
    },
]
