import { createSlice } from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
    configId: null,
    configurationUser: false,
}

const qrcodeSlice = createSlice({
    name : "qrcode",
    initialState : initialState,
    reducers : {
        setQRcode(state,action) {
            state.configId= action.payload;
        },
        setConfigurationUser(state,action) {
            state.configurationUser = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
})


export default qrcodeSlice
