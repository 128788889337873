import Header from "../organisms/Header";
import Menu from "../organisms/Menu";
import React from "react";

const Layout = () => {

  return (
    <>
      <Header/>
      <Menu/>
    </>
  )
}

export default React.memo(Layout);