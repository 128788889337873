import {useEffect, useRef} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {platform} from "../../../../core/platform/platform";




const WalkIn = ({setWalkInAnimationDone}) => {
  const { walkInImages } = useSelector(state => ({
    walkInImages: state.intro.walkInImages
  }),shallowEqual);
  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);


  const imageRef = useRef();
  let walkInAnimationIndex = 0;
  let lastTimestamp = null;
  const speed = 1.2;
  const frame = 1000 / 60;

  const animate = (timestamp) => {
    if (!lastTimestamp) {
      lastTimestamp = timestamp;
    }

    const timeElapsed = (timestamp - lastTimestamp)/speed;
    if (Math.floor(timeElapsed) >=  Math.floor(frame * speed)) {

      if (!imageRef.current){
        return;
      }
      imageRef.current.src = walkInImages && walkInImages[walkInAnimationIndex].dataURI;
      walkInAnimationIndex = (walkInAnimationIndex + 1) % walkInImages.length;

      const stopAnimationIndex = walkInImages.length - 1
      if (walkInAnimationIndex === stopAnimationIndex) {
        setWalkInAnimationDone(true);


        return;
      }
      lastTimestamp = timestamp;
    }
    requestAnimationFrame(animate);
  }


  useEffect(() => {
    if (!walkInImages) {
      return;
    }
    animate();
  }, [walkInImages]);



  return (
    <div className={"model-sequence"}>
      <img ref={imageRef} src={`${process.env.PUBLIC_URL}/intro/${isMobile ? "m_" : ""}base-walk-in-image.webp`}/>
    </div>
  )
}

export default WalkIn;