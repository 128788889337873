import rotateDeviceImg from "../../assets/images/rotate_device.svg";
import rotateArrowImg from "../../assets/images/rotate_arrow.svg";
import React from "react";


const RotateDevice = () => {



  return (
    <div className="rotate-device">
      <div className="rotate-img">
        <img src={rotateDeviceImg} className="rotate-device-img"/>
        <img src={rotateArrowImg} className="rotate-arrow-img"/>
      </div>
      <p>Rotate your device to continue</p>
    </div>
  )
}

export default RotateDevice;