import React from "react";


const SlideToSubButton = ({item, slideToSubHandler}) => {


  return (
    <div className={"slide-to-sub"}>
      <button type={"button"} className={"btn arrow-right"} data-block
              onClick={() => slideToSubHandler(item)}>
        <span>{item.nameEn}</span>
      </button>
    </div>
  )
}
export default SlideToSubButton;