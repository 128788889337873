

const createCopyConfig = (copyConfig, configDataKeys) => {

  for(let i=0; i < configDataKeys.length; i++ ) {
    const key = configDataKeys[i];

    if(copyConfig[key]["NOTES"]) {
      delete copyConfig[key]["NOTES"];
    }
  }
  return copyConfig;
}

export default createCopyConfig;