import React from "react";
import useSetDefaultOption from "../../../hooks/core/useSetDefaultOption";
import CardVisibleSide from "./CardVisibleSide";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";


const PreSelectionCard = ({cardFlipHandler, id, extThumbnail, intThumbnail, descKrExt, descEnExt, descKrInt, descEnInt, nameKr, nameEn, cardFlipToggle, selectDefaultOption}) => {
  const langData = useSelector(langDataSelector);
  const currentVehicle = useSelector(currentVehicleSelector);



  return (
    <div className="card card-flip" data-cardflip={cardFlipToggle.includes(id)}>
      <div className="toggle-flip" onClick={cardFlipHandler ? () => cardFlipHandler(id) : null}></div>
      <div className="card-inner">
        <CardVisibleSide callBack={() => selectDefaultOption(currentVehicle.id, id)}
                         image={extThumbnail} cardSide={"front"}
                         name={langData === "kr" ? nameKr : nameEn}
                         desc={langData === "kr" ? descKrExt : descEnExt}
         />
        <CardVisibleSide callBack={() => selectDefaultOption(currentVehicle.id, id)}
                         image={intThumbnail} cardSide={"back"}
                         name={langData === "kr" ? nameKr : nameEn}
                         desc={langData === "kr" ? descKrInt : descEnInt}
        />
      </div>
    </div>)
}

export default PreSelectionCard;
