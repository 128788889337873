import React from 'react';

import useToggleController from "../../../../hooks/controller/useToggleController";
import SceneController from "../molecules/SceneController";
import BackgroundController from "../molecules/BackgroundController";
import ExtOnlyControllers from "../atoms/ExtOnlyControllers";
import IntOnlyControllers from "../atoms/IntOnlyControllers";
import MovingParts from "../../../independent/MovingParts";
import {shallowEqual, useSelector} from "react-redux";
import CaptureButton from "../atoms/CaptureButton";

const EnvControllers = () => {


  const {backgroundView, connectMode} = useSelector(state => ({
    backgroundView: state.toggle.backgroundView,
    connectMode: state.theme.connectMode
  }),shallowEqual);

  const {
    lightEvent,
    extLight,
    intLight,
    hotspotEvent,
    camera,
    attract,
    attractView
  } = useToggleController();



  //추후 이벤트 핸들러 및 상태값 추가하여 IntOnlyControllers 컴포넌트에 전달하여 작동하도록 수정 예정.
  //추후 Ext, Int 에 새로운 기능 및 버튼이 추가될 수 있다는 전제 및 가독성 위해 Ext/Int 컴포넌트 구분.
  return (
    <div className="view-control-wrap" data-camera-open={attract} data-background-open={backgroundView}>
      {connectMode === "remote" && <MovingParts/> }
      <SceneController setAttract={attractView} />
      <div className="controls">
        <BackgroundController/>
        {camera.currentCameraType === "EXT"
          ? <ExtOnlyControllers light={extLight} lightEvent={lightEvent} hotspotEvent={hotspotEvent}/>
          : <IntOnlyControllers light={intLight} lightEvent={lightEvent}/>
        }
        {
          connectMode === "web" ? <CaptureButton/> : null
        }

      </div>
    </div>
  )
}

export default EnvControllers;
