/**
 * get color pixel data
 * */

import {ConvertRGBtoHex, getHSVToPosition, hsv_to_hsl, hsvToRgb} from "./colorConverts";

export const getColorPixelData = (x,y,obj,ref)=>{
    const hsvData = getHSVToPosition(obj,x,y);
    const hsv ={h: ref.current.state.hsv.h, s: hsvData.saturation, v: hsvData.bright, a: 1}
    const hsl =hsv_to_hsl({...hsv})

    const rgb =hsvToRgb(hsv.h,hsv.s,hsv.v)
    const hex =ConvertRGBtoHex(rgb.r,rgb.g,rgb.b)

    const colorData = {
        hex: hex,
        hsl: hsl,
        hsv: hsv,
        oldHue: ref.current.state.oldHue,
        rgb: rgb,
        source: "hsv"
    }

    return colorData
}
