import {optionAction} from "../../../../redux/store";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";


const useNotesTab = (code) => {
  const dispatch = useDispatch();
  const sendData = useSelector(configDataSelector);

  const [notesValue, setNotesValue] = useState("");
  const [isNoSelected, setIsNoSelected] = useState(false);

  const setNotesHandler = (key, code) => {
    let text = notesValue;
    if (key === "delete") {
      setNotesValue("");
      text = "";
    }
    dispatch(optionAction.setNotes({key: code, value: {"NOTES": text} }));
  }




  useEffect(() => {
    const noSelect = !Boolean(sendData[code]);
    if (noSelect) {
      setIsNoSelected(noSelect);
      setNotesValue("");
    } else {
      setNotesValue(sendData[code]["NOTES"] || "");
    }
  }, [sendData[code]]);

  return {
    isNoSelected, notesValue, setNotesValue, setNotesHandler
  }

}

export default useNotesTab;