import StepsList from "./StepsList";
import React, {useRef} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";

const ChildNavigation = ({childNavigationData, slideToSubHandler, slideToMainHandler}) => {
  const scrollbarsRef = useRef();


  return (
    <div className={"option-subwrap"}>
      <div className={"option-subwrap-header"}>
        <button type={"button"} className={"slide-to-main"} onClick={slideToMainHandler}>back to main</button>
        <h1>{childNavigationData && childNavigationData.nameEn}</h1>
      </div>

      <div className={"option-subwrap-inner"}>
        <Scrollbars ref={scrollbarsRef}>
          <StepsList navigationDataObject={childNavigationData}
                     isParentNav={false}
                     slideToSubHandler={slideToSubHandler}
          />
        </Scrollbars>
      </div>
    </div>)
}

export default ChildNavigation;