import React from "react";
import PartChip from "../atoms/PartChip";
import NoneChip from "../atoms/NoneChip";

const Part2dTab = ({personalItemObject,code, codeKeyData}) => {

  return (
    <div className={"tab-content active"}>
    <div className={"option"}>
      <ul className={"option-list"}>
        <NoneChip codeKeyData={codeKeyData} code={code}/>
        <PartChip code={code} itemObject={personalItemObject} codeKeyData={codeKeyData} />
      </ul>
    </div>
    </div>
  )
}
export default Part2dTab;
