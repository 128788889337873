import React from "react";


const SceneButton = ({sceneChangeHandler, attr, setSelectedAttr, selectedAttr}) => {

  return (
    <li>
      <button type="button" className="scene" onClick={() => sceneChangeHandler(attr, selectedAttr, setSelectedAttr)}>
        <img src={attr.img}/>
      </button>
    </li>

  )
}
export default SceneButton;