import useTargetCamera from "../hooks/controller/useTargetCamera";
import {useDispatch, useSelector} from "react-redux";
import {optionAction} from "../redux/store";

const withShootingPointHandler = (InnerComponent) => {

  return (props) => {
    const dispatch = useDispatch();
    const {shootingPointChange} = useTargetCamera();
    const linkPath = props.linkPath;

    const currentStep = useSelector(state => state.optionWindow.currentStep);
    const dataSub = useSelector(state => state.optionWindow.dataSub);


    const shootingPointChangeHandler = (e, stepObject, target) => {
      shootingPointChange(stepObject, target);

      if(e.target.id !== "delete_button") {
        let stepCode = currentStep === props.code ? "" : props.code;
        dispatch(optionAction.setCurrentStep(stepCode));
      }
      const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\+<>@\#$%&\\\=\(\'\"]/gi;
      const replaceLinkPath = linkPath.replaceAll(reg,"");

      if(linkPath && !dataSub) {
        dispatch(optionAction.setCurrentNavigation(replaceLinkPath));
      }
    }

    return (
      <InnerComponent
        {...{
          ...props,
          shootingPointChangeHandler
        }}
      />
    )

  }
}

export default withShootingPointHandler;