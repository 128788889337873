/**
 * theme set redux
 * */

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
  theme : "dark",
  langData : {lang: "en", langName: "nameEn", langDesc: "descEn"},
  connectMode : "web"   //web, remote, mediaWall
}

export const themeSlice = createSlice({
  name : "theme",
  initialState : initialState,
  reducers : {
    lightTheme(state) {
      state.theme = "light";
    },
    darkTheme(state) {
      state.theme = "dark";
    },
    setLangData(state, action) {
      if(action.payload === "en"){
        state.langData = {lang: "en", langName: "nameEn", langDesc: "descEn"}
      } else if(action.payload === "kr"){
        state.langData = {lang: "kr", langName: "nameKr", langDesc: "descKr"}
      }
    },
    setConnectMode(state, action) {
      state.connectMode = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
})

export const langDataSelector = createSelector(
  (state) => state.theme.langData || initialState.langData,
  (langData) => {
    return langData;
  }
)