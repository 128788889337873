import React from "react";


const SceneThumbnail = ({setAttract, selectedAttr, currentCameraType}) => {


  return (
    <button type="button" className="scene scene-current" onClick={() => setAttract()}>
      { currentCameraType === "EXT" ?
        <img src={selectedAttr.EXT && selectedAttr.EXT.img}/> :
        <img src={selectedAttr.INT && selectedAttr.INT.img}/>
      }
    </button>)
}
export default SceneThumbnail;