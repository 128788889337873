export const buildConfigSnippet = (configData) => {
  const snippet = {};
  if(configData.code) {
    snippet.COLOR = configData.code;
  }

  if(configData.diffRgb) {
    snippet.DIFFUSE = configData.diffRgb;
  }

  if(configData.materialType) {
    snippet.MATERIAL = configData.materialType.toUpperCase();
  }
  return snippet;
}



export const setPreselectionVehicleOption = async(default_config) => {

  const temp = {}
  const defaultKeys = Object.keys(default_config);

  for(var i = 0 ; i < defaultKeys.length;i++){
    const key = defaultKeys[i];
    const defaultData = default_config[key];

    if(defaultData !== "") {
      if(defaultData.type == "INT" || defaultData.type == "EXT"){
        if(key.includes("EXT") && key.includes("PRIMARY")){
          temp["EXT_MAIN"] = buildConfigSnippet(defaultData);

        }else if(key.includes("EXT") && key.includes("SECONDARY")){
          temp["EXT_SPECIAL"] = buildConfigSnippet(defaultData);

        }else if(key.includes("INT") && key.includes("KEY")){
          temp["INT_KEY"] = buildConfigSnippet(defaultData);

        }else if(key.includes("INT") && key.includes("SECONDARY")){
          temp["INT_SECONDARY"] = buildConfigSnippet(defaultData);

        }else if(key.includes("COLOR")){
          temp["INT_" + key] = buildConfigSnippet(defaultData);
        }
      }
      else{
        const partData = defaultData.partSwitch ? defaultData.partSwitch : defaultData.materialSwitch;
        if(partData) {
          temp[key] = {"CODE":partData.code}
        } else if(!partData){
          temp[key] = {"CODE": defaultData.code }
        }

      }
    }
  }

  return temp;

}

