import {modalAction} from "../../redux/store";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {multipleModalSelector} from "../../redux/modal-slice/modalSlice";


const MultipleModal = () => {
  const dispatch = useDispatch();
  const multipleModalData = useSelector(multipleModalSelector);

  return (
    <div className={"modal-wrap"} data-type={multipleModalData.dataType} style={{display: multipleModalData.isOpened ? "block" : "none"}}>
      <div className={"modal-container"}>
        <div className={"modal"}>
          <div className={"modal-body"} id={"SUB_MODAL"}/>
          <div className={"modal-footer"}>
            <button type={"button"} className={"btn notheme"} data-block data-size={"lg"} data-intent={"confirm"}
              onClick={() => {
                dispatch(modalAction.multipleModalClose());
                multipleModalData.closeAll && dispatch(modalAction.modalClose());
              }}>
              <span>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultipleModal;