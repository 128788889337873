import {useSelector} from "react-redux";
import {elementReady, getCurrentTime} from "../../../../../core/utils/tools";

const CaptureButton = () => {

  const configId = useSelector(state => state.qrcode.configId);
  const code = useSelector(state => state.optionWindow.currentVehicle.code);
  return (
    <button
      type={"button"} className={"control-button"} id={"viewCapture"}
      onClick={() => {
          elementReady("#application-canvas").then(() => {
              window && window.postMessage({
                  msg: 'ui:takeScreenshot:current', data: (code ?? 'oneofone') + '-' + (configId ?? 'capture')+ '-'+ getCurrentTime()
              }, '*');
          });
      }}
    >
      Capture
    </button>
  )
}
export default CaptureButton;
