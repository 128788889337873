
import Router from "./app/router/Router";


function App() {


  return (
    <Router/>
  );
}

export default App;
