import {batch, useDispatch, useSelector} from "react-redux";
import {optionAction} from "../../../../redux/store";
import useTargetCamera from "../../../../hooks/controller/useTargetCamera";
import {navDataSelector} from "../../../../redux/menuWindow-slice/menuWindowSlice";
import {useNavigate, useParams} from "react-router-dom";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";


const useOptionController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const navData = useSelector(navDataSelector);
  const langData = useSelector(langDataSelector);
  const navigationList = navData && navData.processedNavData ? navData.processedNavData : [];
  const connectMode = useSelector(state => state.theme.connectMode);

  const { headerCameraControl } = useTargetCamera();

  const slideToSubHandler = (clickDetailButton) => {
    let parentData;
    let selectChildNavData;
    for(let i=0; i<navigationList.length; i++) {
      if(navigationList[i].id === clickDetailButton.id) {
        selectChildNavData = navigationList[i];
      }
      if(navigationList[i].id === clickDetailButton.parentId) {
        parentData = navigationList[i];
      }
    }

    const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\+<>@\#$%&\\\=\(\'\"]/gi;
    const itemId = parentData && parentData.linkPath && parentData.linkPath.replaceAll(reg,"");


    headerCameraControl(selectChildNavData);
    if (connectMode === "web") {
      navigate(`/${langData.lang}/${params.id}/configurator/${parentData.linkPath}`);
    }
    batch(() => {
      dispatch(optionAction.setCurrentNavigation(itemId));
      dispatch(optionAction.setDataSub(true));
      dispatch(optionAction.setChildNavigationData(selectChildNavData));
    });
  }


  const slideToMainHandler = () => {
    batch(() => {
      dispatch(optionAction.setDataSub(false));
      dispatch(optionAction.setChildNavigationData(""));
    })

  }


  return {
    slideToSubHandler, slideToMainHandler
  }

}

export default useOptionController;