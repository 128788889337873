import {useEffect, useState} from "react";

const ChangeModelButtons = ({ imageRef, productsImagesIndexRef, modelChooseImages, showModelInfo, setShowModelInfo, VEHICLE_SELECTION_POINTS, runningAnimation, animate }) => {
  const LAST_IMAGE_INDEX = VEHICLE_SELECTION_POINTS.length - 1;
  const [startX, setStartX] = useState(0);

  const touchStartHandler = (e) => {
    if(runningAnimation) {
      return;
    }
    setStartX(e.touches[0].clientX - e.currentTarget.offsetLeft);
  }


  let prevX = 0;
  const touchMoveHandler = (e) => {
    if(runningAnimation) {
      return;
    }
    const currentX = e.touches[0].clientX - startX;
    const diff = currentX - prevX;
    prevX = currentX;

    const distance = Math.floor(diff) % 10;
    const nextIndex = (productsImagesIndexRef.current - Math.round(distance / 2));
    productsImagesIndexRef.current = Math.max(0, Math.min(nextIndex, VEHICLE_SELECTION_POINTS[LAST_IMAGE_INDEX]));
    imageRef.current.src = modelChooseImages[productsImagesIndexRef.current].dataURI;

    if (!VEHICLE_SELECTION_POINTS.includes(productsImagesIndexRef.current)) {
      setShowModelInfo(false);
    } else {
      setShowModelInfo(true);
    }
  }


  const touchEndHandler = (e) => {
    if(runningAnimation) {
      return;
    }
    const moveDistance = e.changedTouches[0].clientX - startX;
    if (Math.abs(moveDistance) > 300) {
      const direction = moveDistance > 0 ? "left" : "right";
      if(direction === "left" && productsImagesIndexRef.current === 0){
        return;
      }
      animate(direction);
    } else {

      let closestIndex = 0;
      let closestDistance = Math.abs(productsImagesIndexRef.current - VEHICLE_SELECTION_POINTS[0]);


      for (let i = 1; i < VEHICLE_SELECTION_POINTS.length; i++) {
        const distance = Math.abs(productsImagesIndexRef.current - VEHICLE_SELECTION_POINTS[i]);

        if (distance - 20 < closestDistance) {
          closestIndex = i;
          closestDistance = distance;
        }
      }

      let direction;
      if (VEHICLE_SELECTION_POINTS[closestIndex] > productsImagesIndexRef.current) {
        direction = "right";
      } else if (VEHICLE_SELECTION_POINTS[closestIndex] < productsImagesIndexRef.current) {
        direction = "left";
      } else {
        return;
      }
      animate(direction);
    }
  }

  useEffect(() => {
    const mouseWheelEvent = (e) => {
      if(e.deltaY < 0 && !runningAnimation) {
        animate("left");
      } else if (e.deltaY > 0 && !runningAnimation) {
        animate("right");
      }
    }
    window.addEventListener("wheel", mouseWheelEvent);
    return () => {
      window.removeEventListener("wheel", mouseWheelEvent);
    }
  }, [runningAnimation]);

  return (
    <div className={"change-model"} onTouchStart={touchStartHandler} onTouchMove={touchMoveHandler} onTouchEnd={touchEndHandler}>
      <div id={"left"} className={"sidearea left"} onClick={(e) => {
        if (!showModelInfo || productsImagesIndexRef.current === 0) {
          return;
        }
        animate(e);
      }}/>
      <div id={"right"} className={"sidearea right"} onClick={(e) => {
        if (!showModelInfo || productsImagesIndexRef.current >= VEHICLE_SELECTION_POINTS[LAST_IMAGE_INDEX]) {
          return;
        }
        animate(e);
      }}/>
    </div>
  )
}
export default ChangeModelButtons;