/**
 * configuration load component
 * */

import React from 'react';
import {modalAction} from "../../../redux/store";
import {useDispatch} from "react-redux";
import LoadConfirm from "./LoadConfirm";
import {langSwitch} from "../../../../core/utils/lang";
import {useParams} from "react-router-dom";
import useLoadMyOneOfOne from "../../../hooks/core/useLoadMyOneOfOne";

const LoadComponent = ({componentMount, vehicleSelectHandler}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const {initialValue, codeInputRefs, getEntireCodeFromInput, keyDownHandler, onInputHandler} = useLoadMyOneOfOne();

  return (
    <>
      <div className={"modal-header"}>
        <h2>{langSwitch("loadConfig", "title", params.lang)}</h2>
        <button type="button" className="modal-close" onClick={() => dispatch(modalAction.modalClose())}>close
        </button>
      </div>

      <div className={"modal-body"}>
        <p>
          {langSwitch("loadConfig", "subTitle", params.lang).split("\n").map((title, index) => {
            return (
              <React.Fragment key={index}><span>{title}</span><br/></React.Fragment>
            )
          })}
        </p>

        <div className={"save-load"}>
          <div className="form-group" id="EnterCode">
            <label>{langSwitch('loadConfig', 'codeLabel', params.lang)}</label>
            <div className="form">
              {
                initialValue.map((item, index) => {
                  return (
                    <input
                      key={index}
                      ref={codeInputRefs.current[index]}
                      type={"text"}
                      data-box
                      maxLength={1}
                      onKeyDown={(e) => keyDownHandler(e, index)}
                      onInput={(e) => onInputHandler(e, index)}
                    />
                  )
                })
              }

            </div>
            <button
              type="button" className="btn notheme"
              data-block="" data-intent="confirm" data-size="lg"
              onClick={() => {

                const myCode = getEntireCodeFromInput();
                componentMount("ModalConfirm", <LoadConfirm bespokeCode={myCode}
                                                            componentMount={componentMount}
                                                            vehicleSelectHandler={vehicleSelectHandler}
                />, "confirm")

              }}
            >
              <span>{langSwitch('loadConfig', 'loadButton', params.lang)}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoadComponent;
