import axios from 'axios'

const confApiPrefix = process.env.REACT_APP_backend_url+"/public/bespoke";
export const confSave = async (conf,data)=>{
    return await axios.post(confApiPrefix+'/save',data,conf)
}
export const confLoad = async (bespokeCode)=>{
    return await axios.get(confApiPrefix+'/load?bespokeCode='+bespokeCode);
}
export const confQRCode = async (bespokeCode)=>{
    return await axios.get(confApiPrefix+'/qr?bespokeCode='+bespokeCode);
}
