import OptionController from "./optionController/template/OptionController";
import EnvControllers from "./envController/organisms/EnvControllers";
import React from "react";
import {useOutletContext} from "react-router-dom";


const Controllers = () => {

  return (
    <>
      <OptionController/>
      <EnvControllers/>
    </>
  )
}

export default Controllers;