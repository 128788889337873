import React from "react";
import {optionAction} from "../../../../redux/store";
import {useDispatch} from "react-redux";


const NoneChip = ({codeKeyData, code}) => {
  const dispatch = useDispatch();

  return (
    <li className={`${codeKeyData ? "" : "active"}`}>
      <div className={"no-value"}>
        <button type={"button"} onClick={() => dispatch(optionAction.setSendDataRemove(code))}>
          <span>option name</span>
        </button>
      </div>
    </li>)
}

export default NoneChip;