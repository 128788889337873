import {useDispatch} from "react-redux";
import * as reduxModules from "../../redux/store";


const useMediaWallDispatch = () => {
  const dispatch = useDispatch();

  const dispatchAction = (action, func, payload="") => {
    dispatch(reduxModules[action][func](payload));
  }

  return { dispatchAction }
}

export default useMediaWallDispatch;