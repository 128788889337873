import React, {useEffect, useRef, useState} from 'react';
import {ConvertRGBtoHex} from "../../../../../core/utils/colorConverts";


const useColorPickerImageTab = (target ,size,changeColor,imagePickerPointer,imageData, selectImage, imagePickerBgColorRef)=>{
    const magnifier = useRef();
    const magnifierSize = 50;

    const [isShow,setIsShow] = useState(false)
    const [colorData,setColorData] = useState([]);
    const init = {
        width: `${magnifierSize}px`,
        height: `${magnifierSize}px`,
        backgroundRepeat:'no-repeat',
        position:'absolute',
        pointerEvents:'none',
        border:'1px solid',
        borderRadius: "50%",
    }


    useEffect(() => {
        const preventBubbling = (e) => {
            e.preventDefault()
        };
        const pickers = document.getElementById("picker");
        pickers.addEventListener("touchmove", preventBubbling, {passive: false});

        if (pickers)
            return () => pickers.removeEventListener("touchmove", preventBubbling);
    }, [])




    const [magniState,setState] = useState(init);
    useEffect(() => {
        if(selectImage) {
            setIsShow(true);
        } else if(!selectImage) {
            setIsShow(false);
        }
    },[selectImage])



    const colorCalculation = (mouseX, mouseY, colorBox) => {
        let colors = []
        for(let y = mouseY - size; y <= mouseY + size; y++){
            let arr = []

            for(let x = mouseX - size; x <= mouseX+size; x++){
                if(mouseX < 0 || mouseY < 0 || mouseX >= colorBox.clientWidth || mouseY >= colorBox.clientHeight){
                    setIsShow(false);
                } else {
                    setIsShow(true);
                }


                if(x >= colorBox.clientWidth || y <0 || x < 0 || y>=colorBox.clientHeight){
                    const rgb = {r: 0, g: 0, b: 0};
                    const hex = ConvertRGBtoHex(rgb.r, rgb.g, rgb.b);
                    arr.push({hex: hex, rgb: rgb});
                }else{
                    let pos = parseInt((x + y * colorBox.clientWidth )*4);
                    const rgb = { r: imageData[pos], g: imageData[pos+1], b: imageData[pos+2]}
                    // const hex = ConvertRGBtoHex(imageData[pos],imageData[pos+1],imageData[pos+2])
                    const hex = ConvertRGBtoHex(rgb.r, rgb.g, rgb.b);
                    arr.push({hex: hex, rgb: rgb});
                }
            }
            colors.push(arr);
        }
        return colors;
    }

    const colorSelect = (e, colorBox) => {
        let rect = e.target.getBoundingClientRect();
        let mouseX = typeof e.offsetX === 'number' ? e.offsetX : parseInt(e.changedTouches[0].pageX- rect.left);
        let mouseY = typeof e.offsetY === 'number' ? e.offsetY : parseInt(e.changedTouches[0].pageY- rect.top);
        let pos = parseInt((mouseX + mouseY * colorBox.clientWidth )*4);


        let pointerPixelX = mouseX + colorBox.offsetLeft;
        let pointerPixelY = mouseY + colorBox.offsetTop;

        let pointerPerX = (pointerPixelX / colorBox.width * 100) >= 100 ? 100 : (pointerPixelX / colorBox.width * 100) <= 0 ? 0 : (pointerPixelX / colorBox.width * 100);
        let pointerPerY = (pointerPixelY / colorBox.height * 100) >= 100 ? 100 : (pointerPixelY / colorBox.height * 100) <= 0 ? 0 : (pointerPixelY / colorBox.height * 100);

        if(!imageData[pos] && imageData[pos] !== 0){
            return;
        }

        imagePickerPointer.current.style.top = pointerPerY+"%";
        imagePickerPointer.current.style.left = pointerPerX+"%";
        imagePickerBgColorRef.current.style.backgroundColor = `rgb(${imageData[pos]},${imageData[pos+1]},${imageData[pos+2]})`;
        changeColor({r:imageData[pos],g:imageData[pos+1],b:imageData[pos+2]});
    }


    useEffect(()=>{
        const colorBox = target.current;
        if(!colorBox){
            return;
        }


        if(selectImage) {
            const overEvent = (e)=>{
                setIsShow(true);
            }

            const moveEvent = (e)=>{
                setColorData([])
                let mouseX = typeof e.offsetX === 'number' && e.offsetX;
                let mouseY = typeof e.offsetY === 'number' && e.offsetY;

                const colors = colorCalculation(mouseX, mouseY, colorBox);

                setColorData(colors)
                const quarterSize = magnifierSize/4;

                setState({
                    ...magniState,
                    top: mouseY + colorBox.offsetTop - magnifierSize - quarterSize,
                    left: mouseX + colorBox.offsetLeft + quarterSize
                })
            }

            const outEvent = (e)=>{
                setIsShow(false)
            }

            const clickEvent = (e)=>{
                colorSelect(e, colorBox)
            }


            const touchMoveHandler = (e) => {
                setColorData([]);
                setIsShow(true);
                let rect = e.target.getBoundingClientRect();
                let touchX = parseInt(e.touches[0].pageX - rect.left);
                let touchY = parseInt(e.touches[0].pageY - rect.top);

                const colors = colorCalculation(touchX, touchY, colorBox);

                setColorData(colors)
                const quarterSize = magnifierSize/4;
                setState({
                    ...magniState,
                    top: touchY + colorBox.offsetTop - magnifierSize - quarterSize,
                    left: touchX + colorBox.offsetLeft + quarterSize
                })
            }

            const touchEndHandler = (e) => {
                setIsShow(false);
                colorSelect(e, colorBox)
            }

            colorBox.addEventListener('mouseover',overEvent);
            colorBox.addEventListener('mousemove',moveEvent)
            colorBox.addEventListener('mouseout',outEvent)
            colorBox.addEventListener('click',clickEvent)

            colorBox.addEventListener("touchmove", touchMoveHandler);
            colorBox.addEventListener("touchend", touchEndHandler);

            return ()=> {
                colorBox.removeEventListener('mouseover',overEvent)
                colorBox.removeEventListener('mouseout',outEvent)
                colorBox.removeEventListener('mousemove',moveEvent)
                colorBox.removeEventListener('click',clickEvent)

                colorBox.removeEventListener("touchmove", touchMoveHandler);
                colorBox.removeEventListener("touchend", touchEndHandler);
            }
        }

    },[imageData, selectImage])

    return {magnifier,isShow,colorData,magniState}
}

export default useColorPickerImageTab;
