/**
 * color navigation info component
 *
 * */
import React from "react";
import PreviewImg from "../atoms/PreviewImg";
import PreviewTitle from "../atoms/PreviewTitle";
import {useDispatch, useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";
import {optionAction} from "../../../../redux/store";
import usePreviewColor from "./usePreviewColor";

const PreviewColor = ({code, colorItemObject, shootingPointChangeHandler}) => {
  const dispatch = useDispatch();
  const sendData = useSelector(configDataSelector);
  const langData = useSelector(langDataSelector);
  const {currentColor} = usePreviewColor(code, colorItemObject);



  return (
    <div className={"option-value"} onClick={shootingPointChangeHandler}>
      <PreviewImg currentColor={currentColor}/>
      <PreviewTitle title={colorItemObject[langData.langName]} currentColor={currentColor} type={"COLOR"}/>
      { sendData[code] && (code.includes("SPECIAL") || code.includes("SECONDARY") || code.includes("PINSTRIPE")) &&
        <button type="button" id={"delete_button"} className="option-value-init" onClick={() => dispatch(optionAction.setSendDataRemove(code))}/>
      }
    </div>
  )
}

export default PreviewColor;
