import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toggleAction} from "../../redux/store";
import shootingPoint from "../../../core/json/shootingPoint.json";
import {useCallback} from "react";
import {cameraSelector} from "../../redux/toggle-slice/toggleSlice";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";


const useTargetCamera = () => {
  const dispatch = useDispatch();
  const camera = useSelector(cameraSelector);

  const {hotspot, connectMode} = useSelector(state => ({
    hotspot: state.toggle.hotspot,
    connectMode: state.theme.connectMode
  }),shallowEqual);







  const findShootingPoint = (target) => {
    return shootingPoint[target]
  }


  const shootingPointChange = async (stepData, target) => {
    const peer = await callPeerRemoteModule(connectMode);
    let targetCameraType = stepData && stepData.cameraType;
    let targetCameraCode = stepData && stepData.cameraCode;

    if (!stepData.cameraType && target) {
      if (findShootingPoint(target)) {
        targetCameraType = findShootingPoint(target).cameraType;
        targetCameraCode = findShootingPoint(target).cameraCode;
      }
    }

    if (!targetCameraType && !targetCameraCode) {
      return;
    }

    if (camera.currentCameraType === targetCameraType && camera.currentCameraCode === targetCameraCode) {
      return;
    }

    if(peer) {
      peer.conn.sendData("camera:change", [hotspot, targetCameraType, targetCameraCode]);
      // dispatch(toggleAction.resetMovingPartsState());
    } else if(!peer) {
      if(targetCameraCode === "TRUNK") {
        window && window.postMessage({
          msg: "camera:change",
          data: [hotspot, targetCameraType, targetCameraCode, true]
        }, "*");
      } else {
        window && window.postMessage({
          msg: "camera:change",
          data: [hotspot, targetCameraType, targetCameraCode]
        }, "*");
      }
    }

    dispatch(toggleAction.setToggle({
      key: "camera",
      value: {currentCameraType: targetCameraType, currentCameraCode: targetCameraCode}
    }));


  }




  const headerCameraControl = useCallback(async (item) => {
    const peer = await callPeerRemoteModule(connectMode);
    if(!item) {
      return;
    }

    if(!item.cameraType) {
      dispatch(toggleAction.setToggle({key: "hotspot", value: false}));
      return;
    }

    if (camera.currentCameraType === item.cameraType && camera.currentCameraCode === item.cameraCode) {
      return;
    }

    let targetCameraType = item.cameraType;
    let targetCameraCode = "";

    if (item.cameraType === "EXT") {
      targetCameraCode = "";

    } else if (item.cameraType === "INT") {
      dispatch(toggleAction.setToggle({key: "hotspot", value: false}));
      if (item.cameraCode && item.cameraCode.trim().length > 0) {
        targetCameraCode = item.cameraCode;
      }
    }


    if(peer) {
      peer.conn.sendData("camera:change", [hotspot, targetCameraType, targetCameraCode]);
      // dispatch(toggleAction.resetMovingPartsState());
    } else if(!peer) {
      window && window.postMessage({
        msg: "camera:change",
        data: [hotspot, targetCameraType, targetCameraCode]
      }, "*");
    }

    dispatch(toggleAction.setToggle({
      key: "camera",
      value: {currentCameraType: targetCameraType, currentCameraCode: targetCameraCode}
    }));
  },[camera, connectMode, hotspot]);


  return {shootingPointChange, headerCameraControl}
}

export default useTargetCamera;