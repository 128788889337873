

const callPeerMediaWallModule = async(connectMode) => {
  if(connectMode !== "mediaWall") {
    return;
  }

  const peer = (await import("../peerMediaWall")).default;
  return peer;
}


export default callPeerMediaWallModule;