import {langSwitch} from "../../../../core/utils/lang";
import QRCodeComponent from "./QRCodeComponent";
import React from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";


const StandByBody = ({isPeerOpen, peerId, qrRef, qrSize}) => {
  const langData = useSelector(langDataSelector);


  return (
    <div className={"stb-body"}>
      <div className={"stb-howto"}>
        <div className={"title"}>{ langSwitch("connectPage", "guideTitle")}</div>
        <ol>
          { langSwitch("connectPage", "guideText", langData.lang).split("/").map(text => {
            return <li key={text}>{ text }</li>
          })}
        </ol>
      </div>
      <div className={"stb-qrscan"}>
        <div className={"title"}>{ langData.lang === "kr" ? "QR코드" : "QR code"}</div>
        <p>{ langData.lang === "kr" ? "QR코드를 스캔해주세요!" : "Please scan the QR code!"}</p>
        <div className={"created-qrcode"} ref={qrRef} >
          {isPeerOpen ? <QRCodeComponent peerId={peerId} qrSize={qrSize.current} /> : null}
        </div>
      </div>
    </div>


  )
}

export default StandByBody;