import {Swiper, SwiperSlide} from "swiper/react";
import PreSelectionCard from "../molecules/PreSelectionCard";
import React, {useCallback, useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {cardFlipToggleSelector} from "../../../redux/toggle-slice/toggleSlice";
import {toggleAction} from "../../../redux/store";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import useSetDefaultOption from "../../../hooks/core/useSetDefaultOption";


const PreselectionSwiper = ({preselectionList}) => {
  const dispatch = useDispatch();
  const cardFlipToggle = useSelector(cardFlipToggleSelector);
  const {selectDefaultOption} = useSetDefaultOption();

  const {checkClickHeader, connectMode} = useSelector(state => ({
    checkClickHeader: state.optionWindow.checkClickHeader,
    connectMode: state.theme.connectMode
  }),shallowEqual);



  const [swiper, setSwiper] = useState(null);

  const slideChangeHandler = async(e) => {
    const peer = await callPeerRemoteModule(connectMode);
    peer && peer.conn.sendData("slideEvent", e.snapIndex);
  };



  const slideParams = {
    spaceBetween: 20,
    slidesPerView: "auto",
    pagination: {clickable: true},
    breakpoints: { 768: { spaceBetween: 50 } },
    onSnapIndexChange: slideChangeHandler,
    onSwiper: setSwiper
  }


  useEffect(() => {
    if(swiper){
      swiper.slideTo(0);
    }
  },[checkClickHeader]);



  const cardFlipHandler = useCallback(async (e) => {
    const peer = await callPeerRemoteModule(connectMode);
    const copyFlippedList = [...cardFlipToggle];

    if(copyFlippedList.includes(e)){
      let flipListArray = copyFlippedList.filter(item => item !== e);
      dispatch(toggleAction.setCardFlipToggle(flipListArray));
      peer && peer.conn.sendData("dispatch", { action: "toggleAction", func: "setCardFlipToggle", payload: flipListArray });
    } else {
      copyFlippedList.push(e);
      dispatch(toggleAction.setCardFlipToggle(copyFlippedList));
      peer && peer.conn.sendData("dispatch", { action: "toggleAction", func: "setCardFlipToggle", payload: copyFlippedList });
    }
  },[cardFlipToggle]);



  return (
    <div className="ps-list">
      <Swiper {...slideParams} ref={setSwiper} observer={true} observeParents={true} className={"swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-backface-hidden"}>
        { preselectionList.filter((data) => !data.isDefault).map((data, idx) => {
          return (
            <SwiperSlide key={idx}>
              <PreSelectionCard {...data} cardFlipHandler={cardFlipHandler} cardFlipToggle={cardFlipToggle} selectDefaultOption={selectDefaultOption}/>
            </SwiperSlide>)
          })
        }
      </Swiper>
    </div>

  )
}

export default PreselectionSwiper;