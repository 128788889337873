
const withCategory = (InnerComponent) => {
  return (props) => {
    const colorArray = [...props.colorArray] || [];



    return (
      <div className={"option"}>
        <h4>{props.categoryName}</h4>
        <ul className={"option-list"}>
          {
            colorArray.sort((a,b) => a.optionOrder - b.optionOrder).map((colorItem, index) => {
              if (colorItem.color.isActive) {
                return <InnerComponent key={index} {...{...props, colorItem}}/>
              }
          })
          }

        </ul>
      </div>
    )


  }
}

export default withCategory;