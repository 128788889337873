import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {navDataSelector} from "../../../../redux/menuWindow-slice/menuWindowSlice";



const useTabs = (setColorByCategory, setColorItemObject, stepObject, code, codeKeyData, setCurrentTab) => {
  const [extraTabList, setExtraTabList] = useState([]);
  const navData = useSelector(navDataSelector);
  const allColorItems = navData && navData.allColorItems ? navData.allColorItems : [];



  const trackingTarget = (code) => {

    const map = {
      'SEAT_HEADREST_SIGNATURE' : 'SEAT_COLOR',
      // 'WHEEL' : 'EXTERIOR_COLOR_PRIMARY'
    }

    if(code !== undefined && code !== null && code.trim() !== ''){
      const mappingColor = map[code];
      if(mappingColor) {
        return mappingColor;
      } else {
        return code;
      }

    }
  }

  const colorCategoryProcessing = (code) => {
    const mappingColorCode = trackingTarget(code);
    const mappingColorData = allColorItems.find(item => item.colorTarget === mappingColorCode);

    const initData = {"default": []};
    mappingColorData && mappingColorData.itemOptions.map(color => {
      if(color.colorCategoryId) {
        if(initData.hasOwnProperty(color.colorCategoryId)) {
          initData[color.colorCategoryId].push(color);
        } else {
          initData[color.colorCategoryId] = [color];
        }
      } else {
        if(initData.hasOwnProperty("default")) {
          initData["default"].push(color);
        }
      }
    });

    return { categoryData: initData, colorObject: mappingColorData }
  }


  useEffect(() => {
    const mappingColorData = colorCategoryProcessing(code);
    setColorByCategory(mappingColorData.categoryData);
    setColorItemObject(mappingColorData.colorObject);

    let extraTabs;

    const tabListOfStep = mappingColorData.colorObject && mappingColorData.colorObject.tabs ? mappingColorData.colorObject.tabs : "";

    if(stepObject.stepType === "COLOR" && tabListOfStep) {
      extraTabs = JSON.parse(tabListOfStep.replaceAll("'", '"'));
    } else if(stepObject.stepTabs) {
      extraTabs = JSON.parse(stepObject.stepTabs.replaceAll("'", '"'));
    } else {
      extraTabs = [];
    }
    setExtraTabList([...extraTabs]);
  },[code]);


  useEffect(() => {
    if(!codeKeyData) {
      setCurrentTab(stepObject.stepType === "COLOR" ? "COLOR" : "OPTION")
    }
  },[codeKeyData]);



  return {extraTabList}
}

export default useTabs;