/**
 * lab, rgb color
 * */
import React, {useState} from 'react';
import {lab_to_rgb, rgb_to_lab} from "../../../../../core/utils/colorConverts";
import ColorTypeSelectButtons from "./ColorTypeSelectButtons";
import ColorPickerRange from "./ColorPickerRange";
import useRgbInputs from "./useRgbInputs";

const RgbInputs = ({color, setColor}) => {
  const [selectedColorType, setSelectedColorType] = useState("rgb");
  const { inputColorChange } = useRgbInputs(color, setColor);


  return (
    <div className={"color-info"}>
      <ColorTypeSelectButtons selectedColorType={selectedColorType} setSelectedColorType={setSelectedColorType}/>
      {
        Object.keys(color).map((colorMode, index) => {
          return (
           <ColorPickerRange
             key={colorMode}
             color={color}
             colorMode={colorMode}
             selectedColorType={selectedColorType}
             inputColorChange={inputColorChange}
           />
          )
        })
      }
    </div>
  )
}
export default RgbInputs;
