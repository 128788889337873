import {transferLanguage, unescapeHtmlTag} from "../../../../../core/utils/tools";
import React from "react";
import {useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";


const PreviewTitle = ({selectedItem, title, currentColor, type}) => {
  const sendData = useSelector(configDataSelector);
  const langData = useSelector(langDataSelector);

  return (
    <div className="option-value-name">
      <h3>{unescapeHtmlTag(title)}</h3>
      { type === "COLOR" && <p>{sendData !== undefined && (currentColor ? unescapeHtmlTag(currentColor.TITLE) : transferLanguage(langData.lang, "noSelect"))}</p> }
      { type === "PART" && <p>{selectedItem ? unescapeHtmlTag(selectedItem[langData.langName]) : transferLanguage(langData.lang,"noSelect")}</p>}
    </div>
  )
}

export default PreviewTitle;