/**
 * set qrcode
 * */
import React from "react";
import {QRCodeSVG} from "qrcode.react";
import {transferLanguage} from "../../../../core/utils/tools";
import {useParams} from "react-router-dom";
import {qrcodeAction} from "../../../redux/store";
import {useDispatch} from "react-redux";

const SaveComponentQRCode = ({configId, sendData}) => {

  const params = useParams();
  const dispatch = useDispatch();
  dispatch(qrcodeAction.setQRcode(configId));

  // useEffect(() => {
  //   (async () => {
  //     try {
  //
  //       const payloadData = {
  //         productId: "1",
  //         bespokeCode: configId,
  //         email: null,
  //         configuration: JSON.stringify(sendData)
  //       }
  //
  //       await confSave({                             // save configuration
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Accept-Language": params.lang === "kr" ? "ko" : "en"
  //         }
  //       }, payloadData)
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   })();
  // }, []);

  return (
    <div className={"form-group"} id={"SavedQR"}>
      <label>{transferLanguage(params.lang, "qrCode")}</label>
      <div className={"qrcode"} style={{padding: "10% 0", textAlign: "center"}}>
        {/*<img src={qrCodeImage} />*/}
        <QRCodeSVG
          value={`${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_QRCODE_PATH}${configId}`}
          size={200}
          id={"qrCode_id"}
        />
      </div>
    </div>
  )
}

export default SaveComponentQRCode;

