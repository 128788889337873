import React from "react";


const BackgroundThumbnail = ({bg, background, backgroundChangeHandler}) => {



  return (
    <button type="button" className={`${background === bg.envCode && 'active'}`}
            onClick={() => backgroundChangeHandler(bg.envCode)}>
      <img src={bg.img} title="Dark Studio" alt=""/>
    </button>
  )
}

export default BackgroundThumbnail;