import Tabs from "../components/controller/optionController/atoms/Tabs";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";


const withTabs = (InnerComponent) => {
  return (props) => {
    const currentStep = useSelector(state => state.optionWindow.currentStep);

    const [currentTab, setCurrentTab] = useState(props.stepObject.stepType === "COLOR" ? "COLOR" : "OPTION");
    const [colorByCategory, setColorByCategory] = useState(null);
    const [colorItemObject, setColorItemObject] = useState(null);
    const [initDetailIndex, setInitDetailIndex] = useState(0);

    useEffect(() => {
      if(props.selectedIndex){
        setInitDetailIndex(props.selectedIndex);
      }
    },[props.selectedIndex]);



    return (
      <div className={"option-expand"}>
        {currentStep === props.code && (
        <>
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab}
                stepObject={props.stepObject}
                code={props.stepObject && props.stepObject.stepType === "COLOR" ? props.colorTarget : props.code}
                setColorByCategory={setColorByCategory}
                setColorItemObject={setColorItemObject}
                codeKeyData={props.codeKeyData}
          />
          <InnerComponent {...{
            ...props,
            colorByCategory, colorItemObject, currentTab, initDetailIndex
          }}/>
        </>)
        }
      </div>
    )
  }

}

export default withTabs;