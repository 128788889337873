import React from "react";
import usePartChip from "./usePartChip";


const PartChip = ({code, itemObject, codeKeyData}) => {
  const { partChipSelectHandler, switchData } = usePartChip(itemObject);
  

  return (
    <li className={`${(codeKeyData && switchData) && switchData.code === codeKeyData.CODE ? "active" : ""}`}>
      <div>
        <button type={"button"} onClick={() => partChipSelectHandler(code, itemObject, switchData)}>
          <img src={process.env.REACT_APP_image_path+"/item/"+itemObject.thumbnailImg}/>
          <span>{itemObject.name}</span>
        </button>
      </div>
    </li>
  )
}

export default PartChip;