import {shallowEqual, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {persistor} from "../../index";
import RemoteQrCode from "../components/independent/RemoteQrCode";
import RemoteStandBy from "../components/independent/RemoteStandBy";
import ConnectModal from "../components/modal/remoteConnect/ConnectModal";
import useMsgReceive from "../hooks/core/useMsgReceive";
import {langSwitch} from "../../core/utils/lang";
import {langDataSelector} from "../redux/theme-slice/themeSlice";
import callPeerRemoteModule from "../../core/utils/callPeerRemoteModule";
import ModelSelect from "../components/intro/ModelSelect";


const withPeerConnect = (InnerComponent) => {
  return () => {
    const {menuOpened} = useSelector(state => ({
      menuOpened: state.menuOpen.menuOpened,
    }),shallowEqual);


    const isGnb = useSelector(state => state.optionWindow.isGnb);
    const langData = useSelector(langDataSelector);
    const initConnectMode = "remote";

    useMsgReceive(initConnectMode);


    //remote peer connect
    const [endPeerConnection,setEndPeerConnection] = useState(false);
    const [isModal,setIsModal] = useState(false);
    const [modalInfo,setModalInfo] = useState({title: "", text: "", data: false});
    const [productSelected, setProductSelected] = useState(false);

    const successQrCodeHandler = (e, html5QrCode)=>{
      setModalInfo({
        title: langSwitch("connectPage", "scanSuccessTitle", langData.lang),
        text : langSwitch("connectPage", "scanSuccessText", langData.lang),
        data : false
      });
      setIsModal(true);

      (async () => {
        const peer = await callPeerRemoteModule("remote");
        peer && peer.conn.connection(e,setModalInfo,setEndPeerConnection);
      })();

      html5QrCode && html5QrCode.stop();
    }

    useEffect(() => {
      persistor.purge();
    },[]);



    const [isPeer, setIsPeer] = useState(false);
    const [qrMode, setQrMode] = useState(false);



    return (
      <div className={"remote-tablet"} data-theme={"dark"} {...(menuOpened ? {'menu-opened': "true"} : '')} {...{'option-opened': `${!isGnb}`}}>
        <div className={"wrap"}>
          <div>리모트</div>
          { !isPeer
              ? qrMode
                ? <RemoteQrCode config={{fps: 10, qrbox: 350}} {...{successQrCodeHandler, setQrMode, setModalInfo}}/>
                : <RemoteStandBy setQrMode={setQrMode}/>
              : productSelected
              ? <InnerComponent/>
              : <ModelSelect setProductSelected={setProductSelected}/>
          }

          {
            isModal && <ConnectModal {...{modalInfo, setIsPeer, setIsModal, endPeerConnection}}/>
          }


          
          
          {/*peer code 입력란 활성화*/}
          {/*{*/}
          {/*  process.env.REACT_APP_mode === 'dev' && <div style={{display: isPeer ? "none" : 'flex'}}>*/}
          {/*    Peer Id : <input type={'text'} onChange={(e) => {*/}
          {/*    successQrCodeHandler(e.target.value)*/}
          {/*  }}/>*/}
          {/*    <button style={{color: '#fff'}} onClick={() => {*/}
          {/*    }}>Connect*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*}*/}
        </div>
      </div>
    )
  }
}
export default withPeerConnect;