import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../redux/theme-slice/themeSlice";

const ModelSelectPc = ({productList, activeVehicle, vehicleActiveHandler, vehicleSelectHandler, initConnectMode, setProductSelected, navAndConfigDataReady}) => {

  const langData = useSelector(langDataSelector)


  return (
    <>
      <aside className={"model-select"}>
        <h2>Select model</h2>
        {
          productList.map(item => (
            <div key={item.code} className={item.code === activeVehicle ? "model-button active" : "model-button"}>
              <div id={item.code} className={"model-name"} onClick={vehicleActiveHandler}>
                {item.displayTitle}
                <small>{item.displaySubtitle}</small>
              </div>
              {
                <a
                  path={null}
                  className={"btn"}
                  data-size={"lg"}
                  onClick={(e) => {
                  // e.preventDefault();
                    if(initConnectMode === "web") {
                      window.location.href = `${process.env.REACT_APP_DOMAIN}/${langData.lang}/${activeVehicle.toLowerCase()}`
                    } else if(initConnectMode === "remote"){
                      setProductSelected(true);
                      vehicleSelectHandler(activeVehicle, "", initConnectMode, "");
                    }



                }}>
                  {
                    !navAndConfigDataReady ? (
                      <div className={"loadingView"} style={{display: "block"}}>
                        <div className={"spinner"}/>
                      </div>
                    ) : <span>Start Configuration</span>
                  }

                </a>
              }

            </div>
          ))
        }
      </aside>
      <div className={"model-bg"}>
        {
          productList.map(item => (
            <div key={item.code} className={item.code === activeVehicle ? "model active" : "model"}>
              <img src={`${process.env.REACT_APP_DOMAIN}/resource/images/bg-${item.code.toLowerCase()}.jpg`}/>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default ModelSelectPc;