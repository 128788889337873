import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {optionAction} from "../../../../redux/store";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";

let realIndex = 0;
let swiper = null;
const usePartDetailTab = (partArray, selectedIndex, personalItemObject)=>{
    const dispatch = useDispatch()
    const swiperRef = useRef();

    const sendData = useSelector(configDataSelector);





    const setSwiper = (e)=>{
        swiper = e;
    }

    const swipeParams = {
        spaceBetween: 50,
        slidesPerView: "auto",
        pagination: { clickable: true },
        initialSlide : personalItemObject ? 0 : selectedIndex,
        observer: true, observeParents: true, navigation: true, watchOverflow: true,
        onInit: setSwiper
    }

    const changeSliderItem = (idx,code)=>{
        const copySendData = {...sendData[code]};


        realIndex = idx;
        if(partArray[idx].partSwitch){
            const item =partArray[idx].partSwitch

            dispatch(optionAction.setSendData({
                key:item.partCategory,
                value:{
                    ...copySendData,
                    CODE:item.code
                }
            }))

        }else if(partArray[idx].materialSwitch){
            const item =partArray[idx].materialSwitch

            dispatch(optionAction.setSendData({
                key:item.type,
                value:{
                    ...copySendData,
                    CODE:item.code
                }
            }))
        }
    }

    useEffect(()=>{
        realIndex = selectedIndex;
        const mouseOutEvent = (e)=>{
            if(swiper){
                swiper.touchEventsData.isMoved = false;
                swiper.touchEventsData.isTouched = false;
                swiper.touchEventsData.startMoving = false;
                swiper.slideTo(realIndex,300,()=>{console.log("callback")})
            }
        }

        if(swiperRef.current) {

            swiperRef.current.addEventListener('mouseout', mouseOutEvent);
            // swiperRef.current.addEventListener('mouseout', mouseOutEvent);
        }

        return ()=>{
            if(swiperRef.current){
                swiperRef.current.removeEventListener('mouseout',mouseOutEvent);
            }
        }
    },[])

    return {changeSliderItem,swiperRef,setSwiper, swipeParams}
}

export default usePartDetailTab;
