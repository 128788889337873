/**
 * qrcode reader
 * html5Qrcode 사용
 * */

import React, {useEffect} from 'react'
import {Html5Qrcode} from "html5-qrcode";

const RemoteQrCode = (props)=>{
  const config = props.config;
  useEffect(()=>{
    const html5QrCode = new Html5Qrcode("reader");
    Html5Qrcode.getCameras().then(cameras => {
      // assuming at least one camera exists
      // camera list 0 => 전면, 1 => 후면
      // if(cameras.length > 1 ){
      //     html5QrCode.start(cameras[1].id, config, props.qrCodeSuccessCallback).catch(e=>alert(e));   // 두개 이상이면 후면 카메라 사용
      // }else{
      //     html5QrCode.start(cameras[0].id, config, props.qrCodeSuccessCallback).catch(e=>alert(e));   // 하나만 있으면 전면 카메라 사용 ( ex - 노트북 )
      // }

      if(cameras && cameras.length) {
        // let cameraId = cameras[1].id;

        let cameraId = { facingMode: "environment" };
        html5QrCode.start(
          cameraId,
          config,
          (event) => props.successQrCodeHandler(event,html5QrCode),
          (errorMessage) => {
            console.log("qrCodeErrorCallback",errorMessage)
          }
        ).catch((err) => {
          console.log("qrError",err);
        })
      }
    }).catch(e=>{
      alert(e)
    });

  },[])


  return (
    <div className={"scan-camera"}>
      <div className={"scan-header"} style={{position:"absolute",top:0,zIndex:15}}>
        <a className={"back"} onClick={()=>props.setQrMode(false)}>back</a>
        <h1>Media Wall Linkage</h1>
      </div>
      <div id={"reader"} style={{width:'100%',height:'100%',zIndex:1}}>
      </div>
    </div>

  )
}

export default RemoteQrCode;
