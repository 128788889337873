import React from "react"
import {unescapeHtmlTag} from "../../../../core/utils/tools";
import PartItem from "../atoms/PartItem";
import ColorItem from "../atoms/ColorItem";
import PersonalItem from "../atoms/PersonalItem";


const SummaryList = ({title, item}) => {
  if (!Array.isArray(item)) {
    let code = null;

    if (item.partSwitch) {
      code = item.customCode ? item.customCode : item.partSwitch.code;
    } else if (item.materialSwitch) {
      code = item.materialSwitch.code;
    }

    return <PartItem item={item} title={title} code={code}/>

  } else {
    return (
      <>
        {item.length > 0 && (
          <li>
            <div className="spec-set">
              {unescapeHtmlTag(title)}
            </div>
            {item.map((itm, idx) => {
              if (itm.partPersonalization) {
                return (
                  <div key={idx} className={"spec-sub"}>
                    <PersonalItem {...itm}/>
                  </div>)

              } else {
                return (itm.options &&
                  <div key={idx} className="spec-sub">
                    <ColorItem {...itm}/>
                  </div>)
              }
            })}
          </li>)}
      </>)
  }
}


export default SummaryList;
