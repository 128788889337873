import React, {Fragment} from "react";
import unescapeHtmlTag from "../../../../../core/utils/tools";
import ColorOptionController from "./ColorOptionController";
import PartOptionController from "./PartOptionController";
import withNavTab from "../../../../hoc/withNavTab";
import {colorTargetReplacement} from "../../../../../core/utils/replace";
import SlideToSubButton from "../atoms/SlideToSubButton";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../../redux/theme-slice/themeSlice";

const StepsList = ({stepArray, navigationDataObject, slideToSubHandler}) => {
  const langData = useSelector(langDataSelector);


  return (
    <div className={"option-h2"}>
      <h2>{unescapeHtmlTag(stepArray[0][langData.langName])}</h2>
      {
        stepArray.map((stepObject, stepObjectIdx) => (
          <Fragment key={'Step_' + stepObjectIdx}>
            {
              stepObject.stepType == "COLOR" && stepObject.stepItems.map((colorItemObject, colorItemObjectIdx) => {
                let code = colorTargetReplacement(colorItemObject.colorTarget);
                return (
                  <ColorOptionController
                    key={'ColorStep_' + colorItemObjectIdx}
                    linkPath={navigationDataObject.linkPath} {...{
                    code,
                    colorItemObject,
                    stepObject
                  }}
                  />)
              })
            }

            {
              (stepObject.stepType == "PART_3D" || stepObject.stepType == "MATERIAL") && stepObject.stepItems.length > 0 &&
              <PartOptionController
                key={'3DStep_' + stepObjectIdx}
                title={stepArray.length > 1 && stepObject[langData.langName]}
                code={stepObject.stepItems[0].partSwitch ? stepObject.stepItems[0].partSwitch.partCategory : stepObject.stepItems[0].materialSwitch && stepObject.stepItems[0].materialSwitch.type}
                partArray={stepObject.stepItems ? stepObject.stepItems : []}
                linkPath={navigationDataObject.linkPath}
                stepObject={stepObject}
              />
            }

            {
              stepObject.stepType == "PART_2D" && stepObject.stepItems.map((personalItemObject, idx) => (
                <PartOptionController
                  key={'PersonalStep_' + idx}
                  title={personalItemObject.partPersonalization[langData.langName]}
                  code={stepObject.nameEn.toUpperCase().replaceAll(" ", "_") + "_" + personalItemObject.partPersonalization.code}
                  personalItemObject={personalItemObject}
                  linkPath={navigationDataObject.linkPath}
                  stepObject={stepObject}
                />))
            }

            {
              stepObject.childNavData && stepObject.childNavData.map(item => (
              <SlideToSubButton key={item} item={item} slideToSubHandler={slideToSubHandler}/>))
            }
          </Fragment>))
      }
    </div>)
}


export default withNavTab(StepsList);