import {optionAction} from "../../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";


const useColorChip = () => {
  const dispatch = useDispatch();
  const configData = useSelector(configDataSelector);

  const colorChipSelectHandler = (code, item) => {
    const copyConfigData = {...configData[code]};
    const {CUSTOM, ...rest} = copyConfigData;

    const payload = {
      key: code,
      value: {
        ...rest,
        COLOR: item.color.code,
        DIFFUSE: item.color.diffRgb,
      }
    };

    if(item.color.type === "EXT") {
      payload.value.MATERIAL = item.color.materialType.toUpperCase();
    }
    dispatch(optionAction.setSendData(payload));
  }


  return { colorChipSelectHandler };
}

export default useColorChip;