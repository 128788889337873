
import React, {useEffect, useRef, useState} from 'react';
import { getColorPixelData } from "../../../../../core/utils/colorPixelUtils";


const useColorPickerColorTab = (targetClassName, colorRef, size)=>{
    const magnifier = useRef();
    const canvasRef = useRef();
    const magnifierSize = 50;

    const [isShow,setIsShow] = useState(false);
    const [colorData,setColorData] = useState([]);

    const init = {
        width: `${magnifierSize}px`,
        height: `${magnifierSize}px`,
        backgroundRepeat:'no-repeat',
        position:'absolute',
        pointerEvents:'none',
        border:'1px solid',
        borderRadius: '50%'
    }


    useEffect(() => {
        const preventBubbling = (e) => {
            e.preventDefault();
        }
        const pickers = document.getElementById("pickers");
        pickers.addEventListener("touchmove", preventBubbling, {passive: false});

        if (pickers)
            return () => pickers.removeEventListener("touchmove", preventBubbling);
    }, []);







    const [magniState,setState] = useState(init);

    useEffect(()=>{
        const colorBox = document.getElementsByClassName(targetClassName[0])[0].children[0];
        if(!colorBox){
            return;
        }
        const pointer = document.getElementsByClassName(targetClassName[1])[0].children[1];
        if(pointer){
            pointer.style.pointerEvents = 'none';
        }

        if(colorRef && colorRef.current){
            const mouseOver = (e)=>{
                setIsShow(true)
            }
            const mouseMove = (e)=>{
                var x = typeof e.offsetX === 'number' && e.offsetX;
                var y = typeof e.offsetY === 'number' && e.offsetY;

                let colors = []
                for(var i = x-size; i<=x+size; i++){
                    let yColors = []
                    for(var j =y-size;j<=y+size;j++){
                        const data= getColorPixelData(i,j,colorBox,colorRef);
                        yColors.push({hex: data.hex, rgb: data.rgb})
                    }
                    colors.push(yColors);
                }
                setColorData(colors);
                const quarterSize = magnifierSize/4;
                setState({
                    ...magniState,
                    top: y + colorBox.offsetTop - magnifierSize - quarterSize,
                    left: x + colorBox.offsetLeft + quarterSize
                })
            }

            const mouseOut = ()=>{
                setIsShow(false)
            }

            const touchMoveHandler = (e) => {
                setIsShow(true);
                let rect = e.target.getBoundingClientRect();

                let touchX = e.touches[0].pageX- rect.left;
                let touchY = e.touches[0].pageY- rect.top;

                let colors = []
                for(let i = touchX-size; i <= touchX+size; i++){
                    let yColors = []
                    for(let j = touchY-size; j <= touchY+size; j++){

                        if(touchX < 0 || touchY < 0 || touchX >= colorBox.clientWidth || touchY >= colorBox.clientHeight){
                            setIsShow(false);
                        }

                        const data= getColorPixelData(i,j,colorBox,colorRef);
                        yColors.push({hex: data.hex, rgb: data.rgb})
                    }
                    colors.push(yColors);
                }


                setColorData(colors);
                const quarterSize = magnifierSize/4;
                setState({
                    ...magniState,
                    top: touchY + colorBox.offsetTop - magnifierSize - quarterSize,
                    left: touchX + colorBox.offsetLeft + quarterSize
                })
            }

            const touchEndHandler = () => {
                setIsShow(false);
            }


            colorBox.addEventListener('mouseover',mouseOver)
            colorBox.addEventListener('mousemove',mouseMove)
            colorBox.addEventListener('mouseout',mouseOut)
            colorBox.addEventListener("touchmove", touchMoveHandler);
            colorBox.addEventListener("touchend", touchEndHandler);
            return ()=>{
                colorBox.removeEventListener('mouseover',mouseOver)
                colorBox.removeEventListener('mousemove',mouseMove)
                colorBox.removeEventListener('mouseout',mouseOut)
                colorBox && colorBox.removeEventListener("touchmove", touchMoveHandler);
                colorBox && colorBox.removeEventListener("touchend", touchEndHandler);
            }
        }

    },[])

    return {magnifier,canvasRef,isShow,colorData,magniState}
}

export default useColorPickerColorTab;
