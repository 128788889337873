/**
 * color converter
 * */

export const ColorToHex = (color) =>{
    var hexadecimal = color.toString(16);
    return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
}

export const ConvertRGBtoHex = (red, green, blue) =>{
    return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
}

export const hexToRgb=(hex) =>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const hsvToRgb = (h, s, v) =>{
    let d = 0.0166666666666666 * h;
    let c = v * s;
    let x = c - c * Math.abs(d % 2.0 - 1.0);
    let m = v - c;
    c += m;
    x += m;
    c = Math.round(c * 255);
    m =  Math.round(m * 255);
    x =  Math.round(x * 255);
    switch (d >>> 0) {
        case 0: return {r: c, g: x, b: m,a:1};
        case 1: return {r: x, g: c, b: m,a:1};
        case 2: return {r: m, g: c, b: x,a:1};
        case 3: return {r: m, g: x, b: c,a:1};
        case 4: return {r: x, g: m, b: c,a:1};
    }
    return {r: c, g: m, b: x,a:1};
}
export const hsv_to_hsl = (h,s,v) =>{
    // both hsv and hsl values are in [0, 1]
    var l = (2 - s) * v / 2;

    if (l != 0) {
        if (l == 1) {
            s = 0;
        } else if (l < 0.5) {
            s = s * v / (l * 2);
        } else {
            s = s * v / (2 - l * 2);
        }
    }

    return {
        h:h,
        s:s,
        l:l
    }
};
function color_f(t){
    if(t > 0.008856){
        return Math.pow(t,1/3);
    }else{
        return (903.3 * t + 16)/116;
    }
}

export const rgb_to_lab = (r,g,_b)=>{
    var r = r / 255,
        g = g / 255,
        b = _b / 255,
        x, y, z;

    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

    x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
    y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
    z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;

    return {l:Math.round((116 * y) - 16), a:Math.round(500 * (x - y)), b:Math.round(200 * (y - z))}
}

export const lab_to_rgb = (l,a,_b)=>{
    l = parseInt(l);
    a = parseInt(a)
    _b = parseInt(_b);

    var y = (l + 16) / 116,
        x = a / 500 + y,
        z = y - _b / 200,
        r, g, b;

    x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16/116) / 7.787);
    y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16/116) / 7.787);
    z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16/116) / 7.787);

    r = x *  3.2406 + y * -1.5372 + z * -0.4986;
    g = x * -0.9689 + y *  1.8758 + z *  0.0415;
    b = x *  0.0557 + y * -0.2040 + z *  1.0570;

    r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1/2.4) - 0.055) : 12.92 * r;
    g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1/2.4) - 0.055) : 12.92 * g;
    b = (b > 0.0031308) ? (1.055 * Math.pow(b, 1/2.4) - 0.055) : 12.92 * b;

    return {r:Math.round(Math.max(0, Math.min(1, r)) * 255),
        g:Math.round(Math.max(0, Math.min(1, g)) * 255),
        b:Math.round(Math.max(0, Math.min(1, b)) * 255)}
}

export const getHSVToPosition = (obj,x,y)=>{
    const containerWidth = obj.getBoundingClientRect().width;
    const containerHeight = obj.getBoundingClientRect().height;

    var left = x ;
    var top = y ;

    if (left < 0) {
        left = 0;
    } else if (left > containerWidth) {
        left = containerWidth;
    }

    if (top < 0) {
        top = 0;
    } else if (top > containerHeight) {
        top = containerHeight;
    }

    var saturation = left / containerWidth;
    var bright = 1 - top / containerHeight;

    return {saturation,bright}
}
