

const usePartOptionController = () => {

  const findSelectedPartItem = (stepObject, partArray, codeKeyData, personalItemObject) => {
    let partSwitch;
    let selectedIndex = 0;
    let selectedItem;

    const findSelected = (partArray, codeKeyData, switchType, switchKey, personalItemObject) => {
      if(!partArray && personalItemObject){
        if((codeKeyData) && (codeKeyData.CODE === personalItemObject.partPersonalization.code)) {
          partSwitch = personalItemObject.partPersonalization.code;
          selectedItem  = personalItemObject;
        } else {
          partSwitch = "";
          selectedItem = null;
        }
      } else if(partArray) {
        partArray.find((item, index) => {
          if((codeKeyData) && item[switchType].code === codeKeyData.CODE) {
            selectedIndex = index;
            selectedItem = item;
          }
        });
        partSwitch = selectedItem && selectedItem[switchType][switchKey];
      }
    }

    let switchType;
    let switchKey;
    switch (stepObject.stepType) {
      case "PART_3D":
        switchType = "partSwitch";
        switchKey = "partCategory";
        findSelected(partArray, codeKeyData, switchType, switchKey);
        break;

      case "MATERIAL":
        switchType = "materialSwitch";
        switchKey = "type";
        findSelected(partArray, codeKeyData, switchType, switchKey);
        break;

      case "PART_2D":
        switchType = "partPersonalization";
        switchKey = "code";
        findSelected(null, codeKeyData, switchType, switchKey, personalItemObject);
        break;

      default:
        switchType = "";
        switchKey = "";
    }

    return { partSwitch, selectedItem, selectedIndex };
  }


  return { findSelectedPartItem }
}
export default usePartOptionController;