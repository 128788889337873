import unescapeHtmlTag from "../../../../core/utils/tools";
import React from "react";


const CardDesc = ({name, desc}) => {

  return (
    <>
      <h3>{unescapeHtmlTag(name) || ""}</h3>
      <p>{unescapeHtmlTag(desc) || ""}</p>
    </>
  )
}

export default CardDesc;