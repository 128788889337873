import React, {useRef, useState} from "react";

import {requestPdfDownload} from "../../../../core/api/pdfApi";
import {useSelector} from "react-redux";
import {screenShotSelector} from "../../../redux/summary-slice/summarySlice";

const DownloadPdfButton = ({summaryConfigData}) => {
  const screenShotReady = useSelector(state => state.summary.screenShotReady);
  const screenShot = useSelector(screenShotSelector);
  const configId = useSelector(state => state.qrcode.configId);
  const downloadRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const downLoadPdf = () => {
    if(!screenShotReady) {
      return ;
    }

    let payloadScreenShotArray;
    setIsLoading(true);

    const replacementCameraView = (id) => {
      let cameraView;
      switch (id) {
        case "ExtCaptureCamera":
          cameraView = "EXT_FRONT_LEFT";
          break;

        case "ExtCaptureCamera2":
          cameraView = "EXT_REAR_RIGHT";
          break;

        case "IntCaptureCamera":
          cameraView = "INT_CENTER_LOOKAT_DASH";
          break;

        case "IntCaptureCameraForRear":
          cameraView = "INT_CENTER_LOOKAT_REAR";
          break;
      }
      return cameraView;
    }


    if (screenShot.length > 0) {
      payloadScreenShotArray = screenShot.map(item => {
        const splitData = item.img.split("data:image/png;base64,");
        return {
          "summaryImage": splitData[1],
          "cameraView": replacementCameraView(item.id)
        }
      })
    }
    const payload = {
      "summaryImageList" : payloadScreenShotArray,
      "bespokeCode" : configId,
      "summaryConfigData" : JSON.stringify(summaryConfigData),
    }


    requestPdfDownload(payload)
      .then(res => {

        const name = res.headers["content-disposition"].split("filename=")[1].replace(/"/g, "");
        const url = window.URL.createObjectURL(new Blob([res.data]));

        downloadRef.current.href = url;
        downloadRef.current.download = name;
        downloadRef.current.click();
        downloadRef.current.remove();
        setIsLoading(false);

        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", name);
        // link.style.cssText = "display:none";
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
    }).catch(error => {
      console.log("error",error);
    });
  }


  return (
    <>
      <div className="summary-btngroup" onClick={downLoadPdf} data-html2canvas-ignore={"true"}
           // style={{display: process.env.REACT_APP_DOMAIN === "https://oneofone.genesis.com" ? "none" : ""}}
      >
        <span className="btn download notheme" data-block {...!screenShotReady || isLoading ? {"data-disabled": "true"} : ""}>
          <span>{
            isLoading ? "Downloading..." : "Download PDF"
          }</span>
          {
            isLoading && (
              <div className={"loadingView"} style={{display: "block"}}>
                <div className={"spinner"}/>
              </div>
            )
          }
        </span>
      </div>
      <div>
        <a ref={downloadRef}/>
      </div>
    </>
  )
};
export default DownloadPdfButton;
