/*
* load configuration confirm
*
* */

import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {introAction, menuAction, modalAction, optionAction} from "../../../redux/store";
import {confLoad} from "../../../../core/api/configuratorApi";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";

const LoadConfirm = ({bespokeCode, componentMount, vehicleSelectHandler}) => {
  const dispatch = useDispatch();

  const {connectMode} = useSelector(state => ({
    connectMode: state.theme.connectMode,
  }), shallowEqual);



  const submit = async () => {
    const peer = await callPeerRemoteModule(connectMode);
    const configLoadResponse = await confLoad(bespokeCode);


    if (configLoadResponse.data.result && configLoadResponse.data.message === "SUCCESS") {
      const configData = configLoadResponse.data.data.config_data;
      const productCode = configLoadResponse.data.data.productCode || "";
      const loadMyDesign = true;

      if(!peer) {
        const setConfig = new Promise(resolve => {
          resolve(dispatch(optionAction.setLoadData(configData)));
          dispatch(introAction.setLoadingState("loading"));
        });
        setConfig.then(() => {
          setTimeout(() => {
            window.location.href = `/en/${productCode.toLowerCase()}/configurator/exterior`
          },100)
        });


      } else if (peer) {
        vehicleSelectHandler(productCode, loadMyDesign, connectMode, configData);
        peer.conn.sendData("dispatch", {action: "introAction", func: "setLoadingState", payload: "wait"});
      }

      dispatch(menuAction.menuToggle());
      // dispatch(modalAction.modalClose());

    } else {
      componentMount("ModalAlert", <>
        <div className={"modal-body"}>
          <h2>Failed</h2>
          <p>Configuration data does not exist.</p>
        </div>
        <div className={"modal-footer"}>
          <button type={"button"} className={"btn notheme"} data-block data-size={"lg"} onClick={() => {
            dispatch(menuAction.menuToggle());
            dispatch(modalAction.modalClose());
          }}>
            <span>Close</span>
          </button>
        </div>
      </>, "alert");
    }
  }


  return (
    <>
      <div className={"modal-body"}>
        <h2>Are you sure you want to load the code?</h2>
        <p>The values configured so far are replaced</p>
      </div>
      <div className={"modal-footer"}>
        <button
          type={"button"} className={"btn notheme"} data-block data-size={"lg"} data-intent={"confirm"}
          onClick={submit}
        >
          <span>Confirm</span>
        </button>
        <button type={"button"} className={"btn notheme"} data-block data-size={"lg"}
                onClick={() => dispatch(modalAction.modalClose())}>
          <span>Cancel</span>
        </button>

      </div>
    </>
  )
}

export default LoadConfirm
