import NoneChip from "../atoms/NoneChip";
import PartChip from "../atoms/PartChip";
import React from "react";

const Part3dTab = ({partArray,code, codeKeyData}) => {



  return (
    <div className={"tab-content active"}>
      <div className={"option"}>
        <ul className={"option-list"}>
          { (code === "SIGNATURE_PLATE") && <NoneChip code={code} codeKeyData={codeKeyData}/> }
          { partArray && [...partArray].map((partItem, partIndex) => {
            return(
              <PartChip key={partIndex} code={code} itemObject={partItem} codeKeyData={codeKeyData}/>
            )})
          }
        </ul>
      </div>
    </div>
  )
}
export default Part3dTab;
