export const DUMMY_PARTS = [
  {
    title:"DOOR FL",
    code:"doorFL",
    state: false
  },
  {
    title:"DOOR FR",
    code:"doorFR",
    state: false
  },
  {
    title:"DOOR RL",
    code:"doorRL",
    state: false
  },
  {
    title:"DOOR RR",
    code:"doorRR",
    state: false
  },
  {
    title:"Window FL",
    code:"windowFL",
    state: false
  },
  {
    title:"Window FR",
    code:"windowFR",
    state: false
  },
  {
    title:"Window RL",
    code:"windowRL",
    state: false
  },
  {
    title:"Window RR",
    code:"windowRR",
    state: false
  },
  {
    title:"Trunk",
    code:"trunk",
    state: false
  },
  {
    title:"Sun Roof",
    code:"sunroof",
    state: false
  },
]