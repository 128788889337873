import { useEffect, useState } from "react";
import MainComponent from "../components/mediaWall/templates/MainComponent";
import HowToConnect from "../components/mediaWall/molecules/HowToConnect";
import {persistor} from "../../index";
import {themeAction} from "../redux/store";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

const MediaWall = () => {

  const [isConnect,setIsConnect] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(()=>{
    if(!isConnect){
      dispatch(themeAction.setLangData(params.lang));
      dispatch(themeAction.setConnectMode("mediaWall"));
    }
  },[isConnect]);

  return (
    <>
      {isConnect
        ? <MainComponent/>
        : <HowToConnect isConnect={isConnect} setIsConnect={setIsConnect}/>
      }
    </>
  )
}
export default MediaWall;

