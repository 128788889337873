import React from "react";


const MaterialSwitch = ({name, colorMaterial, setCurrentMaterial}) => {


  return (
    <div className={"option"}>
      <h4>Material</h4>
      <div className={"switch"}>
        <label>
          <input type="radio" name={name} id={"glossy"} checked={colorMaterial === "GLOSSY"} onChange={() => setCurrentMaterial("GLOSSY")}/>
          <span>Glossy</span>
        </label>
        <label>
          <input type="radio" name={name} id={"matt"} checked={colorMaterial === "MATT"} onChange={() => setCurrentMaterial("MATT")}/>
          <span>Matt</span>
        </label>
      </div>
    </div>
  )
}

export default MaterialSwitch;
