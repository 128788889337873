/**
 * can select car list
 * */

import React, {useEffect, useState} from "react";
import ModelItem from "../atoms/ModelItem";
import {useSelector} from "react-redux";
import {productListApi} from "../../../../core/api/introApi";


const SelectModel = ({vehicleSelectHandler}) => {
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    productListApi().then(res => {
      if(res.data.result && res.data.message === "SUCCESS") {
        const productListData = res.data.data.content;
        setProductList(productListData);
      }
    })
  },[]);


  return (
    <div className="menu-section">
      <h2>SELECT MODEL</h2>
      <ul className="model-list">
        { productList.map((item, idx) => {
            return (
              <ModelItem key={idx} car={item} idx={idx} vehicleSelectHandler={vehicleSelectHandler}/>)
          })
        }
      </ul>
    </div>
  )
}
export default SelectModel;
