/**
 * public functions
 * */

import React, {Fragment} from 'react';

// export const sendToPlayCanvas = (webGlRef, data)=>{
//     webGlRef.contentWindow.postMessage(data,'*')
// }
// export const uuidv4 = () => {
//     return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
//         return v.toString(16);
//     });
// }

export const tabNameMapping = (tabCode) => {
    let tabName;
    switch (tabCode) {
        case "DETAIL":
            tabName = "DETAIL"
            break;
        case "COLOR":
            tabName = "COLOR"
            break;
        case "CUSTOM_COLOR":
            tabName = "SPECIAL REQUEST"
            break;
        case "NOTE":
            tabName = "NOTES"
            break;
    }
    return tabName;
}





export const unescapeHtmlTag = (src) => {
    if (typeof src !== 'string') {
        return src;
    }
    const map = {
        '#33':'!',
        'quot':'"',
        '#35':'#',
        '#36':'$',
        '#37':'%',
        'amp':'&',
        '#39':'\'',
        '#40':'(',
        '#41':')',
        '#42':'*',
        '#58':':',
        '#59':';',
        'lt':'<',
        'gt':'>',
        '#63':'?',
        '#64':'@',
        '#123':'{',
        '#124':'|',
        '#125':'}'
    };
    if (src !== undefined && src !== null && src.trim() !== '') {
        const replaceSrc = src.replace(/&([^;]+);/g, (m,c) => map[c]||'');
        return replaceSrc;
    } else {
        return src;
    }
}



export default unescapeHtmlTag;
const languageList = {
    "en":{
        "viewOption" : "VIEW OPTION",
        "background" : "BACKGROUND",
        "light" : "LIGHT ON/OFF",
        "camera" : "CAMERA CHANGE",
        "extCamera" : "EXTERIOR CAMERA",
        "intCamera" : "INTERIOR CAMERA",
        "frontCam" :"FRONT",
        "sideCam" :"SIDE",
        "rearCam" :"REAR",
        "cam" :"CAM",
        "model":"MODEL",
        "saveConf" : "Save Configuration",
        "loadConf" : "Load Configuration",
        "carConfCode" : "CODE",
        "qrCode" : "QR CODE",
        "sendEmail" : "SEND CODE TO EMAIL",
        "emailAddressMsg" : "Please type email address",
        "sendEmailBtn" : "Send Email",
        "codeGenerate" : "Please enter the code generated when saving.",
        "enterCode" : "Enter CODE",
        "load":"Load",
        "noSelect":"Not Selected",


        "ext":"EXTERIOR",
        "extColor":"EXTERIOR COLOR",
        "mainColor":"MAIN COLOR",
        "specialColor" : "SPECIAL COLOR",
        "color" : "COLOR",
        "specialRequest" : "SPECIAL REQUEST",
        "material":"MATERIAL",
        "glossy":"GLOSSY",
        "matt":"MATT",
        "oneofoneExclusive":"ONE OF ONE EXCLUSIVE",
        "genesisLib":"GENESIS LIBRARY",
        "wheel":"WHEELS",
        "option":"Option",
        "detail":"Detail",

        "int":"INTERIOR",
        "intColor":"INTERIOR COLOR",
        "keyColor":"KEY COLOR",
        "secondaryColor" : "SECONDARY COLOR",
        "intDetail":"INTERIOR Color Detail",
        "dashUpperLeather" : "DASH UPPER Leather",
        "dashUpperStitch" : "DASH UPPER STITCH",
        "dashLowerLeather" : "DASH Lower Leather",
        "dashLowerStitch" : "DASH Lower STITCH",
        "seatLeather" : "Seats Leather",
        "seatColor":"Seat Color",
        "seatInnerLinerStitch" : "SEAT INNER Liner Stitch",
        "seatOutLinerPiping" : " Seat Out liner & Piping",

        "headLining":"HEAD-LINING",
        "seatBelt":"SEATBELT",
        "floorMat" : "FLOOR MAT",
        'steeringWheelHorn':"STEERING WHEEL HORN",
        'steeringWheelHornWing':"STEERING WHEEL HORN Wing",
        'steeringWheelHornStitch':"STEERING WHEEL HORN STITCH",
        "seatType":"SEAT TYPE",
        "seatPattern":"SEAT PATTERN",
        "trunk":"TRUNK",
        "consoleSide":"CONSOLE SIDE",

        "personalization":"PERSONALIZATION",
        "signaturePlate" : "SIGNATURE PLATE",
        "engraving" : "ENGRAVING",
        "doorScuff" : "DOOR SCUFF",
        "headRest" : "HEADREST",
        "cushion" : "CUSHION",
        "embroidery":"EMBROIDERY",

        "rgb_r" : "Red",
        "rgb_g" : "Green",
        "rgb_b" : "Blue",
        "lab_l" : "L",
        "lab_a" : "a",
        "lab_b" : "b",
    },
    "kr":{
        "viewOption" : "VIEW OPTION",
        "background" : "BACKGROUND",
        "light" : "LIGHT ON/OFF",
        "camera" : "CAMERA CHANGE",
        "extCamera" : "외장 카메라",
        "intCamera" : "내장 카메라",
        "frontCam" :"FRONT",
        "sideCam" :"SIDE",
        "rearCam" :"REAR",
        "cam" :"CAM",
        "model":"모델",
        "saveConf" : "차량 구성 저장하기",
        "loadConf" : "차량 구성 불러오기",
        "carConfCode" : "차량 구성 코드",
        "qrCode" : "QR 코드",
        "sendEmail" : "이메일로 차량 구성 보내기",
        "emailAddressMsg" : "Please type email address",
        "sendEmailBtn" : "Send Email",
        "codeGenerate" : "Please enter the code generated when saving.",
        "enterCode" : "Enter CODE",
        "load":"Load",
        "noSelect":"선택 없음",

        "ext":"외장",
        "extColor":"외장 색상",
        "mainColor":"메인 컬러",
        "specialColor" : "스페셜 컬러",
        "color" : "컬러",
        "specialRequest" : "스페셜 요청",
        "material":"재질",
        "glossy":"유광",
        "matt":"무광",
        "oneofoneExclusive":"ONE OF ONE EXCLUSIVE",
        "genesisLib":"GENESIS LIBRARY",
        "wheel":"휠",
        "option":"항목",
        "detail":"상세 설명",

        "int":"내장",
        "intColor":"내장 컬러",
        "keyColor":"메인 컬러",
        "secondaryColor" : "SECONDARY COLOR",
        "intDetail":"내장 컬러 상세",
        "dashUpperLeather" : "대시보드 상단 가죽",
        "dashUpperStitch" : "대시보드 상단 스티치",
        "dashLowerLeather" : "대시보드 하단 가죽",
        "dashLowerStitch" : "대시보드 하단 스티치",
        "seatLeather" : "시트 가죽",
        "seatColor":"시트 컬러",
        "seatInnerLinerStitch" : "시트 이너라이너 스티치",
        "seatOutLinerPiping" : " 시트 아웃라이너 & 파이핑",
        "headLining":"헤드라이닝 ",
        "seatBelt":"시트 벨트",
        "floorMat" : "플로어 매트",
        'steeringWheelHorn':"스티어링 휠 혼",
        'steeringWheelHornWing':"스티어링 휠 림",
        'steeringWheelHornStitch':"스티어링 휠 스티치",
        "seatType":"시트 타입",
        "seatPattern":"시트 패턴",
        "trunk":"트렁크",
        "consoleSide":"콘솔 사이드",

       "personalization":"PERSONALIZATION",
        "signaturePlate" : "시그니쳐 플레이트",
        "engraving" : "인그레이빙",
        "doorScuff" : "도어스커프",
        "headRest" : "헤드레스트",
        "cushion" : "쿠션",
        "embroidery":"자수",

        "rgb_r" : "Red",
        "rgb_g" : "Green",
        "rgb_b" : "Blue",
        "lab_l" : "L",
        "lab_a" : "a",
        "lab_b" : "b",
    }
}

export const transferLanguage = (lang,id)=>{
    return languageList[lang][id]
}

/**
 * This function check if the specific element is loaded and ready.
 * @param selector
 * @returns {Promise<unknown>}
 */
export const elementReady = (selector) => {
    return new Promise((resolve, reject) => {
        let el = document.querySelector(selector);
        if (el) {
            resolve(el);
            return
        }
        new MutationObserver((mutationRecords, observer) => {
            // Query for elements matching the specified selector
            Array.from(document.querySelectorAll(selector)).forEach((element) => {
                resolve(element);
                //Once we have resolved we don't need the observer anymore.
                observer.disconnect();
            });
        })
            .observe(document.documentElement, {
                childList: true,
                subtree: true
            });
    });
}

export const getCurrentTime = () => {
    let timestamp = Date.now();
    let now = new Date(timestamp);

    let year = now.getFullYear();
    let month = ("0" + (now.getMonth() + 1)).slice(-2);
    let day = ("0" + now.getDate()).slice(-2);

    let hours = ("0" + now.getHours()).slice(-2);
    let minutes = ("0" + now.getMinutes()).slice(-2);
    let seconds = ("0" + now.getSeconds()).slice(-2);

    let dateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;
    return dateTime;
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
