import React from "react";


const SummaryAnimationButton = ({runSummaryAnimation}) => {


 return (
   <button type={"button"} className={"play-video"} onClick={runSummaryAnimation}>Play Video</button>
 )

}
export default SummaryAnimationButton;