import {langSwitch} from "../../../../core/utils/lang";
import React, {memo} from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";


const PreselectionDesc = () => {
  const langData = useSelector(langDataSelector);

  return (
    <>
      <h2>{langSwitch("preselection", "title", langData.lang)}</h2>
      <p dangerouslySetInnerHTML={{__html: langSwitch("preselection", "subtitle", langData.lang).replace(/\n/, "<br />")}}/>
    </>
  )
}
export default memo(PreselectionDesc);