import React from "react";
import CardImage from "../atoms/CardImage";
import CardDesc from "../atoms/CardDesc";
import SelectButton from "../atoms/SelectButton";
import {useSelector} from "react-redux";


const CardVisibleSide = ({cardSide, callBack, image, name, desc}) => {
  const connectMode = useSelector(state => state.theme.connectMode);

  return (
    <div className={`card-${cardSide}`}>

      <CardImage image={image}/>
      <div className="ps-info">
        <CardDesc name={name} desc={desc}/>
        { connectMode !== "mediaWall" && <SelectButton callBack={callBack}/> }
      </div>
    </div>
  )
}

export default CardVisibleSide;