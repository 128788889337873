import React, {memo, useEffect} from "react";
import SkipStepButton from "../atoms/SkipStepButton";
import PreselectionDesc from "../atoms/PreselectionDesc";


const PreselectionHeader = ({preselectionList}) => {

  return (
    <div className="ps-top">
      <PreselectionDesc/>
      {
        preselectionList.length > 0 ? <SkipStepButton preselectionList={preselectionList}/> : null
      }
    </div>
  )
}

export default memo(PreselectionHeader);