import React, {Fragment} from "react";
import LOGO_IMAGE from "../../../assets/images/logo_white.svg";
import {langSwitch} from "../../../../core/utils/lang";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";


const ConnectionComplete = () => {
  const langData = useSelector(langDataSelector);
  const connectedMessage = langSwitch("connectPage", "connectedMessage", langData.lang);

  return (
    <div className={"remote-tablet remote-wall"} data-theme="dark" data-lang={"ko"}>
      <div className={"wrap"}>
        <div className={"standby"}>
          <div className={"stb-header"}>
            <img src={LOGO_IMAGE} alt={"Genesis One of one logo"}/>
            <h1>{langSwitch("connectPage", "connectedTitle", langData.lang)}</h1>
            <>
              {connectedMessage.split("/").map((text, idx) => {
                if (idx === (connectedMessage.split("/").length - 1)) {
                  return (
                    <p key={text}>{text}</p>
                  )
                } else {
                  return (
                    <Fragment key={text}>
                      <p>{text}</p>
                      <hr/>
                    </Fragment>
                  )
                }
              })}
            </>
          </div>
        </div>
      </div>
    </div>
  )


}

export default ConnectionComplete
