/**
 * special request color picker version
 * */

import React from 'react';
import useColorPickerTab from "./useColorPickerTab";
import ColorPickerColorTab from "./ColorPickerColorTab";
import ColorPickerImageTab from "./ColorPickerImageTab";
import MaterialSwitch from "../atoms/MaterialSwitch";
import PickerSwitch from "../atoms/PickerSwitch";

const ColorPickerTab = ({code}) => {
  const {
    color, setColor,
    clickApply, changeColor, imageChange,
    colorRef, fileRef, imageRef, imagePickerBgColorRef, imagePickerPointer,
    pickerType, setPickerType,
    selectImage, imageData,
    colorMaterial, setColorMaterial
  } = useColorPickerTab(code);

  return (
    <div className={"tab-content active"}>
      <div className={"tab-menu"}>
        {code.includes("EXT") &&
          <MaterialSwitch name={`ExteriorColor_PrimaryColor_Color_Material_${code}`} colorMaterial={colorMaterial} setCurrentMaterial={setColorMaterial}/>
        }
        <PickerSwitch name={`Select_picker_${code}`} setPickerType={setPickerType}/>
      </div>
      <div className={"picker"}>
        <ColorPickerColorTab {...{color, setColor, changeColor, clickApply, colorRef, code, pickerType}} />
        <ColorPickerImageTab {...{fileRef, selectImage, imageChange, imageRef, clickApply, changeColor, imageData, pickerType, imagePickerPointer, imagePickerBgColorRef}}/>
      </div>
    </div>)
}

export default ColorPickerTab;
