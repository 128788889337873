import React from 'react';
import {backgroundData} from "../../../../../core/dummy/backGroundList";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toggleAction} from "../../../../redux/store";
import callPeerRemoteModule from "../../../../../core/utils/callPeerRemoteModule";

const useBackgroundController = () => {
  const dispatch = useDispatch();
  const {background, connectMode,attract, backgroundView} = useSelector(state => ({
    background: state.toggle.background,
    connectMode: state.theme.connectMode,
    attract: state.toggle.attract,
    backgroundView: state.toggle.backgroundView
  }),shallowEqual);


  const backgroundChangeHandler = async (envCode) => {
    dispatch(toggleAction.setToggle({key: "background", value: envCode}));


    const peer = await callPeerRemoteModule(connectMode);
    if (peer) {
      peer.conn.sendData("env:change", envCode);
    } else if (!peer) {
      console.log("Background change : ", envCode);
      window && window.postMessage({ msg: "env:change", data: envCode }, '*');
    }
    if(backgroundView) {
      dispatch(toggleAction.setToggle({ key: "backgroundView", value: false}));
    }
  }


  const backgroundViewEvent = () => {
    dispatch(toggleAction.setToggle({ key: "backgroundView", value: true }));
  }


  return {backgroundChangeHandler, backgroundData, background, backgroundViewEvent}
}

export default useBackgroundController;
