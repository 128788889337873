

export function supportsAvif() {
  const avifImage = new Image();
  avifImage.src = process.env.PUBLIC_URL + '/intro/walkin_anim_quality_up_5.avif';

  return avifImage.decode()
    .then(() => true)
    .catch(() => false);
}

