import React, {useEffect, useRef, useState} from "react"
import StandbyHeader from "../atoms/StandbyHeader";
import StandByBody from "../atoms/StandByBody";
import {useSelector} from "react-redux";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import callPeerMediaWallModule from "../../../../core/utils/callPeerMediaWallModule";
import {persistor} from "../../../../index";


const HowToConnect = ({isConnect,setIsConnect}) => {
  const qrRef = useRef();
  const qrSize = useRef(0);

  const [isPeerOpen,setIsPeerOpen] = useState(false);
  const [peerId,setPeerId] = useState();



  useEffect(() => {
    persistor.purge();

    const computed = window.getComputedStyle(qrRef.current);
    qrSize.current = parseInt(computed.width, 10) - (parseInt(computed.padding, 10) * 2);

    (async () => {
      const peer = await callPeerMediaWallModule("mediaWall");
      if(!peer) {
        return;
      }

      if(peer.conn.peer._open){
        setIsPeerOpen(peer.conn.peer._open);
        setPeerId(peer.conn.peer._lastServerId);
      } else {
        peer.conn.connection(setIsConnect, setIsPeerOpen, setPeerId);
      }
    })();

  }, []);


  return (
    <div className={"remote-tablet remote-wall"} data-theme="dark" data-lang={"ko"}>
      <div className={"wrap"}>
        <div className={"standby"}>
          <StandbyHeader/>
          <StandByBody {...{isPeerOpen, peerId, qrRef, qrSize}}/>
        </div>
      </div>
    </div>
  );
}

export default HowToConnect;
