import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
  currentVehicle:"",                 // current car code ( name )
  currentNavigation : "",      // current category ( preselection , ext , int, etc. );
  currentStep: "",
  checkClickHeader: "",
  isGnb:true,               //current menu gnb ( isgnb = preselection,summary )
  currentColorPicker:{},    // color picker ( color , image )
  CONFIG_DATA:{             // configuration
  },
  childNavigationData: "", // child navigation에 출력할 옵션
  dataSub: false, // 부모네비게이션-자식네비게이션 전환용 css 옵션
}
const interiorDetailPart = { // detail part list
  "INT_KEY" : ["INT_DASH_LOWER_COLOR","INT_DASH_LOWER_STITCH_COLOR","INT_SEAT_COLOR","INT_SEAT_INNERLINER_COLOR","INT_SEAT_OUTLINE_COLOR",
    "INT_SEAT_PIPING_COLOR", "INT_HEADLI5647888888888888888888856655NING_COLOR","INT_SEATBELT_COLOR","INT_FLOORMAT_COLOR", "INT_MAT_COLOR", "INT_TRUNK_COLOR",
    "INT_STEERINGWHEEL_ORNAMENT_COLOR", "INT_CONSOLE_UPR_COLOR", "INT_CONSOLE_LWR_COLOR", "INT_CONSOLE_UPR_STITCH_COLOR", "INT_CONSOLE_LWR_STITCH_COLOR", "INT_DOORQUILTING", "INT_FOOTREST","INT_STEERINGWHEEL_INNER"
  ],

  "INT_SECONDARY":["INT_DASH_UPPER_COLOR","INT_DASH_UPPER_STITCH_COLOR", "INT_STEERINGWHEEL_STR_STITCH_COLOR","INT_STEERINGWHEEL_STR_COLOR", "INT_STEERINGWHEEL_OUTER"
  ],


  "GV80_KEY": ["INT_STEERINGWHEEL_HORN_COLOR","INT_STEERINGWHEEL_HORN_STITCH_COLOR"],
  "GV80_SECONDARY": ["INT_ARMREST"],
  "G90_KEY": ["INT_ARMREST"],
  "G90_SECONDARY": ["INT_STEERINGWHEEL_HORN_COLOR","INT_STEERINGWHEEL_HORN_STITCH_COLOR"],
}

export const optionWindowSlice = createSlice({
  name : "optionWindow",
  initialState : initialState,
  reducers : {
    clearOption(state){
        state = initialState
    },
    setCurrentVehicle(state,action) {
      if (action.payload.code.includes("g90")) {
        interiorDetailPart["INT_KEY"] = [...interiorDetailPart["INT_KEY"], ...interiorDetailPart["G90_KEY"]];
        interiorDetailPart["INT_SECONDARY"] = [...interiorDetailPart["INT_SECONDARY"], ...interiorDetailPart["G90_SECONDARY"]];
      } else if (action.payload.code.includes("gv80")) {
        interiorDetailPart["INT_KEY"] = [...interiorDetailPart["INT_KEY"], ...interiorDetailPart["GV80_KEY"]];
        interiorDetailPart["INT_SECONDARY"] = [...interiorDetailPart["INT_SECONDARY"], ...interiorDetailPart["GV80_SECONDARY"]];
      }
      state.currentVehicle = action.payload;
    },
    setCurrentNavigation(state, action) {
      state.currentNavigation = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setCheckClickHeader(state, action) {
      state.checkClickHeader = action.payload
    },
    setOptions(state,action){

      state[action.payload.selection] = action.payload.value
    },
    setNotes(state, action) {
      const copyConfigData = {...state["CONFIG_DATA"]};
      const {key, value} = action.payload;
      const resultData = {...copyConfigData[key], ...value}

      if(!value.NOTES) {
        delete resultData.NOTES;
      }

      state["CONFIG_DATA"] = {
        ...copyConfigData,
        [key]: resultData
      }
    },
    setSendData(state,action){
      const copyConfigData = {...state["CONFIG_DATA"]}
      if(action.payload.key == "INT_KEY" || action.payload.key == "INT_SECONDARY"){
        const {NOTES, ...rest} = action.payload.value;

        if(action.payload.key == "INT_KEY" && !copyConfigData["INT_SECONDARY"]){

          copyConfigData[action.payload.key] = action.payload.value;
          const allInterior = interiorDetailPart["INT_KEY"].concat(interiorDetailPart["INT_SECONDARY"]);

          allInterior.map(detail=>{

            const detailsNotes = copyConfigData[detail] && copyConfigData[detail].NOTES ? {NOTES: copyConfigData[detail].NOTES} : null;
            if(detailsNotes){
              copyConfigData[detail] = {...rest, ...detailsNotes};
            } else {
              copyConfigData[detail] = {...rest};
            }
          })
        }else{
          copyConfigData[action.payload.key] = action.payload.value

          interiorDetailPart[action.payload.key].map(detail=>{
            const detailsNotes = (copyConfigData[detail] && copyConfigData[detail].NOTES) ? {NOTES: copyConfigData[detail].NOTES} : null;
            if(detailsNotes){
              copyConfigData[detail] = {...rest, ...detailsNotes};
            } else {
              copyConfigData[detail] = {...rest};
            }
          })
        }
      }

      state["CONFIG_DATA"] = {
        ...copyConfigData,
        [action.payload.key]:action.payload.value,
      };
    },
    setSendDataRemove(state,action){
      const copyConfigData = {...state["CONFIG_DATA"]};
      delete copyConfigData[action.payload];

      if(action.payload == "INT_SECONDARY"){

        interiorDetailPart["INT_SECONDARY"].map(item=>{
          const {NOTES, ...rest} = copyConfigData["INT_KEY"];
          const detailsNotes = (copyConfigData[item] && copyConfigData[item].NOTES) ? {NOTES: copyConfigData[item].NOTES} : null;
          if(detailsNotes) {
            copyConfigData[item] = {...rest, ...detailsNotes};
          } else {
            copyConfigData[item] = {...rest};
          }
        });
      }
      state["CONFIG_DATA"] = copyConfigData;
    },
    setLoadData(state,action){

      const temp = {...action.payload}
      interiorDetailPart["INT_KEY"].map(item=>{
        if(!temp[item]){
          temp[item] = temp["INT_KEY"]
        }
      })

      if(temp["INT_SECONDARY"]){

        interiorDetailPart["INT_SECONDARY"].map(item=>{
          if(!temp[item]){
            temp[item] = temp["INT_SECONDARY"]
          }
        })
      }else{
        interiorDetailPart["INT_SECONDARY"].map(item=>{
          if(!temp[item]){
            temp[item] = temp["INT_KEY"]
          }
        })
      }
      state["CONFIG_DATA"] = temp
    },
    setSelectedColorPicker(state,action){
      state.currentColorPicker = {
        ...state.currentColorPicker,
        [action.payload.key]:action.payload.value
      }
    },
    setTwoTone(state,action){
      state.twoTone[action.payload.location] = action.payload.value ? action.payload.value : !state.twoTone[action.payload.location]
    },
    setClearOption(state){
      const keys = Object.keys(state);
      for(var i = 0;  i< keys.length;i++){
        const key = keys[i]
        if(key !== "allColorTypeData" && key !== "currentVehicle"){

          delete state[key]
        }
      }

      const initkeys = Object.keys(initialState);
      for(var i = 0;  i< initkeys.length;i++){
        const key = initkeys[i]
        if(key !== "allColorTypeData" && key !== "currentVehicle"){

          state[key] = initialState[key]
        }
      }
    },

    setChildNavigationData(state, action) {
      state.childNavigationData = action.payload;
    },
    setDataSub(state, action) {
      state.dataSub = action.payload;
    },
  }
  ,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
})



export const configDataSelector = createSelector(
  (state) => state.optionWindow.CONFIG_DATA,
  (configData) => {
    return configData;
  }
)
export const childNavigationDataSelector = createSelector(
  (state) => state.optionWindow.childNavigationData || initialState.childNavigationData,
  (childNavigationData) => {
    return childNavigationData;
  }
)
export const currentVehicleSelector = createSelector(
  (state) => state.optionWindow.currentVehicle || initialState.currentVehicle,
  (currentVehicle) => {
    return currentVehicle;
  }
)
