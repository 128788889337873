import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./app/redux/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import {BrowserRouter} from "react-router-dom";
import "../src/app/assets/fonts/GenesisSans/GenesisSans.css";
import * as serviceWorkRegistration from "./serviceWorkerRegistration";


// let timeout
// document.addEventListener('visibilitychange', function() {
//   if( document.hidden ) {
//     console.log("The page is now in the background. It will be redirected to home page after 20 seconds");
//     timeout = setTimeout(function() {
//       console.log("Redirecting to home page");
//       window.location.href = process.env.REACT_APP_DOMAIN;
//     }, 20000);
//   } else {
//     clearTimeout(timeout);
//   }
// });


export let persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>

    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkRegistration.register();
