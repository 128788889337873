/**
 * loading video
 * */

import React from 'react';
import loadingVideo from '../../assets/images/loading.mp4';
import LoadingProgress from "./LoadingProgress";

const Loading = () => {

  return (
    <div className={"bg_loading"} style={{opacity: 1}}>
      <video muted loop playsInline autoPlay id={"loadingVideo"}>
        <source src={loadingVideo} type="video/mp4"/>
      </video>
      <LoadingProgress/>
    </div>
  )
}

export default Loading
