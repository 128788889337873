import React, {useEffect} from "react";
import {QRCodeSVG} from "qrcode.react";
import {confSave} from "../../../../core/api/configuratorApi";
import {useParams} from "react-router-dom";

const SummaryQrCode = (props) => {
    const params = useParams();
    const {configId, configData, currentVehicle} = props;
    
    useEffect(() => {
        (async () => {
            try {

                const payloadData = {
                    productId: currentVehicle.id,
                    bespokeCode: configId,
                    email: null,
                    configuration: JSON.stringify(configData)
                }

                await confSave({                             // save configuration
                    headers: {
                        "Content-Type": "application/json",
                        "Accept-Language": params.lang === "kr" ? "ko" : "en"
                    }
                }, payloadData)
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return (
        <div className={"summary-code"}>
            <h5>Your Genesis One of One code</h5>
            <div className="code-wrap">
                <div className="qrcode">
                    <QRCodeSVG
                        value={`${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_QRCODE_PATH}${configId}`}
                        size={150}
                        id={"qrCode_id"}
                    />
                </div>
                <div className="codes">
                    <div className="code">{configId}</div>
                </div>
            </div>
        </div>
    )
}
export default SummaryQrCode;
