/**
 * Save Load Configuration HeaderItem
 * */

import React from "react";
import SaveComponent from "../../modal/saveYourDesign/SaveComponent";
import LoadComponent from "../../modal/loadYourDesign/LoadComponent";
import useModal from "../../../hooks/core/useModal";
import Button from "../../independent/Button";

const MenuBottom = ({vehicleSelectHandler}) => {
  const { ModalPortal, componentMount, subModalMount, SubModalPortal } = useModal();

  return (
    <div className="menu-bottom">
      <div className="contact-info">
        <h2>Contact</h2>
        <a href="mailto:oneofone@genesis.com">oneofone@genesis.com</a>
      </div>
      <Button className={"menu-save notheme"} name={"Save your Design"} block={true} confirm={true} size={"lg"}
              onClick={() => componentMount("SaveConfigurator", <SaveComponent subModalMount={subModalMount}/>)}/>
      <Button className={"menu-load notheme"} name={"Load your Design"} block={true} size={"lg"}
              onClick={() => componentMount("LoadConfigurator", <LoadComponent componentMount={componentMount} vehicleSelectHandler={vehicleSelectHandler}/>)}/>
      <ModalPortal/>
      <SubModalPortal/>
    </div>
  )
}
export default MenuBottom;
