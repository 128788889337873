import React from "react";
import controllerImg from "../../assets/images/3D_controller.png";
import {useSelector} from "react-redux";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";


const TouchController = () => {
  const connectMode = useSelector(state => state.theme.connectMode);




  const prev = {x:0,y:0,distance:0}

  let isTouch = false;
  const touchStartEvent = (e)=>{
    prev.x = e.touches[0].clientX
    prev.y = e.touches[0].clientY
    if(e.touches.length == 2){
      prev.distance = Math.sqrt(Math.pow(e.touches[1].clientX-e.touches[0].clientX,2) + Math.pow(e.touches[1].clientY - e.touches[0].clientY,2))
    }
    isTouch = true;
  }

  const touchMoveEvent = async (e)=>{
    // console.log('move!', e.touches[0].clientX, e.touches[0].clientY)
    const peer = await callPeerRemoteModule(connectMode);

    if(isTouch){
      if(e.touches.length  == 1){
        const mouse = {
          x:(e.touches[0].clientX-prev.x)/3,
          y:(e.touches[0].clientY-prev.y)/3
        }
        prev.x = e.touches[0].clientX
        prev.y = e.touches[0].clientY

        peer && peer.conn.sendData('camera:move',mouse);

      } else if(e.touches.length == 2){
        const distance = Math.sqrt(Math.pow(e.touches[1].clientX-e.touches[0].clientX,2) + Math.pow(e.touches[1].clientY - e.touches[0].clientY,2))


        // console.log("distance",distance);
        // console.log("zoom", (distance-prev.distance)/50);

        peer && peer.conn.sendData('camera:zoom',(distance-prev.distance)/50);
        prev.distance = distance

      }else{

      }

    }
  }

  const touchEndEvent = (e)=>{
    isTouch = false;
  }

  return (
    <main className={`mainView`}>
      <div className="controller-wrap">
        <div className="controller" onTouchStart={touchStartEvent} onTouchEnd={touchEndEvent} onTouchMove={touchMoveEvent} >
          <img src={controllerImg}/>
          <div className="touch"></div>
        </div>
      </div>

    </main>
  )
}

export default TouchController;
