import React, {Fragment} from "react";
import unescapeHtmlTag from "../../../../core/utils/tools";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";


const PersonalItem = ({partPersonalization, notes, nameKr, nameEn}) => {
  const langData = useSelector(langDataSelector);


  return (
    <Fragment>
      { partPersonalization && <>
        <div className="spec-name">{unescapeHtmlTag(partPersonalization[langData.langName])}</div>
        <div className="spec-value">
          {unescapeHtmlTag(langData.lang === "kr" ? nameKr : nameEn)}
          { notes ? (
            <p className={"spec-value-request"}>
              NOTE : {notes}
            </p>
          ) : null
          }
        </div>
        <div className="spec-code">{partPersonalization.code}</div>
      </>
      }
    </Fragment>)

}

export default PersonalItem;