import React from 'react';
import {QRCodeCanvas} from 'qrcode.react';

const QRCodeComponent = ({peerId, qrSize})=>{
    return (
        <QRCodeCanvas value={`${peerId}`} size={qrSize}/>
    )
}

export default QRCodeComponent;
