import logoWhite from "../../../assets/images/logo_white.svg";
import logoDark from "../../../assets/images/logo.svg";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";

const Logo = ({currentTitle}) => {
  const langData = useSelector(langDataSelector);
  const [stepOpened, setStepOpened] = useState(false);

  const linkToIntro = () => {
    if(window) {

      if(!window.pc) {
        return;
      }

      if(!window.pc.app){
        return;
      }

      window.pc.app.destroy();
    }
    window.location.href = `/${langData.lang}/intro`;
  }
  

  return (
    <>
      <div className="logo" onClick={linkToIntro}>
        <img src={logoWhite} className="logo-white"/>
        <img src={logoDark} className="logo-dark"/>
      </div>
      <button type="button" className={`mo-current-step ${stepOpened && 'step-active'}`}
              onClick={() => setStepOpened(current => !current)}>{currentTitle}</button>
    </>
  )
}

export default Logo;