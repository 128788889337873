import React from "react";


const PreviewImg = ({selectedItem, currentColor}) => {

  return (
    <div className="option-value-thumb">
      <div className="thumb" style={currentColor && {backgroundColor: `rgba(${currentColor && currentColor.COLOR})`}}>
        {selectedItem && <img src={selectedItem && process.env.REACT_APP_image_path+"/item/"+selectedItem.thumbnailImg}/>}
      </div>
      <div className={`${currentColor && currentColor.MATERIAL === "GLOSSY" ? "material-glossy": ''}`}></div>
    </div>
  )
}

export default PreviewImg;