import {useCallback} from 'react';
import {optionAction, toggleAction} from "../../redux/store";
import {batch, shallowEqual, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getPreselectionDetail} from "../../../core/api/preselectionApi";
import {setPreselectionVehicleOption} from "../../../core/utils/buildConfigSnippet";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";

/**
 *  default option setting
 * */



const useSetDefaultOption = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const canvas = document.getElementById("application-canvas");

  const {hotspot, connectMode} = useSelector(state => ({
    hotspot: state.toggle.hotspot,
    connectMode: state.theme.connectMode,
  }), shallowEqual);



  const selectDefaultOption = useCallback(async (productId, preselectionId) => {
    const remoteModePeerConnected = await callPeerRemoteModule(connectMode);

    if (!productId || !preselectionId) {
      return;
    };

    if(!remoteModePeerConnected) {
      if(!canvas) {
        return false;
      } else {
        dispatch(toggleAction.setPreselectionDetailReady(true));
      }
    } else if (remoteModePeerConnected) {
      remoteModePeerConnected.conn.sendData("dispatch", { action: "toggleAction", func: "setPreselectionDetailReady", payload: true});
    }

    await getPreselectionDetail(productId, preselectionId).then(async (res) => {
      const selectedConfigData = res.data.data.config_data;
      const processed = await setPreselectionVehicleOption(selectedConfigData);

      batch(() => {
        dispatch(toggleAction.setToggle({ key: "camera", value: {currentCameraType: "EXT", currentCameraCode: "" }}));
        dispatch(toggleAction.setToggle({ key: "hotspot", value: false }));
        dispatch(optionAction.setClearOption());
        dispatch(optionAction.setLoadData(processed));
        dispatch(optionAction.setCheckClickHeader("exterior"));
        dispatch(optionAction.setCurrentNavigation("exterior"));

        if(remoteModePeerConnected) {
          dispatch(optionAction.setOptions({selection: "isGnb", value: false}));
        }
      });
    });


    if (!remoteModePeerConnected) {
     window && window.postMessage({msg: "camera:change", data: [hotspot, "EXT", 0]}, '*');
    } else if (remoteModePeerConnected) {
      remoteModePeerConnected.conn.sendData('camera:change', [hotspot, "EXT", 0]);
    }
  }, [connectMode, params, hotspot, canvas]);


  return {selectDefaultOption}
}

export default useSetDefaultOption;
