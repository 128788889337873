/**
 *  right menu container
 * */

import React from "react";
import {useDispatch} from "react-redux";
import SelectModel from "../molecules/SelectModel";
import MenuBottom from "../molecules/MenuBottom";
import {menuAction} from "../../../redux/store";
import {useOutletContext} from "react-router-dom";

const Menu = () => {
  const dispatch = useDispatch();
  const {vehicleSelectHandler} = useOutletContext();


  return (
    <div className="menu">
      <button type="button" className="close-menu" onClick={() => dispatch(menuAction.menuToggle())}>Close menu</button>
      <div className="menu-container">
        <SelectModel vehicleSelectHandler={vehicleSelectHandler}/>
      </div>
      <MenuBottom vehicleSelectHandler={vehicleSelectHandler}/>
    </div>
  )
}
export default Menu;
