import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {toggleAction} from "../../redux/store";
import {movingPartsListSelector} from "../../redux/toggle-slice/toggleSlice";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";


const MovingParts = () => {
  const dispatch = useDispatch();
  const connectMode = useSelector(state => state.theme.connectMode);
  const movingPartsList = useSelector(movingPartsListSelector);

  

  return (
    <div className={"moving-parts"}>
      <ul>
        {movingPartsList && movingPartsList.map((item) => (
          <li key={item.code}>
            <button type={"button"} className={item.state ? "active" : ""}
                    onClick={async () => {
                      const peer = await callPeerRemoteModule(connectMode);
                      dispatch(toggleAction.movingPartsControl(item.code));
                      peer && peer.conn.sendData("anim:event",item.code);
                    }}>
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MovingParts;
