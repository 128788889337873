import {langSwitch} from "../../../../core/utils/lang";
import React from "react";
import useSetDefaultOption from "../../../hooks/core/useSetDefaultOption";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";


const SkipStepButton = ({preselectionList}) => {
  const langData = useSelector(langDataSelector);
  const currentVehicle = useSelector(currentVehicleSelector);
  const {selectDefaultOption} = useSetDefaultOption();
  const defaultVehicleConfigData = preselectionList.find(item => item.isDefault);


  return (
    <button
      type="button"
      className={"skip-step preselection-arrow-right"}
      onClick={async() =>  await selectDefaultOption(currentVehicle && currentVehicle.id, defaultVehicleConfigData.id) }
    >
      {langSwitch("preselection", "skipMessage", langData.lang)}
    </button>
  )
}
export default SkipStepButton;