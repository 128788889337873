import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";
import axios from "axios";
import {platform} from "../../../../core/platform/platform";


const ModelInfo = ({imageRef, currentProduct, showModelInfo, setShowModelInfo, chosenVehicleImages}) => {
  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);
  const mobileSuffix = "m_";
  const chosenImageRef = useRef(0);
  const langData = useSelector(langDataSelector);
  const [imagesIsLoading, setImagesIsLoading] = useState(false);


  useEffect(() => {
    if (currentProduct) {
      if (chosenVehicleImages.has(currentProduct.code)) return;
      axios.get(`${process.env.PUBLIC_URL}/intro/${sessionStorage.getItem("supportAvif") ? "avif" : "webp"}/${isMobile ? mobileSuffix : ""}${currentProduct.code}-images.json`).then(chosenImageRes => {
        if (chosenImageRes.status === 200) {
          chosenVehicleImages.set(currentProduct.code, chosenImageRes.data);
          setImagesIsLoading(false);
        }
      });
    }
  }, [currentProduct]);


  let lastTimestamp = null;
  const speed = 1.2;
  const frame = 1000 / 60;

  const chosenVehicleAnimate = (timestamp) => {
    if (!lastTimestamp) {
      lastTimestamp = timestamp;
    }
    setShowModelInfo(false);

    const timeElapsed = (timestamp - lastTimestamp) / speed;

    if (Math.floor(timeElapsed) >= Math.floor(frame * speed)) {
      imageRef.current.src = chosenVehicleImages.get(currentProduct.code)[chosenImageRef.current].dataURI;
      chosenImageRef.current = (chosenImageRef.current + 1) % chosenVehicleImages.get(currentProduct.code).length;

      if (chosenImageRef.current >= chosenVehicleImages.get(currentProduct.code).length - 1) {
        window.location.href = `${process.env.REACT_APP_DOMAIN}/${langData.lang}/${currentProduct.code.toLowerCase()}`
        return;
      }
      lastTimestamp = timestamp;
    }
    requestAnimationFrame(chosenVehicleAnimate);
  }


  const buttonClickHandler = () => {
    if(chosenVehicleImages.get(currentProduct.code)) {
      chosenVehicleAnimate();
    } else {
      setImagesIsLoading(true);
      setTimeout(buttonClickHandler, 500);
    }
  }



  return (
    <div className={"model-info"} data-active={showModelInfo}>
      <div className={"model-name"}>
        <span>{currentProduct && currentProduct.displayTitle + " "}</span>
        <span className={"model-subname"}>{currentProduct && currentProduct.displaySubtitle}</span>
      </div>
      <button
        type={"button"}
        className={"btn"}
        data-intent={"black"}
        data-size={"lg"}
        onClick={buttonClickHandler}
      >
        {
          imagesIsLoading
            ?
            <span>
              <div className={"loadingView"} style={{display: "block"}}>
                <div className={"spinner"}/>
              </div>
            </span>
            :
            <span>Design Yours</span>
        }

      </button>
    </div>
  )

}

export default ModelInfo;