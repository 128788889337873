/**
 * layout item component
 * */

import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {platform} from "../../../../core/platform/platform";

const HeaderItem = ({headerItemData, setCurrentTitle, reg, callback}) => {
  const id = headerItemData.id;
  const path = headerItemData.path;
  const title = headerItemData.title;
  const itemId = id.replaceAll(reg,"");
  const isMobile = platform.mobile && (platform.ios || platform.safariOnIos || platform.android);

  const {currentNavigation, connectMode } = useSelector(state => ({
    currentNavigation: state.optionWindow.currentNavigation,
    connectMode: state.theme.connectMode,
  }),shallowEqual);



  useEffect(() => {
    if(isMobile && connectMode === "web") {
      const headerItemId = id.replaceAll(reg, "");
      if(headerItemId === currentNavigation){
        setCurrentTitle(title);
      }
    }
  },[currentNavigation]);


  return (
    <>
      { connectMode === "web" ? (
        <Link to={`${path}`}>
          <button id={id} className={itemId === currentNavigation ? "current" : ""} onClick={callback}>{title}</button>
        </Link>
      ) : (
        <button id={id} className={itemId === currentNavigation ? "current" : ""} onClick={callback}>{title}</button>)
      }
    </>)
}

export default HeaderItem;
