import React, {useEffect, useState} from "react"
import SummaryListGroup from "./SummaryListGroup";
import SummaryQrCode from "../atoms/SummaryQrCode";
import DownloadPdfButton from "../atoms/DownloadPdfButton";
import {colorTargetReplacement} from "../../../../core/utils/replace";
import {shallowEqual, useSelector} from "react-redux";
import {configDataSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";
import {navDataSelector} from "../../../redux/menuWindow-slice/menuWindowSlice";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";

const SummaryBody = () => {

  const configData = useSelector(configDataSelector);
  const navData = useSelector(navDataSelector);
  const langData = useSelector(langDataSelector);

  const {configId, connectMode, checkClickHeader, currentVehicle} = useSelector(state => ({
    configId: state.qrcode.configId,
    connectMode: state.theme.connectMode,
    checkClickHeader: state.optionWindow.checkClickHeader,
    currentVehicle: state.optionWindow.currentVehicle
  }),shallowEqual);

  const navigationList = navData && navData.processedNavData ? navData.processedNavData : [];

  const [part, setPart] = useState({
    "data": {}                                   // part, color list
  })

  useEffect(() => {
    if(!checkClickHeader.includes("summary")) {
      return;
    }


    const temp = {
      "data": {}
    }

    navigationList.map((nav) => {
      if (!nav.isGnb) {                                 // gnb가 아닌 경우 ( preselection, summary 가 아닌 경우 )
        if (nav.navigationSteps && nav.navigationSteps.length > 0) {           // navigation step이 1개 이상
          const stepArrayList = nav.navigationSteps;


          for (let stepArray of stepArrayList) {
            const stepType = stepArray[0] && stepArray[0].stepType;       // get step type

            for (let stepData of stepArray) {
              let itemsForColor = [];
              let itemsForPart2D = [];


              for (let stepItem of stepData.stepItems) {
                let key = ""
                let type = "data";
                let item = null;
                let title = stepData[langData.langName];

                if (stepType === "PART_2D") {                    //personal list

                  key = (stepData.nameEn && stepData.nameEn.toUpperCase().replace(' ', '_')) + '_' + (stepItem.partPersonalization && stepItem.partPersonalization.code);
                  if ((configData[key] && configData[key].CODE) === (stepItem.partPersonalization && stepItem.partPersonalization.code)) {
                    const temp = {...stepItem};
                    if (configData[key] && configData[key].NOTES) {
                      temp["notes"] = configData[key].NOTES;
                    }
                    itemsForPart2D.push(temp);
                  }


                } else if (stepType === "COLOR") {             // color list

                  key = colorTargetReplacement(stepItem.colorTarget);
                  let selectedItem = null;
                  let notes = "";

                  if (configData[key]) {
                    const itemOptions = stepItem.itemOptions;
                    title = stepItem[langData.langName];

                    if (configData[key].NOTES) {
                      notes = configData[key].NOTES;
                    }

                    if (configData[key].CUSTOM) {
                      selectedItem = configData[key];
                    } else {
                      const findSelected = itemOptions.find((color) => (color.color && color.color.code) === (configData[key] && configData[key].COLOR));
                      selectedItem = findSelected && findSelected.color;
                    }
                    itemsForColor.push({title: title, options: selectedItem, notes: notes});
                  }
                } else if (stepType === "MATERIAL") {          // material list ( veneer )

                  key = stepItem.materialSwitch && stepItem.materialSwitch.type;
                  if (configData[key] && configData[key].CODE === (stepItem.materialSwitch && stepItem.materialSwitch.code)) {
                    let temp = {...stepItem}
                    if (configData[key].NOTES) {
                      temp["notes"] = configData[key].NOTES;
                    }
                    item = temp;
                  }

                } else if (stepType === "PART_3D") {           // part

                  key = stepItem.partSwitch && stepItem.partSwitch.partCategory
                  if (configData[key] && configData[key].CODE === (stepItem.partSwitch && stepItem.partSwitch.code)) {
                    let temp = {...stepItem};
                    if (configData[key].NOTES) {
                      temp["notes"] = configData[key].NOTES;
                    }
                    if (configData[key].COLOR) {
                      temp["color"] = configData[key].COLOR;
                    }
                    item = temp;
                  }
                }
                if (temp[type][nav[langData.langName]] == null) {
                  temp[type][nav[langData.langName]] = {};
                }


                if (stepType === 'COLOR') {
                  item = itemsForColor;
                } else if (stepType === 'PART_2D') {
                  item = itemsForPart2D;
                }

                if (item !== null && title !== "") {
                  if (stepType === 'COLOR' || stepType === 'PART_2D') {
                    temp[type][nav[langData.langName]][stepData[langData.langName]] = item;
                  } else {
                    temp[type][nav[langData.langName]][title] = item;
                  }
                }
              }
            }
          }
        }
      }
    })
    setPart((current) => {
      return temp;
    });

    (async () => {
      const peer = await callPeerRemoteModule(connectMode);
      peer && peer.conn.sendData("summaryData", { data: temp, configId: configId, configData: configData });
    })();

  }, [checkClickHeader]);



  return (
    <div className={"summary-body"}>
      <div className={"summary-spec"}>
        <SummaryListGroup items={part["data"]}/>
      </div>
      <div className={"summary-action"}>
        <SummaryQrCode configId={configId} configData={configData} currentVehicle={currentVehicle}/>
        <DownloadPdfButton summaryConfigData={part["data"]}/>
      </div>
    </div>
  )
}

export default SummaryBody;
