import React, {useEffect} from "react";
import {useSelector} from "react-redux";


const ExtOnlyControllers = ({light, lightEvent, hotspotEvent}) => {
  const hotspot = useSelector(state => state.toggle.hotspot);
  const connectMode = useSelector(state => state.theme.connectMode);


  useEffect(() => {
    window && window.postMessage({msg: "hotspot", data: hotspot}, '*');
  }, [hotspot]);

  return (<>
    {connectMode === "web" && (
      <button
        type="button"
        className={`control-button ${hotspot && "active"}`}
        id="viewMovingParts"
        onClick={() => hotspotEvent()}
      >
      Toggle Moving Parts
    </button>)}

    <button type="button" className={`control-button ${light && "active"}`} id="viewHeadlight"
            onClick={() => lightEvent()}>
      Toggle Headlight
    </button>
  </>)
}

export default ExtOnlyControllers;