/**
 * modal state redux
 * */

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
  modalData: { id : "", isOpened: false, dataType: "" },
  multipleModalData: { isOpened: false, dataType: "", closeAll: false },
}

export const modalSlice = createSlice({
  name : "modal",
  initialState : initialState,
  reducers : {
    modalClose (state) {
      state.modalData = initialState.modalData;
    },
    multipleModalClose (state, action) {
      state.multipleModalData = initialState.multipleModalData;
    },

    setModal(state, action) {
      state.modalData = action.payload;
    },
    setMultipleModal(state, action) {
      state.multipleModalData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
})

export const modalDataSelector = createSelector(
  (state) => state.modal.modalData || initialState.modalData,
  (modalData) => {
    return modalData;
  }
)

export const multipleModalSelector = createSelector(
  (state) => state.modal.multipleModalData || initialState.multipleModalData,
  (multipleModalData) => {
    return multipleModalData;
  }
)

