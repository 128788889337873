import {useEffect, useRef, useState} from "react";
import useMediaWallDispatch from "./useMediaWallDispatch";
import {optionAction, summaryAction, toggleAction} from "../../redux/store";
import callPeerMediaWallModule from "../../../core/utils/callPeerMediaWallModule";
import {shallowEqual, useDispatch, useSelector} from "react-redux";


const useMsgReceiveMediaWall = () => {
  const {connectMode, preselectionDetailReady} = useSelector(state => ({
    connectMode: state.theme.connectMode,
    preselectionDetailReady: state.toggle.preselectionDetailReady,
  }), shallowEqual);

  const dispatch = useDispatch();
  const iframeRef = useRef();
  const {dispatchAction} = useMediaWallDispatch();

  const [preselectionIndex, setPreselectionIndex] = useState(0);
  const [summaryData, setSummaryData] = useState();

  useEffect(() => {
    const receiveMsg = (e) => {
      if (e.data.msg === "result:screenShot") {

        (async () => {
          const peer = await callPeerMediaWallModule(connectMode);
          if(peer) {
            peer.conn.sendData("result:screenshot", e.data.data);
          }
        })();

        const dataKeys = Object.keys(e.data.data);
        const rearrange = dataKeys.map(item => {
          return {"id": item, "img": e.data.data[item]}
        });
        dispatch(summaryAction.setScreenShotImage(rearrange));

      } else if (e.data.msg === "slideEvent") {

        setPreselectionIndex(e.data.data);

      } else if (e.data.msg === "summaryData") {
        setSummaryData(e.data.data);

      } else if (e.data.msg === "dispatch") {
        const {action, func, payload} = e.data.data;
        dispatchAction(action, func, payload);
      } else if (e.data.msg === "assetLoad:end" || e.data.msg === "end:loading") {

        (async () => {
          const peer = await callPeerMediaWallModule(connectMode);
          if(peer) {
            peer.conn.sendData("assetLoad:end", e.data.data);
          }
        })();

      } else if(e.data.msg === "webglDestroy") {
        if(iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.pc && iframeRef.current.contentWindow.pc.app){
          iframeRef.current.contentWindow.pc.app.destroy();
        }
      } else if(e.data.msg === "end:change:part") {
        if (preselectionDetailReady) {
          dispatch(optionAction.setCurrentNavigation("exterior"));
          dispatch(toggleAction.setPreselectionDetailReady(false));
        }
      } else {
        if (iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(e.data, '*');
        }
      }
    }
    window.addEventListener('message', receiveMsg);
    return () => {
      window.removeEventListener('message', receiveMsg);
    }
  }, [preselectionDetailReady]);



  return {
    iframeRef, preselectionIndex, summaryData
  }
}

export default useMsgReceiveMediaWall;