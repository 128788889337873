/**
 * color selection version image
 * */

import React from "react";
import POINTER from "../../../../assets/images/dropper-tool.png";
import useColorPickerImageTab from "./useColorPickerImageTab";
import Magnifier from "../atoms/Magnifier";
import Button from "../../../independent/Button";


const ColorPickerImageTab = ({ fileRef, selectImage, imageChange, imageRef, clickApply, changeColor, imageData, pickerType, imagePickerBgColorRef, imagePickerPointer }) => {
  const { colorData, magnifier, isShow, magniState } = useColorPickerImageTab(imageRef, 3, changeColor, imagePickerPointer, imageData, selectImage, imagePickerBgColorRef);




  return (
    <div style={{display: pickerType === "IMAGE" ? "block" : "none"}}>
      <input type={"file"} ref={fileRef} style={{display: "none"}} accept={"image/jpeg,image/png"} onChange={imageChange}/>
      <div className="imgpicker-wrap" style={{overflow: "hidden"}}>
        <img className={"ImagePicker"}
             ref={imageRef}
             id={"picker"}
             style={{cursor: selectImage && `url(${POINTER}) 0 31, auto`}}
             onClick={() => {
              !selectImage && fileRef.current.click()
             }}
        />
        <div className={"centerPoint"} ref={imagePickerPointer}>
          <div className={"imagePointer"} ref={imagePickerBgColorRef}/>
        </div>
        <div className="imgpicker-guide"/>
      </div>
      <div className="imgpicker-text">
        <p className="desc">Upload a picture and pick up color</p>
        <p className="condition">JPG / PNG and under 20MB</p>
      </div>
      {
        selectImage &&
        <Button block={true} onClick={() => fileRef.current.click()} name={"Select other picture"}/>
      }
      <Button name={"Apply"} confirm={true} block={true} onClick={clickApply}/>
      {selectImage && colorData.length > 0 && (
        <Magnifier magniState={magniState} isShow={isShow} magnifier={magnifier} colorData={colorData}/>)
      }
    </div>
  )
}

export default ColorPickerImageTab;
