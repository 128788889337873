import {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {introAction, optionAction, summaryAction, toggleAction} from "../../redux/store";
import {currentVehicleSelector} from "../../redux/optionWindow-slice/optionWindowSlice";
import {useNavigate, useParams} from "react-router-dom";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";


const useMsgReceive = (initConnectMode) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentVehicle = useSelector(currentVehicleSelector);
  const navigate = useNavigate();

  const {configId, background, preselectionDetailReady, connectMode} = useSelector(state => ({
    configId: state.qrcode.configId,
    background: state.toggle.background,
    preselectionDetailReady: state.toggle.preselectionDetailReady,
    connectMode: state.theme.connectMode,
  }),shallowEqual);




  useEffect(() => {
    if(initConnectMode === "mediaWall" || !initConnectMode) {
      return;
    }

    const receiveMsg = async (e) => {
      if (!e.data.progress) {
        if(e.data.msg === "result:screenshot" || e.data.msg === "result:screenShot"){
          const dataKeys = Object.keys(e.data.data);
          const rearrange = dataKeys.map(item => {
            return { "id": item, "img" : e.data.data[item]}
          });

          dispatch(summaryAction.setScreenShotCode(configId));
          dispatch(summaryAction.setScreenShotImage(rearrange));
          dispatch(toggleAction.setToggle({key: "screenShotBackground", value: background}));

        } else if(e.data.msg === "assetLoad:end" || e.data.msg === "end:loading") {
          dispatch(introAction.setLoadingState("assetLoad:end"));

          if (initConnectMode === "web") {
            if(window){
              window.postMessage({ msg: "canCamera"});
            }
          } else if (initConnectMode === "remote") {
            const remoteModePeerConnected = await callPeerRemoteModule(initConnectMode);
            if(remoteModePeerConnected) {
              remoteModePeerConnected.conn.sendData("canCamera");
            }
          }
        } else if (e.data.msg === "end:change:part") {
          if (preselectionDetailReady && connectMode === "web") {
            dispatch(toggleAction.setPreselectionDetailReady(false));
            dispatch(optionAction.setOptions({selection: "isGnb", value: false}));
            navigate(`/${params.lang}/${params.id}/configurator/exterior`);
          }
        }
      }
    }

    window.addEventListener('message', receiveMsg);
    return () => {
      window.removeEventListener('message', receiveMsg);
    }
  }, [configId, background, currentVehicle, preselectionDetailReady]);



  return {}

}

export default useMsgReceive;
