import {createSlice} from "@reduxjs/toolkit";


const initialState = {
  productList: []
}

const productListSlice = createSlice({
  name: "productList",
  initialState: initialState,
  reducers: {
    setProductList(state, action) {
      state.productList = action.payload;
    }
  }
});

export default productListSlice;