/**
 * preselection container
 * */
import React, {useEffect, useState} from "react";
import SwiperCore, {Pagination} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import PreselectionHeader from "../molecules/PreselectionHeader";
import PreselectionSwiper from "../organisms/PreselectionSwiper";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getPreselectionList} from "../../../../core/api/preselectionApi";
import {currentVehicleSelector} from "../../../redux/optionWindow-slice/optionWindowSlice";
import callPeerRemoteModule from "../../../../core/utils/callPeerRemoteModule";
import {preselectionAction} from "../../../redux/store";


const PreSelection = () => {
  const dispatch = useDispatch();

  const currentVehicle = useSelector(currentVehicleSelector);
  const { connectMode, preselectionList } = useSelector(state => ({
    connectMode: state.theme.connectMode,
    preselectionList: state.preselection.preselectionList
  }), shallowEqual);

  useEffect(() => {
    if(currentVehicle) {
      getPreselectionList(currentVehicle.id).then(res => {
        if(res.data.result && res.data.message === "SUCCESS") {
          const preselectionListData = res.data.data.content;
          dispatch(preselectionAction.setPreselectionList(preselectionListData || []));

          (async () => {
            const peer = await callPeerRemoteModule(connectMode);
            if(peer) {
              peer.conn.sendData("dispatch", {
                action: "preselectionAction",
                func: "setPreselectionList",
                payload: preselectionListData
              })
            }
          })();
        }
      });
    }
  },[currentVehicle]);


  SwiperCore.use([Pagination]);



  return (
      <div className={`pre-selection active`}>
        <div className={"ps-overlay"}/>
        <div className={"ps-container"}>
          <PreselectionHeader preselectionList={preselectionList}/>
          <PreselectionSwiper preselectionList={preselectionList}/>
        </div>
      </div>)
}
export default PreSelection;
