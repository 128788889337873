import {lab_to_rgb, rgb_to_lab} from "../../../../../core/utils/colorConverts";

const useRgbInputs = (color, setColor) => {




  const inputColorChange = (colorMode, colorCode, inputValue) => {
    let maxValue;
    let minValue;
    if(colorMode === "rgb" || (colorMode === "lab" && colorCode === "l")) {
      const regexp = /^[0-9]*$/g
      if(inputValue !== "" && !regexp.test(inputValue)){
        return;
      }

      minValue = 0;
      if(colorMode === "rgb") {
        maxValue = 255;
      } else {
        maxValue = 100;
      }
    } else {
      const regexp = /^-?[0-9]*$/g
      if(inputValue !== "" && !regexp.test(inputValue)){
        return;
      }

      minValue = -120;
      maxValue = 120;
    }

    if(inputValue < minValue){
      inputValue = minValue;
    } else if(inputValue >= maxValue) {
      inputValue = maxValue;
    }


    if(colorMode === "rgb" && !inputValue){
      inputValue = 0;
    }


    let updateColor = JSON.parse(JSON.stringify(color));
    updateColor[colorMode][colorCode] = colorMode === "rgb" ? parseInt(inputValue) : inputValue;



    let convertColor = {};
    if (colorMode === "rgb") {
      convertColor = rgb_to_lab(updateColor[colorMode].r, updateColor[colorMode].g, updateColor[colorMode].b);

    } else {
      if(updateColor[colorMode][colorCode]) {
        convertColor = lab_to_rgb(updateColor[colorMode].l, updateColor[colorMode].a, updateColor[colorMode].b);
      } else {
        convertColor = {r: "", g: "", b: ""};
      }

    }

    updateColor[colorMode === "rgb" ? "lab" : "rgb"] = convertColor;
    setColor({...updateColor});
  };

  return { inputColorChange }
}

export default useRgbInputs;