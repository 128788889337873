/**
 * screenshot result setting redux
 * */

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState = {
  screenShot: [],
  screenShotCode: "",
  screenShotReady: false,
  offsetTopList: [],
  remoteScrollData: null,
  screenShotActiveIndex: 0,

  runningSummaryAnimation: false
}

export const summarySlice = createSlice({
  name : "summary",
  initialState : initialState,
  reducers : {
    setScreenShotImage (state,action){
      state.screenShot = [...action.payload];
    },
    clearScreenShotImage (state) {
      state.screenShot = initialState.screenShot;
    },
    setScreenShotCode (state, action) {
      state.screenShotCode = action.payload
    },
    setScreenShotReady (state, action) {
      state.screenShotReady = action.payload;
    },
    setOffsetTopList (state, action) {
      state.offsetTopList = [...action.payload];
    },
    setRemoteScrollData (state, action) {
      state.remoteScrollData = action.payload;
    },
    setScreenShotActiveIndex (state, action) {
      state.screenShotActiveIndex = action.payload;
    },
    setRunningSummaryAnimation (state, action) {
      state.runningSummaryAnimation = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
})

export const screenShotSelector = createSelector(
  (state) => state.summary.screenShot || initialState.screenShot,
  (screenShot) => {
    return screenShot;
  }
)

export const offsetTopListSelector = createSelector(
  (state) => state.summary.offsetTopList || initialState.offsetTopList,
  (offsetTopList) => {
    return offsetTopList;
  }
)
export const remoteScrollDataSelector = createSelector(
  (state) => state.summary.remoteScrollData || initialState.remoteScrollData,
  (remoteScrollData) => {
    return remoteScrollData;
  }
)