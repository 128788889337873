/**
 * modal container
 * */

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {modalAction} from "../../redux/store";
import MultipleModal from "./MultipleModal";
import {modalDataSelector} from "../../redux/modal-slice/modalSlice";

const ModalIndex = ()=>{
    const dispatch = useDispatch();
    const modalData = useSelector(modalDataSelector);
    const { id, dataType, isOpened } = !!modalData && modalData;


    return (
      <>
        <div className={"modal-wrap"} id={id} data-type={dataType} style={{display: isOpened ? "block" : "none"}}
             onClick={(e) => e.target.className === "modal-container" && dispatch(modalAction.modalClose())}>
            <div className={"modal-container"}>
                <div className={"modal"} id={"ROOT_MODAL"}/>
            </div>
        </div>
        <MultipleModal/>
      </>
    )
}

export default ModalIndex;
