import React, {Fragment} from "react";
import unescapeHtmlTag from "../../../../core/utils/tools";
import {useSelector} from "react-redux";
import {langDataSelector} from "../../../redux/theme-slice/themeSlice";


const ColorItem = ({title, options, notes} ) => {
  const langData = useSelector(langDataSelector);


  return (
    <Fragment>
      {
        options.CUSTOM ? (
          <>
            <div className="spec-name">{unescapeHtmlTag(title)}</div>
            <div className={"spec-value"}>Custom Color
              {
                options.NOTES ? (
                  <p className={"spec-value-request"}>
                    NOTE : { unescapeHtmlTag(options.NOTES) }
                  </p>
                ) : null
              }
            </div>
            <div
              className={"spec-code"}>{`${options.CUSTOM.DIFFUSE.r}, ${options.CUSTOM.DIFFUSE.g}, ${options.CUSTOM.DIFFUSE.b}`}</div>
          </>

        ) : (
          <>
            <div className="spec-name">{unescapeHtmlTag(title)}</div>
            <div className="spec-value">{unescapeHtmlTag(options[langData.langName])}
              {notes ? (
                <p className={"spec-value-request"}>
                  NOTE : { unescapeHtmlTag(notes) }
                </p>) : null
              }
            </div>
            <div className="spec-code">{options.code}</div>
          </>
        )
      }
    </Fragment>
  )

}

export default ColorItem;