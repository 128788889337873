import React from "react";


const CardImage = ({image}) => {


  return (
    <div className="ps-img">
      <img src={process.env.REACT_APP_image_path + "/preselection/" + image}/>
    </div>
  )
}

export default CardImage;