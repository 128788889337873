import axios from 'axios'

const productPrefix = process.env.REACT_APP_backend_url+"/public/product";
export const getPreselectionList = async (bespokeCode)=>{
    return await axios.get(`${productPrefix}/${bespokeCode}/preselection/list`);
}

export const getPreselectionDetail = async (bespokeCode,preselectionId)=>{
    return await axios.get(`${productPrefix}/${bespokeCode}/preselection/${preselectionId}`);
}
