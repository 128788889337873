import {useCallback, useEffect, useState} from "react";
import {optionAction} from "../../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {configDataSelector} from "../../../../redux/optionWindow-slice/optionWindowSlice";


const usePartChip = (itemObject) => {
  const dispatch = useDispatch();
  const configData = useSelector(configDataSelector);

  const partChipSelectHandler = useCallback((code, item, switchData) => {
    const copyConfigData = {...configData[code]};
    let payloadKey = code;

    if(switchData.partCategory){
      payloadKey = switchData.partCategory;
    } else if(switchData.type) {
      payloadKey = switchData.type;
    }

    const payload = {
      key: payloadKey,
      value: {
        ...copyConfigData,
        CODE: switchData.code,
      }
    }
    dispatch(optionAction.setSendData(payload));
  },[configData]);


  const [switchData, setSwitchData] = useState();

  useEffect(() => {
    if(itemObject.partSwitch) {
      setSwitchData(itemObject.partSwitch);
    } else if(itemObject.materialSwitch) {
      setSwitchData(itemObject.materialSwitch);
    } else if(itemObject.partPersonalization) {
      setSwitchData(itemObject.partPersonalization);
    }
  },[itemObject]);


  return { partChipSelectHandler, switchData }

}
export default usePartChip;