

const callPeerRemoteModule = async(connectMode) => {
  if(connectMode !== "remote"){
    return;
  }


  const peer = (await import("../peerRemote")).default;
  return peer;
}


export default callPeerRemoteModule;