import React from "react";


const PickerSwitch = ({name, setPickerType}) => {



  return (
    <div className={"option"}>
      <h4>SELECT PICKER</h4>
      <div className={"switch"}>
        <label>
          <input type="radio" name={name} onChange={() => setPickerType("COLOR")} defaultChecked={true}/>
          <span>COLOR</span>
        </label>
        <label>
          <input type="radio" name={name} onChange={() => setPickerType("IMAGE")}/>
          <span>IMAGE</span>
        </label>
      </div>
    </div>
  )
}

export default PickerSwitch;