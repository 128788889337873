import LOGO_IMAGE from "../../assets/images/logo_white.svg";
import React from "react";


const MediaWallLoading = () => {



  return (
    <div className={"media-wall-loading"}>
      <div className={"remote-tablet remote-wall"} data-theme="dark" data-lang={"ko"}>
        <div className={"wrap"}>
          <div className={"standby"}>
            <div className={"stb-header"}>
              <img src={LOGO_IMAGE} alt={"Genesis One of one logo"}/>
              <div className={"loadingView"} style={{display: "block"}}>
                <div className={"spinner"}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )


}

export default MediaWallLoading;