import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalAction} from "../../redux/store";
import {createPortal} from "react-dom";
import {modalDataSelector, multipleModalSelector} from "../../redux/modal-slice/modalSlice";


const useModal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(modalDataSelector);
  const multipleModalData = useSelector(multipleModalSelector);



  const [modalMount, setModalMount] = useState();
  //기본적인 modal 창 구성에 사용하는 파트
  const componentMount = (id, component, dataType="") => {
    setModalMount({...component});
    dispatch(modalAction.setModal({ id: id, isOpened: true, dataType: dataType}));
  }

  const ModalPortal = () => {
    const ref = useRef();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(true);
      if(document) {
        const dom = document.querySelector("#ROOT_MODAL");
        ref.current = dom;
      }
    },[]);

    if(ref.current && mounted && modalData.isOpened) {
      return createPortal(
        <>
          {modalMount && modalMount}
        </>,
        ref.current
      );
    }
    return null;
  }



  //modal 창이 떠있는 상태에서 중첩해서 뜨는 alert modal 을 위한 파트.
  const [multipleModalMount, setMultipleModalMount] = useState();
  const subModalMount = useCallback((component, dataType="", closeAll=false) => {
    setMultipleModalMount({...component});
    dispatch(modalAction.setMultipleModal({ isOpened: true, dataType: dataType, closeAll: closeAll}));
  },[]);

  const SubModalPortal = () => {
    const ref = useRef();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(true);
      if(document) {
        const dom = document.querySelector("#SUB_MODAL");
        ref.current = dom;
      }
    },[]);

    if(ref.current && mounted && multipleModalData.isOpened) {
      return createPortal(
        <>
          {multipleModalMount && multipleModalMount}
        </>,
        ref.current
      );
    }
    return null;
  }


  return { componentMount, ModalPortal, SubModalPortal, subModalMount }
}

export default useModal;