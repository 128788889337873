import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import usePartDetailTab from "./usePartDetailTab";
import {Navigation} from "swiper";

import PartDetail from "../atoms/PartDetail";

const PartDetailTab = ({partArray, code, selectedIndex, personalItemObject}) => {
  const {swiperRef, changeSliderItem, setSwiper, swipeParams} = usePartDetailTab(partArray, selectedIndex, personalItemObject);



  return (
    <div className={"tab-content active"}>
      <div className={"option-detail"}>
        <div className={"swiper"} ref={swiperRef}>
          <Swiper modules={[Navigation]} {...swipeParams}
                  onSlideChange={(e) => {
                    setSwiper(e);
                    changeSliderItem(e.realIndex, code);
          }}>
            {
              (partArray && !personalItemObject) && partArray.map(item => {
              return (
                <SwiperSlide key={item.id}>
                  <PartDetail item={item}/>
                </SwiperSlide>)
              })
            }
            {
              (!partArray && personalItemObject) && (
              <SwiperSlide key={personalItemObject.id}>
                <PartDetail item={personalItemObject}/>
              </SwiperSlide>)
            }
          </Swiper>
        </div>
      </div>
    </div>
  )
}
export default PartDetailTab;
